import axios, { AxiosRequestConfig } from 'axios';

export interface Response<K> {
  httpCode: number;
  error?: string;
  data?: K;
  statusCode?: number;
  responseCode?: number;
}

// L: Body Type
// K: Params Type
// M: Response Type
export interface CustomAxiosRequest<L, K> extends AxiosRequestConfig {
  data?: L;
  params?: K;
  token: string;
  isFormData?: boolean;
  hideToast?: boolean;
}

class BaseHttpService {
  // L: Body Type
  // K: Params Type
  // M: Response Type
  private async callApi<L, K, M>(requestConfig: CustomAxiosRequest<L, K>): Promise<Response<M>> {
    let newRequestConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_URL,
      ...requestConfig
    };

    let headers = {
      authorization: `Bearer ${requestConfig.token}`,
      'Content-Type': requestConfig.isFormData ? 'multipart/form-data' : 'application/json'
    };

    if (requestConfig.headers) {
      headers = {
        ...headers,
        ...requestConfig.headers
      };
    }

    newRequestConfig = {
      ...newRequestConfig,
      headers
    };

    try {
      let request;

      switch (newRequestConfig.method) {
        case 'GET':
          request = await axios.get(newRequestConfig.url!, { ...newRequestConfig });
          break;

        case 'POST':
          request = await axios.post(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'PUT':
          request = await axios.put(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'PATCH':
          request = await axios.patch(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'DELETE':
          request = await axios.delete(newRequestConfig.url!, { ...newRequestConfig });
          break;

        default:
          request = await axios({ ...newRequestConfig });
          break;
      }

      return {
        httpCode: 200,
        data: request.data,
        statusCode: request.data && request.data.statusCode
      };
    } catch (error: any) {
      const errorRes = error.response;

      // Status Code is 1 if status code from api is either null, undefined or 0
      return {
        httpCode: errorRes ? errorRes.status : 500,
        error:
          errorRes && errorRes.status === 500
            ? 'Sorry but there was an error. Please click the customer support icon at the top for technical assistance.'
            : errorRes && errorRes.data && errorRes.data.message
            ? errorRes.data.message
            : 'Please connect the customer support for assistance',
        statusCode: errorRes && errorRes.data && errorRes.data.statusCode ? errorRes.data.statusCode : 1
      };
    }
  }

  // L: Body Type
  // K: Params Type
  // M: Response Type
  execute<L, K, M>(requestConfig: CustomAxiosRequest<L, K>): Promise<Response<M>> {
    return this.callApi<L, K, M>({ ...requestConfig });
  }
}

export const httpService = new BaseHttpService();
