// All color branding
export const primary: string = '#009ca6';
export const secondary: string = '#6cc24a';

export const lightPrimary: string = '#0cc0c8';
export const lightSecondary: string = '#f1f9ed';

export const link: string = '#0066ff';
export const text: string = '#5e5e5f';
export const divider: string = '#cbcbcb';

export const info: string = '#5e5e5f';
export const success: string = '#65b434';
export const warning: string = '#ffb60f';
export const error: string = '#dd0d42';

export const black: string = '#5e5e5f';
export const darkBlack: string = '#000000';
export const darkCyan: string = '#058d98';
export const darkGrey: string = '#eaeaea';
export const darkTeal: string = '#196970';
export const greyBackground: string = '#f8f8f8';
export const greyBorder: string = '#d1d1d1';
export const headerBase1: string = '#0cc1c9';
export const highlightBackground: string = '#b5d7fe';
export const highlightRow: string = '#06949e19';
export const lightBlue = '#f6fbfe';
export const darkestGrey = '#a9a9a9';
export const lightGrey: string = '#d7d7d7';
export const lightGreyHeading: string = '#999191';
export const lightGreyBorder: string = '#ebebeb';
export const orange: string = '#eb6910';
export const red: string = '#dd0d42';
export const white: string = '#ffffff';

export const mineshaft: string = '#232323';

// Legend colors in Calendar
export const acknowledged: string = '#04649c';
export const cancelled: string = '#e02656';
export const cancelledWithCharge: string = '#A1704F';
export const completed: string = '#65b434';
export const leaves: string = '#e02656';
export const lightLabel = 'rgba(0, 0, 0, 0.38)';
export const listRowBorder: string = 'rgba(0, 0, 0, 0.5)';
export const ongoing: string = '#04649c';
export const rowHeader: string = '#0cc0c8';
export const scheduled: string = '#04649c';
export const softBooked: string = '#ff69b4';
export const workingHours: string = '#65b434';
export const publicHolidays: string = '#ffcccb';

// Attendance sheet
export const black1: string = 'rgba(221, 13, 66, 0.15)';
export const black2: string = 'rgba(0, 0, 0, .08)';
export const black3: string = 'rgba(0, 0, 0, .04)';
export const darkGrey1: string = '#363636';
export const darkGrey2: string = '#e8e8e8';
export const darkGrey3: string = '#828282';
export const darkGrey4: string = '#dedede';
export const darkGrey5: string = '#696969';
export const darkGrey6: string = '#969696';
export const darkGrey7: string = '#909090';
export const darkGrey8: string = '#9b9b9b';
export const lightGreen: string = 'rgba(6, 148, 158, 0.1)';
export const lightGreen1: string = 'rgba(6, 148, 158, 0.14)';
export const lightGrey1: string = '#9c9c9c';
export const lightGrey2: string = '#747474';
export const lightGrey4: string = 'rgba(0, 0, 0, .4)';
export const lightGrey6: string = 'rgba(0, 0, 0, .05)';
export const lightTeal: string = 'rgba(6, 148, 158, 0.5)';
export const teal: string = '#06949e';
export const transparent: string = 'rgba(0, 0, 0, 0)';
export const white1: string = 'rgba(250, 250, 250, 0.5)';
export const white2: string = '#fafafa';

// Preferred days in Service Plan
export const leaf02: string = 'rgba(108, 194, 74, .2)';
export const lightGrey3: string = '#cfcfcf';
export const lightGrey5: string = '#efefef';

/** FormIO */
export const darkRed1: string = '#721c24';
export const lightRed1: string = '#f8d7da';
export const lightRed2: string = '#f5c6cb';

/** Client Appointment Form */
export const darkGrey9: string = '#69696a';
export const lightLeaf: string = 'rgba(108, 194, 74, 0.2)';

/** Resource Center Dialog */
export const darkRed: string = '#800000';
export const green: string = '#00800b';
export const lightGreen2: string = '#cef2d1';
export const lightRed: string = '#f2cece';
export const lightYellow: string = '#ffffd5';
