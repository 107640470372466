export enum VitalsFieldNameEnum {
  HEART_RATE = 'Heart Rate',
  BLOOD_GLUCOSE = 'Blood Glucose',
  BP_SYSTOLIC = 'BP- Systolic / Diastolic',
  TEMPERATURE = 'Temperature',
  SPO2 = 'SpO2 Saturation',
  WEIGHT = 'Weight',
  PAIN = 'Pain',
  RESPIRATION_RATE = 'Respiration Rate',
  REMARKS = 'Remarks'
}

export enum VitalsReferenceRangeKeyEnum {
  vital_signs_heart_rate = 'HEART_RATE',
  vital_signs_resting_blood_glucose = 'BLOOD_GLUCOSE',
  vital_signs_blood_pressure_systolic = 'BP_SYSTOLIC',
  vital_signs_resting_blood_pressure_diastollic = 'BP_SYSTOLIC',
  vital_signs_temperature = 'TEMPERATURE',
  vital_signs_SPO2_saturation = 'SPO2',
  vital_signs_weight = 'WEIGHT',
  vital_signs_resting_respiration_rate = 'RESPIRATION_RATE'
}

export enum VitalsShortNameForRefRangeEnum {
  vital_signs_heart_rate = 'HR',
  vital_signs_resting_blood_glucose = 'BG',
  vital_signs_blood_pressure_systolic = 'SBP',
  vital_signs_resting_blood_pressure_diastollic = 'DBP',
  vital_signs_temperature = 'TEMP',
  vital_signs_SPO2_saturation = 'SPO2',
  vital_signs_weight = 'WEIGHT',
  vital_signs_resting_respiration_rate = 'RR'
}

export enum VitalsShortNameEnum {
  HEART_RATE = 'HR',
  BLOOD_GLUCOSE = 'BG',
  BP_SYSTOLIC = 'BP',
  TEMPERATURE = 'TEMP',
  SPO2 = 'SPO2',
  WEIGHT = 'WEIGHT',
  PAIN = 'PAIN',
  RESPIRATION_RATE = 'RR'
}
