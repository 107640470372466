// TYPES
import { AppThunk } from 'redux/type';

// SELECTOR
import { selectAppToken } from 'redux/app/selectors';

// HTTPS
import { httpService, Response, CustomAxiosRequest } from 'config/http';

// L: Body Type
// K: Params Type
// M: Response Type
// Http action for all apis
export function httpAction<L, K, M>(
  requestConfig: Omit<CustomAxiosRequest<L, K>, 'token'>,
  callback: (response: Response<M>) => void
): AppThunk {
  return async (dispatch, getState) => {
    const token = selectAppToken(getState());

    const response = await httpService.execute<L, K, M>({
      ...requestConfig,
      token
    });

    // When the request returns success
    callback(response);
  };
}
