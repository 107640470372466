import styled from '@emotion/styled';
import BasedButton from '@mui/material/Button';
import BaseTab from '@mui/material/Tab';

export const VitalIconWrapperItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HR = styled.div`
  height: 0;
  border: 1px solid #cccccc;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Button = styled(BasedButton)`
  background-color: #5cba39;
  margin-right: 15px;

  &:focus {
    background-color: #5cba39;
  }

  &:hover {
    background-color: #5cba39;
  }
`;

export const IconTextButton = styled(BasedButton)`
  color: #258b96;
`;

export const SearchWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const FilterBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  flex-wrap: wrap;
`;

export const ViewTypeSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #959595;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 10px;

  & > * {
    flex: 1 1 auto;
  }
`;

export const ViewTypeSelectionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #4b4b4c;
  cursor: pointer;
  border-right: 1px solid #959595;
  font-size: 12px;

  &:last-child {
    border-right: none;
  }

  &.active {
    background-color: #009aa6;
    color: #fff;
  }
`;

export const CheckboxLabelWrapper = styled.p`
  margin: -10px 0 0 0;
  font-size: 12px;
  color: #4b4b4c;
`;

export const FilterBarItem = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  position: relative;

  .MuiFormControl-root {
    position: relative;
  }
`;

export const FilterBarSectionWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    flex-direction: column;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    flex-direction: column;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    flex-direction: column;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1525px) {
    flex-direction: row;
  }
`;

export const ListChartViewWrapper = styled.div`
  width: 200px;
`;

export const FilterBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`;

export const RecordWrapper = styled.div`
  margin-right: 10px;
  text-align: right;
`;

export const Tab = styled(BaseTab)`
  font-weight: bold;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // opacity: 0.9;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VitalTital = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const VitalTitalTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const BpmRow = styled.div`
  height: 4px;
  width: 60px;
  background-color: #009aa6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BpmRedRow = styled.div`
  height: 4px;
  width: 60px;
  background-color: #b74253;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BpmPurpleRow = styled.div`
  height: 4px;
  width: 60px;
  background-color: #9747ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
`;

export const VitalUnitText = styled.div`
  font-size: 12px;
  color: #959595;
  margin-left: 5px;
`;

export const DateWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
`;

export const RefreshWrapper = styled.div`
  .refresh-icon {
    margin-right: 10px;
  }

  button {
    text-transform: capitalize;
  }
`;

export const DateSectionWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const DateSectionErrorWrapper = styled.div`
  position: absolute;
  bottom: -12px;
  left: 10px;
  font-size: 12px;
  color: red;
`;

export const SortableHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TitleVitalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const WordCounterContainer = styled.span`
  position: absolute;
  right: 0;
  background: #fff;
  padding: 0px 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const TooltipBaselineWrapper = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${(props) => `${props.y}px`};
  left: ${(props) => `${props.x}px`};
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 15;
`;

export const TooltipBaselineTitle = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #515151;
`;

export const TooltipBaselineFooter = styled.p`
  margin: 0px;
  margin-top: 5px;
  padding: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #515151;
`;

export const TooltipBaselineBody = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 13px;
`;
