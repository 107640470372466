// TYPE
import { RootState } from 'redux/type';
import { AppMessage } from 'model';

export const selectAppLoadingStatus = (state: RootState): boolean => state.appReducer.loading;
export const selectAppMessage = (state: RootState): AppMessage | undefined => state.appReducer.message;
export const selectAppToken = (state: RootState): string => state.appReducer.token;
export const selectClientId = (state: RootState): string => state.appReducer.clientID;
export const canView = (state: RootState): boolean => {
  return state.appReducer.usersRoles.includes('HEALTH_DATA:VIEW');
};
export const canUpdate = (state: RootState): boolean => {
  return state.appReducer.usersRoles.includes('HEALTH_DATA:UPDATE');
};
export const canCreate = (state: RootState): boolean => {
  return state.appReducer.usersRoles.includes('HEALTH_DATA:CREATE');
};
export const canDelete = (state: RootState): boolean => {
  return state.appReducer.usersRoles.includes('HEALTH_DATA:DELETE');
};
export const canGoHyperlinkProfile = (state: RootState): boolean => {
  return state.appReducer.usersRoles.includes('RESOURCE:VIEW_ALL');
};
export const selectClientInformation = (
  state: RootState
): { clientName: string; clientDOB: string; clientGender: string } => {
  return {
    clientDOB: state.appReducer.clientDOB,
    clientGender: state.appReducer.clientGender,
    clientName: state.appReducer.clientName
  };
};
