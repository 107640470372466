import { createSlice } from '@reduxjs/toolkit';

// MODEL
import { VitalListViewStatusType, VitalState } from 'model';

// ICON
import { ReactComponent as BPIcon } from 'assets/icon/bp_grey.svg';
import { ReactComponent as BloodGlucose } from 'assets/icon/blood_glucose_grey.svg';
import { ReactComponent as HeartRate } from 'assets/icon/heart_rate_grey.svg';
import { ReactComponent as Pain } from 'assets/icon/pain_grey.svg';
import { ReactComponent as RespirationRate } from 'assets/icon/respiration_rate_grey.svg';
import { ReactComponent as SP02 } from 'assets/icon/sp02_grey.svg';
import { ReactComponent as Temp } from 'assets/icon/temperature_grey.svg';
import { ReactComponent as Weight } from 'assets/icon/weight_grey.svg';

import { ReactComponent as BPIconActive } from 'assets/icon/bp.svg';
import { ReactComponent as BloodGlucoseActive } from 'assets/icon/blood_glucose.svg';
import { ReactComponent as HeartRateActive } from 'assets/icon/heart_rate.svg';
import { ReactComponent as PainActive } from 'assets/icon/pain.svg';
import { ReactComponent as RespirationRateActive } from 'assets/icon/respiration_rate.svg';
import { ReactComponent as SP02Active } from 'assets/icon/sp02.svg';
import { ReactComponent as TempActive } from 'assets/icon/temperature.svg';
import { ReactComponent as WeightActive } from 'assets/icon/weight.svg';

import BPIconPng from 'assets/icon/bp.png';
import BloodGlucosePng from 'assets/icon/blood_glucose.png';
import HeartRatePng from 'assets/icon/heart_rate.png';
import PainPng from 'assets/icon/pain.png';
import RespirationRatePng from 'assets/icon/respiration_rate.png';
import SP02Png from 'assets/icon/sp02.png';
import TempPng from 'assets/icon/temperature.png';
import WeightPng from 'assets/icon/weight.png';

// REDUCER ACTION
import {
  changeVitalLoading,
  setDashboardData,
  setVitalListView,
  setCurrentPage,
  setCurrentPageSize,
  setTotalElements,
  setVitalGraphView,
  setVitalGraphLabel,
  setVitalGraphData,
  setVitalGraphReporting,
  setSecondVitalGraphData,
  setVitalListViewStatus,
  setLoadingGraph,
  setLoadingListView,
  setDataForDownload,
  setBaseLine,
  setViewType,
  setCustomViewType,
  setGraphStartDate,
  setGraphEndDate,
  setSelectedVitalType,
  setInitialSelectedVitalType,
  setThirdVitalGraphData,
  setEnabledZoomPan,
  setForceRendeChart,
  setLoadingDashboard,
  setIsDownloadInprogress,
  setIsMultiVitals,
  setReferenceRanges,
  setIsShowBorderline
} from './reducers';

export const initialState: VitalState = {
  loading: false,
  dashboard: [],
  loadingDashboard: true,
  vitalListView: [],
  vitalListViewStatus: VitalListViewStatusType.STABLE,
  currentPage: 0,
  currentPageSize: 10,
  totalElements: 0,
  vitalGraphView: [],
  selectedVitalTypes: [],
  initialVitalTypes: [], // ONLY 2 or 0
  vitalIcons: {
    HEART_RATE: {
      active: HeartRateActive,
      nonActive: HeartRate,
      png: HeartRatePng
    },
    BLOOD_GLUCOSE: {
      active: BloodGlucoseActive,
      nonActive: BloodGlucose,
      png: BloodGlucosePng
    },
    BP_SYSTOLIC: {
      active: BPIconActive,
      nonActive: BPIcon,
      png: BPIconPng
    },
    TEMPERATURE: {
      active: TempActive,
      nonActive: Temp,
      png: TempPng
    },
    SPO2: {
      active: SP02Active,
      nonActive: SP02,
      png: SP02Png
    },
    WEIGHT: {
      active: WeightActive,
      nonActive: Weight,
      png: WeightPng
    },
    PAIN: {
      active: PainActive,
      nonActive: Pain,
      png: PainPng
    },
    RESPIRATION_RATE: {
      active: RespirationRateActive,
      nonActive: RespirationRate,
      png: RespirationRatePng
    }
  },
  vitalText: {
    HEART_RATE: 'Heart Rate',
    BLOOD_GLUCOSE: 'Blood glucose',
    BP_SYSTOLIC: 'BP - Systolic / Diastolic',
    TEMPERATURE: 'Temperature',
    SPO2: 'SpO2 Saturation',
    WEIGHT: 'Weight',
    PAIN: 'Pain',
    RESPIRATION_RATE: 'Respiration Rate'
  },
  vitalUnit: {
    HEART_RATE: 'bpm',
    BLOOD_GLUCOSE: 'mmoL',
    BP_SYSTOLIC: 'mmHg',
    TEMPERATURE: '°C',
    SPO2: '%',
    WEIGHT: 'Kg',
    PAIN: 'Scale',
    RESPIRATION_RATE: 'bpm'
  },
  vitalGraphLabel: [],
  vitalGraphData: [],
  vitalGraphReportData: [],
  secondVitalGraphData: [],
  thirdVitalGraphData: [],
  loadingGraph: false,
  loadingListView: false,
  dataWaitingForDownload: undefined,
  baseLine: {},
  enabledZoomPan: false,
  forceRenderChart: false,
  isDownloadingInProgress: false,
  isShowBorderline: true,
  isMultiVitals: false,
  viewType: null,
  customViewType: null,
  graphStartDate: null,
  graphEndDate: null,
  referenceRanges: {}
};

export const vitalSlice = createSlice({
  name: 'Vital',
  initialState,
  reducers: {
    changeVitalLoading,
    setDashboardData,
    setVitalListView,
    setVitalListViewStatus,
    setCurrentPage,
    setCurrentPageSize,
    setTotalElements,
    setVitalGraphView,
    setVitalGraphLabel,
    setVitalGraphData,
    setVitalGraphReporting,
    setSecondVitalGraphData,
    setLoadingGraph,
    setLoadingListView,
    setDataForDownload,
    setBaseLine,
    setSelectedVitalType,
    setInitialSelectedVitalType,
    setThirdVitalGraphData,
    setEnabledZoomPan,
    setForceRendeChart,
    setLoadingDashboard,
    setIsDownloadInprogress,
    setIsMultiVitals,
    setViewType,
    setCustomViewType,
    setGraphStartDate,
    setGraphEndDate,
    setReferenceRanges,
    setIsShowBorderline
  }
});

export default vitalSlice.reducer;
