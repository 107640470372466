/* eslint-disable max-len */
import React, { FC, useEffect, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { getInitialSelectedVitalType, selectLoadingListView } from 'redux/vital/selectors';

// REDUX
import { useAppDispatch, useAppSelector } from 'hooks';
import { sendMessageToParent } from 'redux/app/actions';

// SELECTOR
import {
  selectVitalListView,
  selectCurrentPage,
  selectCurrentPageSize,
  selectTotalElements,
  selectVitalUnit,
  selectVitalListViewStatus,
  getSelectedVitalType
} from 'redux/vital/selectors';
import { selectClientId, canCreate, canUpdate, canDelete, canGoHyperlinkProfile } from 'redux/app/selectors';

// ACTIONS
import {
  getListViewData,
  setVitalListView,
  sendDeleteRequest,
  setCurrentPage,
  setCurrentPageSize,
  openAllVitalsOpenPopup,
  setVitalListViewStatus,
  getMultiVitalListViewData,
  setSelectedVitalType,
  openEditRemarkPopup,
  setIsMultiVitals,
  getResourceList
} from 'redux/vital/action';

// MESSAGE TYPE
import { PostMessageType, Resource, VitalListViewStatusType, VitalRowItem } from 'model';

//STYLES
import {
  Button,
  IconTextButton,
  SearchWrapper,
  FilterBarWrapper,
  FilterBarItem,
  FilterBarSectionWrapper,
  ListChartViewWrapper,
  FilterBar,
  RecordWrapper,
  Tab,
  DateSectionWrapper,
  DateSectionErrorWrapper,
  SortableHeader,
  CheckboxLabelWrapper
} from './styles';
import { VitalType } from 'model';
import { truncate } from 'lodash';
import { VitalsFieldNameEnum, VitalsShortNameEnum } from 'constant/vitals';
import { Refresh } from '@mui/icons-material';
import { Checkbox, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#565656',
    color: theme.palette.common.white
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  boxShadow: '0 2px 6px 0 rgb(0 0 0 / 30%)'
}));

function customSplit(str: string, maxLength: number) {
  if (str.length <= maxLength) return str;

  const reg = new RegExp('.{1,' + maxLength + '}', 'g');
  const parts = str.match(reg);

  return `${(parts as any[]).join('\n')}...`;
}

const VitalListView: FC<{
  selectedTab: number;
  onSelect: (tab: number) => void;
  startDate: any;
  setStartDate: (data: any) => void;
  endDate: any;
  setEndDate: (data: any) => void;
  employeeCode: string[];
  setEmployeeCode: (data: string[]) => void;
  selectedId: number | undefined;
  setSelectedId: (data: any) => void;
  selectRemark: string;
  setSelectRemark: (data: any) => void;
  orderBy: string;
  setOrderBy: (data: any) => void;
  orderDirection: string;
  setOrderDirection: (data: any) => void;
}> = ({
  selectedTab,
  onSelect,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  employeeCode,
  setEmployeeCode,
  setSelectedId,
  setSelectRemark,
  orderBy,
  setOrderBy,
  orderDirection,
  setOrderDirection
}) => {
  const dispatch = useAppDispatch();
  const vitalListView = useAppSelector(selectVitalListView);
  const vitalListViewStatus = useAppSelector(selectVitalListViewStatus);
  const page = useAppSelector(selectCurrentPage);
  const rowsPerPage = useAppSelector(selectCurrentPageSize);
  const totalElements = useAppSelector(selectTotalElements);
  const clientId = useAppSelector(selectClientId);
  const [errorMessage, setErrorMessage] = useState('');
  const enabledGraph = process.env.REACT_APP_ENABLED_GRAPH === 'true';
  const [loadingSorting, setLoadingSorting] = useState(false);
  const [errorMessagePosition, setErrorMessagePosition] = useState<null | any>();
  const [resourceList, setResourceList] = useState<Resource[]>([]);
  const enabledLoadingListView = useAppSelector(selectLoadingListView);
  const selectedType = useAppSelector(getSelectedVitalType);
  const canDeleteVital = useAppSelector(canDelete);
  const canUpdateVital = useAppSelector(canUpdate);
  const canCreateVital = useAppSelector(canCreate);
  const canGoToProfile = useAppSelector(canGoHyperlinkProfile);
  const [updateListViewFromCheckBox, setUpdateListViewFromCheckBox] = useState(false);

  const initiallySelectedVitalTypes = useAppSelector(getInitialSelectedVitalType);
  const primaryVital = initiallySelectedVitalTypes.length >= 1 ? initiallySelectedVitalTypes[0] : selectedType[0];
  const vitalUnit = useAppSelector(selectVitalUnit(primaryVital?.toUpperCase() as VitalType));
  const secondaryVitalUnit = useAppSelector(
    selectVitalUnit(initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType)
  );
  const vitalName = VitalsFieldNameEnum[primaryVital?.toUpperCase() as VitalType];
  const secondaryVitalName = VitalsFieldNameEnum[initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType];

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setCurrentPage(newPage));

    if (selectedType[0] && selectedType[0].length > 0) {
      dispatch(
        getListViewData(
          selectedType,
          {
            page: newPage,
            size: rowsPerPage,
            employeeCode: employeeCode.join(','),
            fromDate: startDate ? new Date(startDate).toISOString() : null,
            toDate: endDate ? new Date(endDate).toISOString() : null,
            sort: `${orderBy},${orderDirection}`
          },
          initiallySelectedVitalTypes.length === 2
        )
      );
    }
  };

  useEffect(() => {
    if (vitalListViewStatus === VitalListViewStatusType.RECENTLY_UPDATED) {
      setStartDate(null);
      setEndDate(null);
      setEmployeeCode([]);
      setSelectedId(undefined);
      setSelectRemark('');
      dispatch(setVitalListViewStatus(VitalListViewStatusType.STABLE));
    }
  }, [vitalListViewStatus]);

  useEffect(() => {
    if (clientId) {
      dispatch(
        getResourceList((data) => {
          setResourceList(data);
        })
      );
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    dispatch(setIsMultiVitals(selectedType.length > 1));
  }, [selectedType]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;

    dispatch(setCurrentPageSize(newRowsPerPage));

    if (selectedType[0] && selectedType[0].length > 0) {
      dispatch(
        getListViewData(
          selectedType,
          {
            page: 0,
            size: newRowsPerPage,
            employeeCode: employeeCode.join(','),
            fromDate: startDate ? new Date(startDate).toISOString() : null,
            toDate: endDate ? new Date(endDate).toISOString() : null,
            sort: `${orderBy},${orderDirection}`
          },
          selectedType.length > 1
        )
      );
    }

    dispatch(setCurrentPage(0));
  };

  useEffect(() => {
    if (updateListViewFromCheckBox) return;

    if (clientId && clientId.length > 0) {
      if (selectedType.length >= 2) {
        dispatch(
          getMultiVitalListViewData(selectedType[0], selectedType[1], {
            page: 0,
            size: rowsPerPage || 10,
            employeeCode: employeeCode.join(','),
            sort: `date,desc`
          })
        );
      } else if (selectedType[0] && selectedType[0].length > 0) {
        dispatch(
          getListViewData(selectedType, {
            page: 0,
            size: rowsPerPage || 10,
            employeeCode: employeeCode.join(','),
            sort: `date,desc`
          })
        );
      }
    }
  }, [dispatch, selectedType, clientId, updateListViewFromCheckBox]);

  const handleSearchButton = () => {
    setSelectRemark(null);
    setSelectedId(null);

    if (selectedType[0] && selectedType[0].length > 0) {
      if (dayjs(endDate).isBefore(dayjs(startDate))) {
        setErrorMessage('To Date cannot be less than From Date');
        setErrorMessagePosition({ left: 10 });

        return;
      }

      dispatch(setCurrentPageSize(10));
      dispatch(setCurrentPage(0));
      dispatch(
        getListViewData(selectedType, {
          page: 0,
          size: 10,
          employeeCode: employeeCode.join(','),
          fromDate: startDate ? new Date(startDate).toISOString() : null,
          toDate: endDate ? new Date(endDate).toISOString() : null,
          sort: `${orderBy},${orderDirection}`
        })
      );
    }
  };

  const handleAddRecord = () => {
    if (selectedType[0]) {
      setSelectRemark(null);
      setSelectedId(null);
      dispatch(openAllVitalsOpenPopup(clientId, orderBy, orderDirection, 'CUSTOM_FIELDS', selectedType));
    }
  };

  useEffect(() => {
    return () => {
      setStartDate(null);
      setEndDate(null);
      setEmployeeCode([]);
      setSelectedId(undefined);
      setSelectRemark('');
      dispatch(setVitalListView([]));
      setUpdateListViewFromCheckBox(false);
    };
  }, [dispatch]);

  const handleDeleteRecord = (id: number) => {
    if (selectedType[0]) {
      dispatch(
        sendDeleteRequest(id, selectedType, {
          page: 0,
          size: 10,
          employeeCode: employeeCode.join(','),
          fromDate: startDate ? new Date(startDate).toISOString() : null,
          toDate: endDate ? new Date(endDate).toISOString() : null
        })
      );
    }
  };

  const handleEditRemark = (row: VitalRowItem) => {
    if (selectedType[0]) {
      dispatch(openEditRemarkPopup(row, selectedType));
    }
  };

  const handleNavigateToClientDetail = useCallback(
    (clientUUID: string) => {
      if (!canGoToProfile) return;

      dispatch(sendMessageToParent({ clientUUID }, PostMessageType.NAVIGATE_TO_CLIENT));
    },
    [dispatch, canGoToProfile]
  );

  const handleClickSortableHeader = (sortBy: string) => {
    if (loadingSorting) return;

    setLoadingSorting(true);

    const newOrderDirection = orderDirection === 'asc' ? 'desc' : 'asc';

    if (orderBy !== sortBy) {
      setOrderBy(sortBy);
    }

    setOrderDirection(newOrderDirection);

    if (selectedType[0]) {
      dispatch(
        getListViewData(
          selectedType,
          {
            page: page,
            size: rowsPerPage,
            employeeCode: employeeCode.join(','),
            fromDate: startDate ? new Date(startDate).toISOString() : null,
            toDate: endDate ? new Date(endDate).toISOString() : null,
            sort: `${sortBy},${newOrderDirection}`
          },
          selectedType.length >= 2,
          false,
          () => {
            setLoadingSorting(false);
          }
        )
      );
    }

    return;
  };

  const renderSortIcon = useCallback(
    (sortBy: string) => {
      return sortBy !== orderBy ? null : orderDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
    },
    [orderBy, orderDirection]
  );

  const rowMarkValue = (value: string) => {
    const screenWidth = document.body.clientWidth;

    if (screenWidth < 500) {
      return truncate(customSplit(value, 10), { length: 100 });
    }

    if (screenWidth < 736) {
      return truncate(customSplit(value, 10), { length: 200 });
    }

    if (screenWidth === 736) {
      return truncate(customSplit(value, 20), { length: 200 });
    }

    if (screenWidth === 1152) {
      return truncate(customSplit(value, 70), { length: 200 });
    }

    if (screenWidth < 1152 && screenWidth > 735) {
      return truncate(customSplit(value, 30), { length: 200 });
    }

    if (screenWidth > 1152) {
      return truncate(customSplit(value, 100), { length: 200 });
    }
  };

  const truncateRemarkForMultiVitalRemark = (value: string) => {
    const screenWidth = document.body.clientWidth;

    if (screenWidth < 1520) {
      return truncate(customSplit(value, 100), { length: 25 });
    }

    if (screenWidth < 1600) {
      return truncate(customSplit(value, 100), { length: 40 });
    }

    if (screenWidth < 1700) {
      return truncate(customSplit(value, 100), { length: 50 });
    }

    if (screenWidth > 1700) {
      return truncate(customSplit(value, 100), { length: 100 });
    }
  };

  const prepareRemark = (row: VitalRowItem) => {
    if (selectedType.length > 1) {
      let content = [];

      content[0] = row.remark ? (
        <Tooltip title={row.remark}>
          <div>
            <span style={{ fontWeight: 'bolder' }}>
              {VitalsShortNameEnum[primaryVital?.toUpperCase() as VitalType]}
            </span>{' '}
            - {truncateRemarkForMultiVitalRemark(row.remark)}{' '}
          </div>
        </Tooltip>
      ) : (
        ''
      );
      content[1] = row.secondaryRemark ? (
        <Tooltip title={row.secondaryRemark}>
          <div>
            <span style={{ fontWeight: 'bolder' }}>
              {VitalsShortNameEnum[initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType]}
            </span>{' '}
            - {truncateRemarkForMultiVitalRemark(row.secondaryRemark)}
          </div>
        </Tooltip>
      ) : (
        ''
      );

      return <div>{content}</div>;
    }

    return rowMarkValue(row.remark || '');
  };

  const loadingComponent = () => {
    return (
      <div style={{ width: '100%', height: 500, textAlign: 'center', marginTop: 30 }}>
        <CircularProgress size={30} />
      </div>
    );
  };

  const handleRefreshButton = () => {
    handleSearchButton();
  };

  const toggleSelectedVital = (clickedVital: string) => {
    if (initiallySelectedVitalTypes.length < 2) return;

    setUpdateListViewFromCheckBox(true);

    if (selectedType.includes(clickedVital)) {
      if (selectedType.length <= 1) return;

      const localSelectedType = initiallySelectedVitalTypes.filter((t) => t !== clickedVital);

      dispatch(setIsMultiVitals(false));
      dispatch(setSelectedVitalType(localSelectedType));

      dispatch(
        getListViewData(localSelectedType, {
          page: 0,
          size: rowsPerPage,
          employeeCode: employeeCode.join(','),
          fromDate: startDate ? new Date(startDate).toISOString() : null,
          toDate: endDate ? new Date(endDate).toISOString() : null,
          sort: `${orderBy},${orderDirection}`
        })
      );
    } else {
      dispatch(setSelectedVitalType(initiallySelectedVitalTypes));
      dispatch(setIsMultiVitals(true));

      dispatch(
        getListViewData(
          initiallySelectedVitalTypes,
          {
            page: 0,
            size: rowsPerPage,
            employeeCode: employeeCode.join(','),
            fromDate: startDate ? new Date(startDate).toISOString() : null,
            toDate: endDate ? new Date(endDate).toISOString() : null,
            sort: `${orderBy},${orderDirection}`
          },
          true
        )
      );
    }
  };

  const getSortBy = (multiVitalStatus: string = '') => {
    if (selectedType.length > 1 && multiVitalStatus === 'primary') return 'primaryRecord';

    if (selectedType.length > 1 && multiVitalStatus === 'secondary') return 'secondaryRecord';

    return 'record';
  };

  const prepareVitalTableHeaderCell = (name: string, unit: string, multiVitalStatus: string = '') => {
    if (name === 'BP- Systolic / Diastolic' || name === 'BP_SYSTOLIC') {
      return (
        <>
          <StyledTableCell align="center" width={150}>
            <RecordWrapper>
              <SortableHeader
                style={{ justifyContent: 'end', marginRight: -3 }}
                onClick={() => handleClickSortableHeader(getSortBy(multiVitalStatus))}
              >
                BP - Systolic ({unit}) {renderSortIcon(getSortBy(multiVitalStatus))}
              </SortableHeader>
            </RecordWrapper>
          </StyledTableCell>

          <StyledTableCell align="center" width={150}>
            BP - Diastolic ({unit})
          </StyledTableCell>
        </>
      );
    }

    return (
      <StyledTableCell align="center" width={150}>
        <RecordWrapper>
          <SortableHeader
            style={{ justifyContent: 'end', marginRight: -3 }}
            onClick={() => handleClickSortableHeader(getSortBy(multiVitalStatus))}
          >
            {name} ({unit}) {renderSortIcon(getSortBy(multiVitalStatus))}
          </SortableHeader>
        </RecordWrapper>
      </StyledTableCell>
    );
  };

  const prepareVitalValueCell = (name: string, value: string | number) => {
    // if (!name) return;

    if (name === 'BP- Systolic / Diastolic' || name === 'BP_SYSTOLIC') {
      const valueArr = value ? value.toString().split('/') : ['', ''];

      return (
        <>
          <StyledTableCell align="center" style={{ fontWeight: 700 }}>
            <RecordWrapper>{valueArr[0]}</RecordWrapper>
          </StyledTableCell>
          <StyledTableCell align="center" style={{ fontWeight: 700 }}>
            <RecordWrapper>{valueArr[1]}</RecordWrapper>
          </StyledTableCell>
        </>
      );
    }

    return (
      <StyledTableCell align="center" style={{ fontWeight: 700 }}>
        <RecordWrapper>{value}</RecordWrapper>
      </StyledTableCell>
    );
  };

  return (
    <>
      <FilterBarSectionWrapper>
        <ListChartViewWrapper>
          {enabledGraph && (
            <Tabs centered value={selectedTab} onChange={(v, newValue: number) => onSelect(newValue)}>
              <Tab label="Graph" />
              <Tab label="List" />
            </Tabs>
          )}
        </ListChartViewWrapper>
        <Grid container>
          <Grid container justifyContent="flex-end" style={{ paddingRight: '10px' }}>
            {canCreateVital && (
              <IconTextButton
                className="primary-txt-btn"
                size="medium"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddRecord}
              >
                Add record
              </IconTextButton>
            )}
            <IconTextButton
              className="primary-txt-btn"
              size="medium"
              startIcon={<Refresh />}
              onClick={handleRefreshButton}
            >
              Refresh
            </IconTextButton>
          </Grid>
          <FilterBar>
            <FilterBarWrapper>
              {initiallySelectedVitalTypes.length >= 2 && (
                <FormGroup style={{ marginRight: '10px' }}>
                  <Checkbox
                    checked={selectedType.includes(initiallySelectedVitalTypes[0])}
                    color="primary"
                    name={initiallySelectedVitalTypes[0]}
                    onChange={() => {
                      toggleSelectedVital(initiallySelectedVitalTypes[0]);
                    }}
                  />
                  <CheckboxLabelWrapper>{vitalName}</CheckboxLabelWrapper>
                </FormGroup>
              )}

              {initiallySelectedVitalTypes.length >= 2 && (
                <FormGroup style={{ marginRight: '10px' }}>
                  <Checkbox
                    checked={selectedType.includes(initiallySelectedVitalTypes[1])}
                    color="primary"
                    name={initiallySelectedVitalTypes[1]}
                    onChange={() => {
                      toggleSelectedVital(initiallySelectedVitalTypes[1]);
                    }}
                  />
                  <CheckboxLabelWrapper>{secondaryVitalName}</CheckboxLabelWrapper>
                </FormGroup>
              )}
              <FilterBarItem>
                <FormControl sx={{ minWidth: 400, margin: 0 }} variant="standard">
                  <InputLabel id="resourceSelect">Resource Name</InputLabel>
                  <Select
                    multiple
                    id="resourceSelect"
                    label="Resource Name"
                    labelId="resourceSelect"
                    value={employeeCode}
                    onChange={(v) => setEmployeeCode(v.target.value as string[])}
                  >
                    {resourceList.map((resource) => (
                      <MenuItem key={`${resource.employeeCode}`} value={resource.employeeCode}>
                        {resource.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FilterBarItem>
              <DateSectionWrapper>
                <FilterBarItem>
                  <DateTimePicker
                    disableFuture
                    inputFormat="DD/MM/YYYY hh:mm A"
                    label="From Date and Time"
                    renderInput={(props) => (
                      <>
                        <TextField
                          sx={{
                            '& .MuiInputBase-root': {
                              color: '#000',
                              width: 180
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#000'
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#258B96'
                            }
                          }}
                          variant="standard"
                          onKeyDown={(v) => v.preventDefault()}
                          {...props}
                        />

                        {startDate && (
                          <IconButton
                            size="small"
                            style={{ position: 'absolute', right: 14, top: 16 }}
                            onClick={() => {
                              setErrorMessage('');
                              setStartDate(null);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )}
                    value={startDate}
                    onChange={(newValue) => {
                      setErrorMessage('');
                      setErrorMessagePosition(null);
                      setStartDate(newValue);
                    }}
                  />
                </FilterBarItem>
                <FilterBarItem>
                  <DateTimePicker
                    disableFuture
                    inputFormat="DD/MM/YYYY hh:mm A"
                    label="To Date and Time"
                    renderInput={(props) => (
                      <>
                        <TextField
                          sx={{
                            '& .MuiInputBase-root': {
                              color: '#000',
                              width: 180
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#000'
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#258B96'
                            }
                          }}
                          variant="standard"
                          onKeyDown={(v) => v.preventDefault()}
                          {...props}
                        />

                        {endDate && (
                          <IconButton
                            size="small"
                            style={{ position: 'absolute', right: 14, top: 16 }}
                            onClick={() => {
                              setErrorMessage('');
                              setEndDate(null);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )}
                    value={endDate}
                    onChange={(newValue) => {
                      setErrorMessage('');
                      setErrorMessagePosition(null);
                      setEndDate(newValue);
                    }}
                  />
                </FilterBarItem>
                {errorMessage && errorMessage.length > 0 && (
                  <DateSectionErrorWrapper style={errorMessagePosition && { left: errorMessagePosition.left }}>
                    {errorMessage}
                  </DateSectionErrorWrapper>
                )}
              </DateSectionWrapper>

              <FilterBarItem>
                <SearchWrapper>
                  <Button className="search-btn" size="medium" variant="contained" onClick={handleSearchButton}>
                    Search
                  </Button>
                </SearchWrapper>
              </FilterBarItem>
            </FilterBarWrapper>
          </FilterBar>
        </Grid>
      </FilterBarSectionWrapper>

      {enabledLoadingListView ? (
        loadingComponent()
      ) : (
        <TableContainer style={{ boxShadow: 'unset', scrollbarWidth: 'none', minHeight: 400 }}>
          <Table
            stickyHeader
            aria-label="customized table"
            size="small"
            style={{ padding: '0px 8px', borderCollapse: 'unset', scrollbarWidth: 'none' }}
            sx={{ minWidth: 700, maxHeight: 200 }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <SortableHeader onClick={() => handleClickSortableHeader('name')}>
                    Resource {renderSortIcon('name')}
                  </SortableHeader>
                </StyledTableCell>
                <StyledTableCell align="left" width={150}>
                  <SortableHeader onClick={() => handleClickSortableHeader('date')}>
                    Date and Time {renderSortIcon('date')}
                  </SortableHeader>
                </StyledTableCell>

                {selectedType.includes(primaryVital) &&
                  prepareVitalTableHeaderCell(vitalName, vitalUnit, primaryVital !== '' ? 'primary' : '')}

                {initiallySelectedVitalTypes.length >= 2 &&
                  selectedType.includes(initiallySelectedVitalTypes[1]) &&
                  prepareVitalTableHeaderCell(secondaryVitalName, secondaryVitalUnit, 'secondary')}

                <StyledTableCell align="left">Remark</StyledTableCell>
                {canDeleteVital && (
                  <StyledTableCell align="left" width={50}>
                    Action
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {vitalListView.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={canGoToProfile ? { color: '#009ca6', cursor: 'pointer' } : {}}
                    onClick={() => handleNavigateToClientDetail(row.resourceId!)}
                  >
                    {row.resourceName ? row.resourceName.toUpperCase() : ''}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(row.recordedDateTime).format('DD/MM/YYYY hh:mm A').toUpperCase()}
                  </StyledTableCell>

                  {prepareVitalValueCell(selectedType[0], row.value)}

                  {selectedType.length >= 2 && prepareVitalValueCell(secondaryVitalName, row.secondaryValue)}

                  <StyledTableCell align="left">
                    <div style={{ textOverflow: 'ellipsis' }}>
                      {canUpdateVital ? <>{prepareRemark(row)}</> : <>{prepareRemark(row)}</>}
                    </div>
                  </StyledTableCell>
                  {canDeleteVital && (
                    <StyledTableCell align="left" width={50}>
                      {!row.isLock ? (
                        <>
                          <Tooltip title="Edit">
                            <EditIcon
                              fontSize="small"
                              style={{ marginLeft: 5, color: '#258B96', cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedId(row.id);
                                setSelectRemark(row.remark);
                                handleEditRemark(row);
                              }}
                            />
                          </Tooltip>

                          <Tooltip title="Delete">
                            <DeleteIcon
                              fontSize="small"
                              style={{ color: '#258B96', cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedId(null);
                                setSelectRemark(null);
                                handleDeleteRecord(row.id);
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <EditIcon fontSize="small" style={{ marginLeft: 5, color: '#258B96', opacity: 0.3 }} />
                          <DeleteIcon fontSize="small" style={{ color: '#258B96', opacity: 0.3 }} />
                        </>
                      )}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalElements}
            labelRowsPerPage="Size"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
};

export default VitalListView;
