/* eslint-disable @typescript-eslint/no-empty-interface */
// BASE
import { BaseState } from './base';

export interface AppMessage {
  type: 'success' | 'error' | 'warning' | 'info';
  content: string;
}

export enum PostMessageType {
  SET_SELECTED_VITAL = 'SET_SELECTED_VITAL',
  FETCH_MASTER_DATA = 'FETCH_MASTER_DATA',
  CONFIRMED_TO_DELETE = 'CONFIRMED_TO_DELETE',
  SHOW_CONFIRMATION_DELETE_POPUP = 'SHOW_CONFIRMATION_DELETE_POPUP',
  SHOW_ALL_VITALS_INSERT_POPUP = 'SHOW_ALL_VITALS_INSERT_POPUP',
  SHOW_DOWNLOAD_REPORT_POPUP = 'SHOW_DOWNLOAD_REPORT_POPUP',
  SAVE_ALL_VITALS_DATA = 'SAVE_ALL_VITALS_DATA',
  SAVE_ALL_VITALS_STATUS = 'SAVE_ALL_VITALS_STATUS',
  NAVIGATE_PARENT = 'NAVIGATE_PARENT',
  NAVIGATE_TO_CLIENT = 'NAVIGATE_TO_CLIENT',
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  PROCEED_DOWNLOADING = 'PROCEED_DOWNLOADING',
  PROCEED_DOWNLOADING_SECOND_ACTION = 'PROCEED_DOWNLOADING_SECOND_ACTION',
  DOWNLOADING_COMPLETED = 'DOWNLOADING_COMPLETED',
  SHOW_ERROR_MESSAGE_NO_DATA = 'SHOW_ERROR_MESSAGE_NO_DATA',
  NAVIGATE_TO_OTHER_VITAL = 'NAVIGATE_TO_OTHER_VITAL',
  NAVIGATE_TO_MULTIPLE_VITAL = 'NAVIGATE_TO_MULTIPLE_VITAL',
  SHOW_SELECT_SECONDARY_VITAL_POPUP = 'SHOW_SELECT_SECONDARY_VITAL_POPUP',
  OPEN_EDIT_REMARK_POPUP = 'OPEN_EDIT_REMARK_POPUP',
  UPDATE_REMARK = 'UPDATE_REMARK',
  UPDATE_VITALS_STATUS = 'UPDATE_VITALS_STATUS',
  SET_PARENT_VIEW_TYPE = 'SET_PARENT_VIEW_TYPE',
  SET_PARENT_CUSTOM_VIEW_TYPE = 'SET_PARENT_CUSTOM_VIEW_TYPE',
  SET_PARENT_GRAPH_START_DATE = 'SET_PARENT_GRAPH_START_DATE',
  SET_PARENT_GRAPH_END_DATE = 'SET_PARENT_GRAPH_END_DATE'
}

export interface InterfaceMessageEvent<T> {
  type: PostMessageType;
  data: T;
}

export interface AppState extends BaseState {
  token: string;
  clientID: string;
  clientName: string;
  clientDOB: string;
  clientGender: string;
  message?: AppMessage;
  usersRoles: string[];
}
