import React, { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// REDUX
import { useAppDispatch, useAppSelector } from 'hooks';

// SELECTOR
import { selectDashboardData, selectLoadingDashboard } from 'redux/vital/selectors';
import { selectAppToken, selectClientId, canCreate } from 'redux/app/selectors';

// COMPONENTS
import MeasurementItem from 'components/MeasurementItem';
import GraphViewWrapper from 'pages/VitalDetail/downloadGraphViewWrapper';

// STYLE
import { EmptyStateWrapper, WarningIconIns, BigTypographyIns, SmallTypographyIns } from './styles';

// ACTION
import { getDashboardData, openAllVitalsOpenPopup, openDownloadVitalsPopUp } from 'redux/vital/action';
import { Button } from '@mui/material';
import { FileDownloadOutlined, Refresh } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector(selectDashboardData);
  const loadingDashboard = useAppSelector(selectLoadingDashboard);
  const appToken = useAppSelector(selectAppToken);
  const clientId = useAppSelector(selectClientId);
  const canCreateVital = useAppSelector(canCreate);

  useEffect(() => {
    if (clientId.length > 0) {
      dispatch(getDashboardData(true));
    }
  }, [appToken, clientId, dispatch]);

  const handleOpenVitalsPopUp = () => {
    dispatch(openAllVitalsOpenPopup(clientId));
  };

  const handleOpenDownloadVitalsPopUp = () => {
    dispatch(openDownloadVitalsPopUp(clientId));
  };

  const handleRefresh = () => {
    if (clientId.length > 0) {
      dispatch(getDashboardData(true));
    }
  };

  if (loadingDashboard) {
    return (
      <div style={{ width: '100%', height: 500, textAlign: 'center', marginTop: 30 }}>
        <CircularProgress size={30} />
      </div>
    );
  }

  if (dashboardData.length === 0 && !loadingDashboard) {
    return (
      <>
        {canCreateVital && (
          <Grid container justifyContent="flex-end">
            <Button color="primary" startIcon={<AddCircleOutlineIcon />} variant="text" onClick={handleOpenVitalsPopUp}>
              Add Records
            </Button>
          </Grid>
        )}
        <EmptyStateWrapper>
          <WarningIconIns />
          <BigTypographyIns>No Data Available</BigTypographyIns>
          <SmallTypographyIns>Add Record from above button</SmallTypographyIns>
        </EmptyStateWrapper>
      </>
    );
  }

  return (
    <>
      <Grid container justifyContent="flex-end">
        {canCreateVital && (
          <Button
            className="primary-txt-btn"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
            onClick={handleOpenVitalsPopUp}
          >
            Add Records
          </Button>
        )}

        <Button
          className="primary-txt-btn"
          color="primary"
          startIcon={<FileDownloadOutlined />}
          variant="text"
          onClick={handleOpenDownloadVitalsPopUp}
        >
          Download Vital Records
        </Button>
        <Button
          className="primary-txt-btn"
          color="primary"
          startIcon={<Refresh />}
          variant="text"
          onClick={handleRefresh}
        >
          Refresh
        </Button>
      </Grid>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
        {dashboardData.map((v) => (
          <MeasurementItem key={v.type} lastAgo={v.lastAgo} type={v.type} unit={v.unit} value={v.value} />
        ))}
      </div>

      <GraphViewWrapper />
    </>
  );
};

export default Dashboard;
