import React, { FC, ReactNode, useCallback, useEffect } from 'react';

// REDUX
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectClientId } from 'redux/app/selectors';

// ACTIONS
import { handleMessageFromParentIframe, handleFetchClientInformation, handleFetchUserRole } from 'redux/app/actions';

const App: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const clientId = useAppSelector(selectClientId);

  const handleMsgFromIframe = useCallback(
    (evt: MessageEvent) => {
      dispatch(handleMessageFromParentIframe(evt));
    },
    [dispatch]
  );

  useEffect(() => {
    if (clientId.length > 0) {
      dispatch(handleFetchClientInformation());
      dispatch(handleFetchUserRole());
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    window.addEventListener('message', handleMsgFromIframe, false);

    return () => {
      window.removeEventListener('message', handleMsgFromIframe);
    };
  }, [dispatch, handleMsgFromIframe]);

  useEffect(() => {
    window.parent.postMessage('done', '*');
  }, []);

  return <>{children}</>;
};

export default App;
