import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import {
  VitalState,
  MeasurementItem,
  VitalRowItem,
  VitalGraphItem,
  VitalReportingItem,
  VitalListViewStatusType,
  DataWaitingForDownload,
  BaseLine,
  ViewType
} from 'model';

// ACTION TO CHANGE STATE
export const changeVitalLoading: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setDashboardData: CaseReducer<VitalState, PayloadAction<MeasurementItem[]>> = (state, action) => {
  state.dashboard = action.payload;
};

export const setVitalListView: CaseReducer<VitalState, PayloadAction<VitalRowItem[]>> = (state, action) => {
  state.vitalListView = action.payload;
};

export const setVitalListViewStatus: CaseReducer<VitalState, PayloadAction<VitalListViewStatusType>> = (
  state,
  action
) => {
  state.vitalListViewStatus = action.payload;
};

export const setCurrentPage: CaseReducer<VitalState, PayloadAction<number>> = (state, action) => {
  state.currentPage = action.payload;
};

export const setCurrentPageSize: CaseReducer<VitalState, PayloadAction<number>> = (state, action) => {
  state.currentPageSize = action.payload;
};

export const setTotalElements: CaseReducer<VitalState, PayloadAction<number>> = (state, action) => {
  state.totalElements = action.payload;
};

export const setVitalGraphView: CaseReducer<VitalState, PayloadAction<VitalGraphItem[]>> = (state, action) => {
  state.vitalGraphView = action.payload;
};

export const setVitalGraphLabel: CaseReducer<VitalState, PayloadAction<number[]>> = (state, action) => {
  state.vitalGraphLabel = action.payload;
};

export const setVitalGraphData: CaseReducer<VitalState, PayloadAction<any[]>> = (state, action) => {
  state.vitalGraphData = action.payload;
};

export const setSecondVitalGraphData: CaseReducer<VitalState, PayloadAction<any[]>> = (state, action) => {
  state.secondVitalGraphData = action.payload;
};

export const setThirdVitalGraphData: CaseReducer<VitalState, PayloadAction<any[]>> = (state, action) => {
  state.thirdVitalGraphData = action.payload;
};

export const setVitalGraphReporting: CaseReducer<VitalState, PayloadAction<VitalReportingItem[]>> = (state, action) => {
  state.vitalGraphReportData = action.payload;
};

export const setLoadingGraph: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.loadingGraph = action.payload;
};

export const setLoadingListView: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.loadingListView = action.payload;
};

export const setLoadingDashboard: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.loadingDashboard = action.payload;
};

export const setBaseLine: CaseReducer<VitalState, PayloadAction<BaseLine>> = (state, action) => {
  state.baseLine = action.payload;
};

export const setSelectedVitalType: CaseReducer<VitalState, PayloadAction<string[]>> = (state, action) => {
  state.selectedVitalTypes = action.payload;
};

export const setInitialSelectedVitalType: CaseReducer<VitalState, PayloadAction<string[]>> = (state, action) => {
  state.initialVitalTypes = action.payload;
};

export const setEnabledZoomPan: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.enabledZoomPan = action.payload;
};

export const setForceRendeChart: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.forceRenderChart = action.payload;
};

export const setDataForDownload: CaseReducer<VitalState, PayloadAction<DataWaitingForDownload | undefined>> = (
  state,
  action
) => {
  state.dataWaitingForDownload = action.payload;
};
export const setIsDownloadInprogress: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.isDownloadingInProgress = action.payload;
};

export const setIsMultiVitals: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.isMultiVitals = action.payload;
};

export const setViewType: CaseReducer<VitalState, PayloadAction<ViewType>> = (state, action) => {
  state.viewType = action.payload;
};

export const setIsShowBorderline: CaseReducer<VitalState, PayloadAction<boolean>> = (state, action) => {
  state.isShowBorderline = action.payload;
};

export const setCustomViewType: CaseReducer<VitalState, PayloadAction<ViewType>> = (state, action) => {
  state.customViewType = action.payload;
};

export const setGraphStartDate: CaseReducer<VitalState, PayloadAction<Date>> = (state, action) => {
  state.graphStartDate = action.payload;
};

export const setGraphEndDate: CaseReducer<VitalState, PayloadAction<Date>> = (state, action) => {
  state.graphEndDate = action.payload;
};

export const setReferenceRanges: CaseReducer<VitalState, PayloadAction<Record<string, any>>> = (state, action) => {
  state.referenceRanges = action.payload;
};
