import React, { FC, useCallback, useMemo } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

// REDUX
import { useAppDispatch } from 'hooks';

// ACTIONS
import { sendMessageToParent } from 'redux/app/actions';

// MESSAGE TYPE
import { PostMessageType } from 'model';

// TYPE
import { IMeasurementItem } from './interface';

// ICON
import { ReactComponent as BPIcon } from 'assets/icon/bp.svg';
import { ReactComponent as BloodGlucose } from 'assets/icon/blood_glucose.svg';
import { ReactComponent as HeartRate } from 'assets/icon/heart_rate.svg';
import { ReactComponent as Pain } from 'assets/icon/pain.svg';
import { ReactComponent as RespirationRate } from 'assets/icon/respiration_rate.svg';
import { ReactComponent as SP02 } from 'assets/icon/sp02.svg';
import { ReactComponent as Temp } from 'assets/icon/temperature.svg';
import { ReactComponent as Weight } from 'assets/icon/weight.svg';

const MeasurementItem: FC<IMeasurementItem> = ({ type, value, unit, lastAgo }) => {
  const dispatch = useAppDispatch();

  const icon = useMemo(() => {
    if (type === 'BLOOD_GLUCOSE') {
      return <BloodGlucose width={68} />;
    }

    if (type === 'BP_SYSTOLIC') {
      return <BPIcon width={68} />;
    }

    if (type === 'HEART_RATE') {
      return <HeartRate width={68} />;
    }

    if (type === 'PAIN') {
      return <Pain width={68} />;
    }

    if (type === 'RESPIRATION_RATE') {
      return <RespirationRate width={68} />;
    }

    if (type === 'SPO2') {
      return <SP02 width={68} />;
    }

    if (type === 'TEMPERATURE') {
      return <Temp width={68} />;
    }

    return <Weight width={68} />;
  }, [type]);

  const text = useMemo(() => {
    if (type === 'BLOOD_GLUCOSE') {
      return 'Blood glucose';
    }

    if (type === 'BP_SYSTOLIC') {
      return 'BP- Systolic / Diastolic';
    }

    if (type === 'HEART_RATE') {
      return 'Heart Rate';
    }

    if (type === 'PAIN') {
      return 'Pain';
    }

    if (type === 'RESPIRATION_RATE') {
      return 'Respiration Rate';
    }

    if (type === 'SPO2') {
      return 'SpO2 Saturation';
    }

    if (type === 'TEMPERATURE') {
      return 'Temperature';
    }

    return 'Weight';
  }, [type]);

  const handleNavigateToDetail = useCallback(() => {
    dispatch(sendMessageToParent<{ type: string }>({ type }, PostMessageType.NAVIGATE_PARENT));

    window.localStorage.setItem('currentTab', `0`);
  }, [dispatch, type]);

  return (
    <Card
      style={{
        background: '#F9F9F9',
        border: '1px solid #FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        width: 205,
        height: 251,
        marginRight: 15,
        marginBottom: 15
      }}
    >
      <CardContent
        style={{
          height: 221,
          paddingTop: 15,
          paddingBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: '#4B4B4C' }}>{text}</Typography>
        <div style={{ paddingTop: 25, paddingBottom: 25 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
            {icon}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 18, color: '#258B96', fontWeight: 'bold', marginRight: 0.5 }}>
              {value}
            </Typography>
            <Typography sx={{ fontSize: 14, color: '#959595' }}>{unit}</Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 12, color: '#959595' }}>{lastAgo}</Typography>
          </div>
        </div>
        <Typography
          sx={{ fontSize: 12, color: '#258B96', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={handleNavigateToDetail}
        >
          View Detail
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MeasurementItem;
