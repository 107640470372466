/* eslint-disable max-len */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import Point from 'assets/icon/point.png';
import RedPoint from 'assets/icon/red-dot.png';
import PurplePoint from 'assets/icon/purple-dot.png';
import Reload from 'assets/icon/loading.png';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { generateViewTypeByDayDiff } from 'helper/index';
import GraphView from './graphview';
import DownloadGraphViewWrapper from './downloadGraphViewWrapper';

// MODEL
import { ViewType, VitalType } from 'model';

// REDUX
import { useAppDispatch, useAppSelector } from 'hooks';

// SELECTOR
import {
  getInitialSelectedVitalType,
  getSelectedVitalType,
  selectIsDownloadingInprogress,
  selectIsShowBorderline,
  selectVitalText,
  selectVitalUnit
} from 'redux/vital/selectors';
import { canCreate, selectClientId } from 'redux/app/selectors';

// ACTIONS
import {
  fetchDataForReporting,
  fetchDataForReportingForMultipleVital,
  getGraphData,
  loadReferenceRange,
  openAllVitalsOpenPopup,
  openSelectSecondaryVitalPopup,
  setForceRendeChart,
  setSelectedVitalType,
  setVitalGraphData,
  setVitalGraphLabel,
  setIsShowBorderline,
  setVitalGraphView
} from 'redux/vital/action';

//STYLES
import {
  BpmPurpleRow,
  BpmRedRow,
  BpmRow,
  CheckboxLabelWrapper,
  DateWrapper,
  IconTextButton,
  ListChartViewWrapper,
  RefreshWrapper,
  Tab,
  TitleVitalWrapper,
  TitleWrapper,
  ViewTypeSelectionItem,
  ViewTypeSelectionWrapper,
  VitalTital,
  VitalTitalTitle,
  VitalUnitText
} from './styles';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';
import { VitalsFieldNameEnum } from 'constant/vitals';

// HELPER
import { generateStartDateAndEndDateByViewType } from 'helper';

const arrayViewType = [
  {
    label: 'Day',
    value: ViewType.Day
  },
  {
    label: 'Week',
    value: ViewType.Week
  },
  {
    label: 'Month',
    value: ViewType.Month
  },
  {
    label: '3 Months',
    value: ViewType.ThreeMonth
  },
  {
    label: '6 Months',
    value: ViewType.SixMonth
  },
  {
    label: 'Year',
    value: ViewType.Year
  },
  {
    label: 'Custom',
    value: ViewType.Custom
  }
];

const VitalGraph: FC<{
  selectedTab: number;
  onSelect: (tab: number) => void;
  startDate: Date;
  setStartDate: (data: Date) => void;
  endDate: Date;
  setEndDate: (data: Date) => void;
  viewType: ViewType;
  setViewType: (data: ViewType) => void;
  customViewType: ViewType | null | undefined;
  setCustomViewType: (data: ViewType | null) => void;
}> = ({
  selectedTab,
  onSelect,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  viewType,
  setViewType,
  customViewType,
  setCustomViewType
}) => {
  const dispatch = useAppDispatch();
  const clientId = useAppSelector(selectClientId);
  const selectedTypes = useAppSelector(getSelectedVitalType);
  const initiallySelectedVitalTypes = useAppSelector(getInitialSelectedVitalType);
  const vitalText = useAppSelector(selectVitalText)(
    initiallySelectedVitalTypes.length > 0
      ? (initiallySelectedVitalTypes[0]?.toUpperCase() as VitalType)
      : (selectedTypes[0]?.toUpperCase() as VitalType)
  );
  const vitalUnit = useAppSelector(
    selectVitalUnit(
      initiallySelectedVitalTypes.length > 0
        ? (initiallySelectedVitalTypes[0]?.toUpperCase() as VitalType)
        : (selectedTypes[0]?.toUpperCase() as VitalType)
    )
  );
  const vitalText1 = useAppSelector(selectVitalText)(
    initiallySelectedVitalTypes.length > 0
      ? (initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType)
      : (selectedTypes[1]?.toUpperCase() as VitalType)
  );
  const vitalUnit1 = useAppSelector(
    selectVitalUnit(
      initiallySelectedVitalTypes.length > 0
        ? (initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType)
        : (selectedTypes[1]?.toUpperCase() as VitalType)
    )
  );
  const [customStartDate, setCustomStartDate] = useState<Date | null>();
  const [customEndDate, setCustomEndDate] = useState<Date | null>();
  const [graphLoaded, setGraphLoaded] = useState(false);
  const canCreateVital = useAppSelector(canCreate);
  const isEnabledMultipleGraph = Boolean(process.env.REACT_APP_ENABLED_ENABLED_MULTIPLE);
  const isDownloadingInprogress = useAppSelector(selectIsDownloadingInprogress);
  const isShowBorderline = useAppSelector(selectIsShowBorderline);
  const [isShowBorderlineToggle, setShowBorderlineToggle] = useState(true);

  const primaryVitalName = VitalsFieldNameEnum[initiallySelectedVitalTypes[0]?.toUpperCase() as VitalType];
  const secondaryVitalName = VitalsFieldNameEnum[initiallySelectedVitalTypes[1]?.toUpperCase() as VitalType];

  useEffect(() => {
    if (graphLoaded && selectedTypes && selectedTypes.length > 0 && clientId && clientId.length > 0) {
      dispatch(getGraphData());
      dispatch(loadReferenceRange());

      const hideBorderlineList = ['WEIGHT', 'PAIN'];

      let showBorderToggle = false;

      selectedTypes.forEach((type) => {
        if (!hideBorderlineList.includes(type)) {
          showBorderToggle = true;
        }
      });
      setShowBorderlineToggle(showBorderToggle);
    }
  }, [selectedTypes, clientId, graphLoaded]);

  useEffect(() => {
    return () => {
      setStartDate(dayjs(new Date()).subtract(1, 'day').toDate());
      setEndDate(dayjs(new Date()).toDate());
      setViewType(ViewType.Day);
      dispatch(setVitalGraphView([]));
      dispatch(setVitalGraphData([]));
      dispatch(setVitalGraphLabel([]));
    };
  }, [dispatch]);

  const onDoneLoadingChart = useCallback(() => {
    setGraphLoaded(true);
  }, []);

  const handleChangeViewType = (viewTypeValue: ViewType) => {
    const generateStartDateAndEndDate = generateStartDateAndEndDateByViewType(
      viewTypeValue as ViewType,
      dayjs(startDate),
      dayjs(endDate)
    );

    setStartDate(generateStartDateAndEndDate.startDate.toDate());
    setEndDate(generateStartDateAndEndDate.endDate.toDate());

    if (viewTypeValue !== ViewType.Custom) {
      setCustomViewType(viewTypeValue);
    }

    dispatch(setForceRendeChart(true));
    setCustomStartDate(null);
    setCustomEndDate(null);
    setViewType(viewTypeValue);
  };

  const handleRefreshButton = () => {
    if (selectedTypes.length === 0) return;

    const start = startDate || dayjs().startOf('date').toDate();
    const end = endDate || dayjs().endOf('date').toDate();

    const dayDiff = dayjs(end).diff(dayjs(start), 'days');

    let customViewTypeLocal: ViewType = ViewType.Day;

    if (dayDiff >= 1 && dayDiff <= 7) {
      customViewTypeLocal = ViewType.Week;
    }

    if (dayDiff > 7 && dayDiff <= 30) {
      customViewTypeLocal = ViewType.Month;
    }

    if (dayDiff > 30 && dayDiff <= 180) {
      customViewTypeLocal = ViewType.ThreeMonth;
    }

    if (dayDiff > 180) {
      customViewTypeLocal = ViewType.Year;
    }

    if (dayDiff > 365) return;

    if (viewType === ViewType.Custom) {
      setCustomViewType(customViewTypeLocal);
    }

    dispatch(getGraphData());
  };

  const handleAddRecord = () => {
    if (selectedTypes[0]) {
      dispatch(openAllVitalsOpenPopup(clientId, '', '', 'CUSTOM_FIELDS', selectedTypes));
    }
  };

  const handleViewMultiVitals = () => {
    if (selectedTypes[0]) {
      dispatch(openSelectSecondaryVitalPopup(clientId, selectedTypes[0]?.toUpperCase(), initiallySelectedVitalTypes));
    }
  };

  const handleDownloadReport = () => {
    const dataType: any = {
      BLOOD_GLUCOSE: 'bloodGlucose',
      BP_SYSTOLIC: 'bp',
      HEART_RATE: 'heartRate',
      PAIN: 'pain',
      RESPIRATION_RATE: 'respiration',
      SPO2: 'spo2',
      TEMPERATURE: 'temp',
      WEIGHT: 'weight'
    };

    const viewTypeObj: any = {
      [ViewType.Day]: 'day',
      [ViewType.Week]: 'week',
      [ViewType.Month]: 'month',
      [ViewType.ThreeMonth]: '3months',
      [ViewType.SixMonth]: '6months',
      [ViewType.Year]: 'year',
      [ViewType.Custom]: 'custom'
    };

    const dataForDownload = {
      [dataType[selectedTypes[0]!.toUpperCase()]]: true,
      period: viewTypeObj[viewType],
      startDateTime: viewType === ViewType.Custom ? startDate.toISOString() : '',
      endDateTime: viewType === ViewType.Custom ? endDate.toISOString() : ''
    };

    if (initiallySelectedVitalTypes.length === 2 && selectedTypes.length === 2)
      return dispatch(fetchDataForReportingForMultipleVital(dataForDownload as any));

    return dispatch(fetchDataForReporting(dataForDownload as any, true));
  };

  const toggleSelectedVital = (clickedVital: string) => {
    if (selectedTypes.includes(clickedVital)) {
      if (selectedTypes.length <= 1) return;

      dispatch(setSelectedVitalType(selectedTypes.filter((t) => t !== clickedVital)));
    } else {
      dispatch(
        setSelectedVitalType(
          clickedVital === initiallySelectedVitalTypes[0].toString()
            ? [clickedVital, ...selectedTypes]
            : [...selectedTypes, clickedVital]
        )
      );
    }

    dispatch(setForceRendeChart(true));
  };

  const handleSetCustomStartDate = useCallback((date: Date) => {
    setCustomStartDate(date);
  }, []);

  const handleSetCustomEndDate = useCallback((date: Date) => {
    setCustomEndDate(date);
  }, []);

  const generateTitleSelectedType = useMemo(() => {
    const unitVital: any = {
      HEART_RATE: 'bpm',
      BLOOD_GLUCOSE: 'mmoL',
      BP_SYSTOLIC: 'mmHg',
      TEMPERATURE: '°C',
      SPO2: '%',
      WEIGHT: 'Kg',
      PAIN: 'Scale',
      RESPIRATION_RATE: 'bpm'
    };

    const isBP = selectedTypes.find((v) => v === 'BP_SYSTOLIC');
    const nonBP = selectedTypes.find((v) => v !== 'BP_SYSTOLIC');
    const isMultipleVital = initiallySelectedVitalTypes.length === 2;
    const isBpPrimary = initiallySelectedVitalTypes[0] === 'BP_SYSTOLIC';
    const ishasBP = initiallySelectedVitalTypes.filter((v) => v === 'BP_SYSTOLIC').length > 0;
    const firstVital = initiallySelectedVitalTypes[0];
    const firstVitalFromSelectedType = selectedTypes.includes(firstVital);
    const secondVital = initiallySelectedVitalTypes[1];
    const secondVitalFromSelectedType = selectedTypes.includes(secondVital);

    if (isMultipleVital && isBpPrimary && ishasBP) {
      return (
        <>
          {isBP && (
            <>
              <TitleWrapper>
                <BpmRow>
                  <img src={Point} />
                </BpmRow>
                <VitalUnitText>SBP (mmHg)</VitalUnitText>
              </TitleWrapper>
              <TitleWrapper>
                <BpmRedRow>
                  <img src={RedPoint} />
                </BpmRedRow>
                <VitalUnitText>DBP (mmHg)</VitalUnitText>
              </TitleWrapper>
            </>
          )}
          {nonBP && (
            <TitleWrapper>
              <BpmPurpleRow>
                <img src={PurplePoint} />
              </BpmPurpleRow>
              <VitalUnitText>{unitVital[nonBP]}</VitalUnitText>
            </TitleWrapper>
          )}
        </>
      );
    }

    if (isMultipleVital && !isBpPrimary && ishasBP) {
      return (
        <>
          {nonBP && (
            <TitleWrapper>
              <BpmRow>
                <img src={Point} />
              </BpmRow>
              <VitalUnitText>{unitVital[nonBP]}</VitalUnitText>
            </TitleWrapper>
          )}
          {isBP && (
            <>
              <TitleWrapper>
                <BpmRedRow>
                  <img src={RedPoint} />
                </BpmRedRow>
                <VitalUnitText>SBP (mmHg)</VitalUnitText>
              </TitleWrapper>
              <TitleWrapper>
                <BpmPurpleRow>
                  <img src={PurplePoint} />
                </BpmPurpleRow>
                <VitalUnitText>DBP (mmHg)</VitalUnitText>
              </TitleWrapper>
            </>
          )}
        </>
      );
    }

    if (isMultipleVital && !ishasBP) {
      return (
        <>
          {firstVitalFromSelectedType && (
            <TitleWrapper>
              <BpmRow>
                <img src={Point} />
              </BpmRow>
              <VitalUnitText>{unitVital[firstVital]}</VitalUnitText>
            </TitleWrapper>
          )}
          {secondVitalFromSelectedType && (
            <TitleWrapper>
              <BpmRedRow>
                <img src={RedPoint} />
              </BpmRedRow>
              <VitalUnitText>{unitVital[secondVital]}</VitalUnitText>
            </TitleWrapper>
          )}
        </>
      );
    }

    if (!isMultipleVital && isBP) {
      return (
        <>
          <TitleWrapper>
            <BpmRow>
              <img src={Point} />
            </BpmRow>
            <VitalUnitText>SBP (mmHg)</VitalUnitText>
          </TitleWrapper>
          <TitleWrapper>
            <BpmRedRow>
              <img src={RedPoint} />
            </BpmRedRow>
            <VitalUnitText>DBP (mmHg)</VitalUnitText>
          </TitleWrapper>
        </>
      );
    }

    if (!isMultipleVital && nonBP) {
      return (
        <TitleWrapper>
          <BpmRow>
            <img src={Point} />
          </BpmRow>
          <VitalUnitText>{unitVital[nonBP]}</VitalUnitText>
        </TitleWrapper>
      );
    }

    return null;
  }, [selectedTypes, initiallySelectedVitalTypes]);

  const handleBorderlineVisibilityChange = () => {
    dispatch(setIsShowBorderline(!isShowBorderline));
    dispatch(setForceRendeChart(true));
  };

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item sm={3} xs={3}>
          <ListChartViewWrapper>
            <Tabs
              centered
              value={selectedTab}
              onChange={(v, newValue: number) => {
                onSelect(newValue);
                setTimeout(() => {
                  setStartDate(dayjs(new Date()).subtract(1, 'day').toDate());
                  setEndDate(dayjs(new Date()).toDate());
                  setViewType(ViewType.Day);
                  dispatch(setVitalGraphView([]));
                  dispatch(setVitalGraphData([]));
                  dispatch(setVitalGraphLabel([]));
                }, 100);
              }}
            >
              <Tab label="Graph" />
              <Tab label="List" />
            </Tabs>
          </ListChartViewWrapper>
        </Grid>
        <Grid container item sm={9} xs={9}>
          <Grid container justifyContent="flex-end">
            {isShowBorderlineToggle && (
              <Grid item xs="auto">
                <FormControlLabel
                  control={<Switch checked={isShowBorderline} onChange={handleBorderlineVisibilityChange} />}
                  label={
                    <Typography
                      style={{
                        color: '#258B96',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: 1.75,
                        cursor: 'pointer'
                      }}
                    >
                      Borderline
                    </Typography>
                  }
                />
              </Grid>
            )}

            <Grid item xs="auto">
              {canCreateVital && (
                <IconTextButton
                  className="primary-txt-btn"
                  size="medium"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddRecord}
                >
                  Add record
                </IconTextButton>
              )}
            </Grid>

            <Grid item xs="auto">
              {isEnabledMultipleGraph && (
                <IconTextButton
                  className="primary-txt-btn"
                  size="medium"
                  startIcon={<VisibilityOutlined />}
                  onClick={handleViewMultiVitals}
                >
                  View Multiple Vitals
                </IconTextButton>
              )}
            </Grid>

            <Grid item xs="auto">
              <RefreshWrapper>
                <Button size="medium" variant="text" disabled={isDownloadingInprogress} onClick={handleDownloadReport}>
                  <SaveAltIcon className="refresh-icon" color="primary" />
                  Download
                  {isDownloadingInprogress && (
                    <CircularProgress color="inherit" size={13} style={{ marginLeft: '5px' }} />
                  )}
                </Button>
              </RefreshWrapper>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item alignItems="flex-end" justifyContent="flex-end" xs={12}>
          <Grid item xs="auto">
            {initiallySelectedVitalTypes.length >= 2 && (
              <FormGroup style={{ marginRight: '10px' }}>
                <Checkbox
                  checked={selectedTypes.includes(initiallySelectedVitalTypes[0])}
                  color="primary"
                  name={initiallySelectedVitalTypes[0]}
                  onChange={() => {
                    toggleSelectedVital(initiallySelectedVitalTypes[0]);
                  }}
                />
                <CheckboxLabelWrapper>{primaryVitalName}</CheckboxLabelWrapper>
              </FormGroup>
            )}
          </Grid>

          <Grid item xs="auto">
            {initiallySelectedVitalTypes.length >= 2 && (
              <FormGroup style={{ marginRight: '10px' }}>
                <Checkbox
                  checked={selectedTypes.includes(initiallySelectedVitalTypes[1])}
                  color="primary"
                  name={initiallySelectedVitalTypes[1]}
                  onChange={() => {
                    toggleSelectedVital(initiallySelectedVitalTypes[1]);
                  }}
                />
                <CheckboxLabelWrapper>{secondaryVitalName}</CheckboxLabelWrapper>
              </FormGroup>
            )}
          </Grid>

          <Grid item md="auto" sm={initiallySelectedVitalTypes.length >= 2 ? 8 : 10} xs={12}>
            <ViewTypeSelectionWrapper>
              {arrayViewType.map((v) => (
                <ViewTypeSelectionItem
                  key={v.value}
                  className={viewType === v.value ? 'active' : ''}
                  onClick={() => {
                    if (viewType !== v.value) {
                      handleChangeViewType(v.value);
                    }
                  }}
                >
                  {v.label}
                </ViewTypeSelectionItem>
              ))}
            </ViewTypeSelectionWrapper>
          </Grid>

          <Grid item xs="auto">
            <DateWrapper>
              <DatePicker
                disableFuture
                disabled={viewType !== ViewType.Custom}
                inputFormat="DD/MM/YYYY "
                label="From Date"
                maxDate={endDate}
                minDate={dayjs(endDate).subtract(365, 'days').toDate()}
                renderInput={(props) => (
                  <TextField
                    sx={{
                      '& .Mui-error': {
                        color: 'rgba(0, 0, 0, 0.38) !important'
                      },
                      '& .MuiInputBase-root': {
                        color: '#000',
                        width: 120
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#000'
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#258B96'
                      },
                      '& .Mui-error:after': {
                        borderBottom: 'none'
                      }
                    }}
                    variant="standard"
                    onKeyDown={(v) => v.preventDefault()}
                    {...props}
                  />
                )}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(dayjs(newValue!).startOf('date').toDate());
                  const localCustomViewType = generateViewTypeByDayDiff(
                    dayjs(newValue!).startOf('date'),
                    dayjs(endDate)
                  );

                  setCustomViewType(localCustomViewType);

                  setTimeout(() => {
                    dispatch(setForceRendeChart(true));
                  }, 100);
                }}
              />
            </DateWrapper>
          </Grid>

          <Grid item xs="auto">
            <DateWrapper>
              <DatePicker
                disableFuture
                disabled={viewType !== ViewType.Custom}
                inputFormat="DD/MM/YYYY"
                label="To Date"
                maxDate={dayjs(startDate).add(365, 'days').toDate()}
                minDate={startDate}
                renderInput={(props) => (
                  <TextField
                    sx={{
                      '& .Mui-error': {
                        color: 'rgba(0, 0, 0, 0.38) !important'
                      },
                      '& .MuiInputBase-root': {
                        color: '#000',
                        width: 120
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#000'
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#258B96'
                      },
                      '& .Mui-error:after': {
                        borderBottom: 'none'
                      }
                    }}
                    variant="standard"
                    onKeyDown={(v) => v.preventDefault()}
                    {...props}
                  />
                )}
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(dayjs(newValue!).endOf('date').toDate());
                  const localCustomViewType = generateViewTypeByDayDiff(
                    dayjs(startDate),
                    dayjs(newValue).endOf('date')
                  );

                  setCustomViewType(localCustomViewType);

                  setTimeout(() => {
                    dispatch(setForceRendeChart(true));
                  }, 100);
                }}
              />
            </DateWrapper>
          </Grid>

          <Grid item xs="auto">
            <RefreshWrapper>
              <Button size="medium" variant="text" onClick={handleRefreshButton}>
                <img className="refresh-icon" src={Reload} /> Refresh
              </Button>
            </RefreshWrapper>
          </Grid>
        </Grid>
      </Grid>
      <VitalTital>
        {viewType === ViewType.Day || (viewType === ViewType.Custom && customViewType === ViewType.Day) ? (
          <VitalTitalTitle>{dayjs(customEndDate || endDate).format('MMMM DD, YYYY')}</VitalTitalTitle>
        ) : (
          <VitalTitalTitle>{`${dayjs(customStartDate || startDate).format('MMMM DD, YYYY')} - ${dayjs(
            customEndDate || endDate
          ).format('MMMM DD, YYYY')}`}</VitalTitalTitle>
        )}

        <TitleVitalWrapper>{generateTitleSelectedType}</TitleVitalWrapper>
      </VitalTital>

      <GraphView
        customViewType={customViewType}
        endDate={endDate}
        id="main_chart_js"
        setEndDate={handleSetCustomEndDate}
        setStartDate={handleSetCustomStartDate}
        setViewType={setViewType}
        startDate={startDate}
        viewType={viewType}
        vitalText={vitalText}
        vitalText1={vitalText1}
        vitalUnit={vitalUnit}
        vitalUnit1={vitalUnit1}
        onDoneLoadingChart={onDoneLoadingChart}
      />

      <DownloadGraphViewWrapper />
    </>
  );
};

export default VitalGraph;
