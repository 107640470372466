/* eslint-disable max-len */
import { jsPDF } from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import dayjs from 'dayjs';
import URI from 'urijs';
import { groupBy } from 'lodash';

// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';
import { sendMessageToParent } from 'redux/app/actions';

// SLICE
import { vitalSlice } from './slice';

// SELECTOR
import { selectClientId, selectClientInformation } from 'redux/app/selectors';
import {
  selectVitalListView,
  selectVitalText,
  selectVitalUnitWithType,
  getInitialSelectedVitalType,
  getGraphURLBasedOnSelectedVital,
  getIsMultiVitals,
  getSelectedVitalType
} from 'redux/vital/selectors';

// MODEL
import {
  AllVitalsDataFromIframe,
  MeasurementItem,
  PaginationData,
  ParamsFetchVitalRow,
  PostMessageType,
  ReportingIframe,
  ViewType,
  ViewTypeFromB3,
  VitalGraphItem,
  VitalListViewStatusType,
  VitalReportingItem,
  MultiVitalsRowItem,
  VitalRowItem,
  VitalsPopupTypeEnum,
  VitalType,
  UpdateVitalObject,
  Resource
} from 'model';

// HELPER
import { generateLabelsByTime, generateStartDateAndEndDateByViewType, generateViewTypeByDayDiffForPDF } from 'helper';

// IMG
import BPIconPng from 'assets/icon/bp.png';
import BloodGlucosePng from 'assets/icon/blood_glucose.png';
import HeartRatePng from 'assets/icon/heart_rate.png';
import PainPng from 'assets/icon/pain.png';
import RespirationRatePng from 'assets/icon/respiration_rate.png';
import SP02Png from 'assets/icon/sp02.png';
import TempPng from 'assets/icon/temperature.png';
import WeightPng from 'assets/icon/weight.png';

// CONSTANT
import { API_ENDPOINT } from 'constant';
import { VitalsShortNameEnum, VitalsFieldNameEnum } from 'constant/vitals';

// ASSETS
import Logo from 'assets/icon/logo.png';

// ACTIONS
const {
  setDashboardData,
  setVitalListView,
  setCurrentPage,
  setCurrentPageSize,
  setTotalElements,
  setVitalGraphView,
  setVitalGraphLabel,
  setVitalGraphData,
  setVitalGraphReporting,
  setSecondVitalGraphData,
  setVitalListViewStatus,
  setLoadingGraph,
  setLoadingListView,
  setSelectedVitalType,
  setThirdVitalGraphData,
  setInitialSelectedVitalType,
  setForceRendeChart,
  setLoadingDashboard,
  setIsDownloadInprogress,
  setIsMultiVitals,
  setViewType,
  setCustomViewType,
  setGraphStartDate,
  setGraphEndDate,
  setReferenceRanges,
  setIsShowBorderline
} = vitalSlice.actions;

export const vitalTypeFromVariable = (type: string): string => {
  if (type === 'heartRate') return 'HEART_RATE';

  if (type === 'pain') return 'PAIN';

  if (type === 'bloodGlucose') return 'BLOOD_GLUCOSE';

  if (type === 'bpDiastolic') return 'BP_DIASTOLIC';

  if (type === 'bpSystolic') return 'BP_SYSTOLIC';

  if (type === 'respiration') return 'RESPIRATION_RATE';

  if (type === 'spo2') return 'SPO2';

  if (type === 'temp') return 'TEMPERATURE';

  return 'weight';
};

export const vitalTypeVariable = (type: string): string => {
  if (type.toUpperCase() === 'HEART_RATE') return 'heartRate';

  if (type.toUpperCase() === 'PAIN') return 'pain';

  if (type.toUpperCase() === 'BLOOD_GLUCOSE') return 'bloodGlucose';

  if (type.toUpperCase() === 'BP_DIASTOLIC') return 'diastolic';

  if (type.toUpperCase() === 'BP_SYSTOLIC') return 'systolic';

  if (type.toUpperCase() === 'RESPIRATION_RATE') return 'respirationRate';

  if (type.toUpperCase() === 'SPO2') return 'spo2';

  if (type.toUpperCase() === 'TEMPERATURE') return 'temperature';

  return 'weight';
};

const getDashboardData =
  (showLoading?: boolean, callback?: (status: boolean) => void): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());

    if (showLoading) {
      dispatch(setLoadingDashboard(true));
    }

    dispatch(
      httpAction<unknown, unknown, MeasurementItem[]>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.DASHBOARD_URL}/${clientId}`
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            dispatch(setDashboardData(res.data));

            if (callback) {
              callback(false);
            }
          }

          if (res.httpCode === 404 && callback) {
            callback(true);
          }

          if (showLoading) {
            dispatch(setLoadingDashboard(false));
          }
        }
      )
    );
  };

const getGraphData =
  (hideLoading?: boolean, callback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());

    if (!hideLoading) {
      dispatch(setLoadingGraph(true));
    }

    const initialVitalTypes = getInitialSelectedVitalType(getState());
    const isMultipleVital = initialVitalTypes.length === 2;

    dispatch(setIsMultiVitals(isMultipleVital));

    let vitalType = getGraphURLBasedOnSelectedVital(getState());

    dispatch(
      httpAction<
        unknown,
        unknown,
        {
          type: VitalType;
          measurement1: VitalGraphItem[];
          measurement2?: VitalGraphItem[];
        }[]
      >(
        {
          method: 'GET',
          url: `${API_ENDPOINT.GRAPH_URL}/${clientId}?${vitalType}`,
          params: {
            fromDate: dayjs().subtract(2, 'years').startOf('year').toISOString(),
            toDate: dayjs().endOf('year').toISOString()
          }
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const isBp = initialVitalTypes.includes('BP_SYSTOLIC');

            if (!isMultipleVital) {
              const formatedData = res.data[0].measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime);
              let secondFormatedData: any[] = [];

              if (Array.isArray(res.data[0].measurement2) && res.data[0].measurement2.length > 0) {
                secondFormatedData = res.data[0].measurement2.sort((a, b) => a.recordedDateTime - b.recordedDateTime);
              }

              dispatch(setVitalGraphView(formatedData));

              const newArray: any[] = [];
              const newSecondArray: any[] = [];

              formatedData.forEach((dataItem) => {
                newArray.push({
                  x: dataItem.recordedDateTime,
                  y: dataItem.value,
                  rawData: {
                    ...dataItem,
                    type: isBp ? 'BP SYSTOLIC' : res.data?.[0].type
                  }
                });
              });

              secondFormatedData.forEach((dataItem) => {
                newSecondArray.push({
                  x: dataItem.recordedDateTime,
                  y: Number(dataItem.value),
                  rawData: {
                    ...dataItem,
                    type: isBp ? 'BP DIASTOLIC' : ''
                  }
                });
              });

              dispatch(setVitalGraphData(newArray));
              dispatch(setSecondVitalGraphData(newSecondArray));
            }

            if (isMultipleVital) {
              if (isBp) {
                const isBpPrimary = initialVitalTypes[0] === 'BP_SYSTOLIC';

                if (isBpPrimary) {
                  const bpData = res.data.find((v) => v.type.toUpperCase() === 'BP_SYSTOLIC');
                  const nonBpData = res.data.find((v) => v.type.toUpperCase() !== 'BP_SYSTOLIC');

                  const formatedData =
                    bpData?.measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];
                  const secondFormatedData =
                    bpData?.measurement2!.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];
                  let thirdFormattedData: any[] = [];

                  if (nonBpData) {
                    thirdFormattedData = nonBpData.measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime);
                  }

                  dispatch(setVitalGraphView(formatedData));

                  const newArray: any[] = [];
                  const newSecondArray: any[] = [];
                  const thirdSecondArray: any[] = [];

                  formatedData.forEach((dataItem) => {
                    newArray.push({
                      x: dataItem.recordedDateTime,
                      y: dataItem.value,
                      rawData: {
                        ...dataItem,
                        type: isBp ? 'BP SYSTOLIC' : bpData?.type
                      }
                    });
                  });

                  secondFormatedData.forEach((dataItem) => {
                    newSecondArray.push({
                      x: dataItem.recordedDateTime,
                      y: Number(dataItem.value),
                      rawData: {
                        ...dataItem,
                        type: isBp ? 'BP DIASTOLIC' : ''
                      }
                    });
                  });

                  thirdFormattedData.forEach((dataItem) => {
                    thirdSecondArray.push({
                      x: dataItem.recordedDateTime,
                      y: Number(dataItem.value),
                      rawData: {
                        ...dataItem,
                        type: nonBpData?.type
                      }
                    });
                  });

                  dispatch(setVitalGraphData(newArray));
                  dispatch(setSecondVitalGraphData(newSecondArray));
                  dispatch(setThirdVitalGraphData(thirdSecondArray));
                }

                if (!isBpPrimary) {
                  const bpData = res.data.find((v) => v.type.toUpperCase() === 'BP_SYSTOLIC');
                  const nonBpData = res.data.find((v) => v.type.toUpperCase() !== 'BP_SYSTOLIC');

                  const formatedData =
                    nonBpData?.measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];
                  const secondFormatedData =
                    bpData?.measurement1!.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];
                  let thirdFormattedData =
                    bpData?.measurement2!.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];

                  dispatch(setVitalGraphView(formatedData));

                  const newArray: any[] = [];
                  const newSecondArray: any[] = [];
                  const thirdSecondArray: any[] = [];

                  formatedData.forEach((dataItem) => {
                    newArray.push({
                      x: dataItem.recordedDateTime,
                      y: dataItem.value,
                      rawData: {
                        ...dataItem,
                        type: nonBpData?.type
                      }
                    });
                  });

                  secondFormatedData.forEach((dataItem) => {
                    newSecondArray.push({
                      x: dataItem.recordedDateTime,
                      y: Number(dataItem.value),
                      rawData: {
                        ...dataItem,
                        type: 'BP SYSTOLIC'
                      }
                    });
                  });

                  thirdFormattedData.forEach((dataItem) => {
                    thirdSecondArray.push({
                      x: dataItem.recordedDateTime,
                      y: Number(dataItem.value),
                      rawData: {
                        ...dataItem,
                        type: 'BP DIASTOLIC'
                      }
                    });
                  });

                  dispatch(setVitalGraphData(newArray));
                  dispatch(setSecondVitalGraphData(newSecondArray));
                  dispatch(setThirdVitalGraphData(thirdSecondArray));
                }
              }

              if (!isBp) {
                const primaryType = initialVitalTypes[0];
                const secondaryType = initialVitalTypes[1];
                const firstData = res.data?.find((v) => v.type === primaryType);
                const secondData = res.data?.find((v) => v.type === secondaryType);

                const formatedData =
                  firstData?.measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];

                const secondFormattedData =
                  secondData?.measurement1.sort((a, b) => a.recordedDateTime - b.recordedDateTime) || [];

                dispatch(setVitalGraphView(formatedData));

                const newArray: any[] = [];
                const secondArray: any[] = [];

                formatedData.forEach((dataItem) => {
                  newArray.push({
                    x: dataItem.recordedDateTime,
                    y: dataItem.value,
                    rawData: {
                      ...dataItem,
                      type: firstData?.type
                    }
                  });
                });

                secondFormattedData.forEach((dataItem) => {
                  secondArray.push({
                    x: dataItem.recordedDateTime,
                    y: Number(dataItem.value),
                    rawData: {
                      ...dataItem,
                      type: secondData?.type
                    }
                  });
                });

                dispatch(setVitalGraphData(newArray));
                dispatch(setSecondVitalGraphData(secondArray));
                dispatch(setThirdVitalGraphData([]));
              }
            }
          }

          setTimeout(() => {
            if (!hideLoading) {
              dispatch(setLoadingGraph(false));
            }
          }, 1000);

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getMultiVitalListViewData =
  (
    primaryVital: string,
    secondaryVital: string,
    params: ParamsFetchVitalRow,
    disabledLoading?: boolean,
    callback?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());

    if (!disabledLoading) {
      dispatch(setLoadingListView(true));
    }

    dispatch(
      httpAction<unknown, unknown, PaginationData<MultiVitalsRowItem>>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.VITAL_URL}/${primaryVital.toUpperCase()}/${secondaryVital.toUpperCase()}/${clientId}`,
          params
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const vitalRowLost: VitalRowItem[] = res.data.content.map((row) => ({
              value: row.primaryValue,
              secondaryValue: row.secondaryValue,
              unit: row.primaryUnit,
              recordedDateTime: row.recordedDateTime,
              remark: row.primaryRemark,
              isLock: row.isLock,
              id: row.recordId,
              resourceId: row.resourceId,
              resourceName: row.resourceName,
              secondaryRemark: row.secondaryRemark
            }));

            dispatch(setVitalListView(vitalRowLost));
            dispatch(setTotalElements(res.data.totalElements));

            if (callback) {
              callback();
            }

            if (!disabledLoading) {
              setTimeout(() => {
                dispatch(setLoadingListView(false));
              }, 1000);
            }
          }
        }
      )
    );
  };

const getPrimaryListViewData =
  (
    selectedVitalTypes: string,
    params: ParamsFetchVitalRow,
    disabledLoading?: boolean,
    callback?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());

    if (!disabledLoading) {
      dispatch(setLoadingListView(true));
    }

    dispatch(
      httpAction<unknown, unknown, PaginationData<VitalRowItem>>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.VITAL_URL}/${selectedVitalTypes.toUpperCase()}/${clientId}`,
          params
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            dispatch(setVitalListView(res.data.content));
            dispatch(setTotalElements(res.data.totalElements));

            if (callback) {
              callback();
            }

            if (!disabledLoading) {
              setTimeout(() => {
                dispatch(setLoadingListView(false));
              }, 1000);
            }
          }
        }
      )
    );
  };

const getListViewData =
  (
    selectedVitalTypes: string[],
    params: ParamsFetchVitalRow,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    isMultiVitalsView: boolean = false,
    disabledLoading?: boolean,
    callback?: () => void
  ): AppThunk =>
  async (dispatch) => {
    if (selectedVitalTypes.length === 2) {
      dispatch(
        getMultiVitalListViewData(selectedVitalTypes[0], selectedVitalTypes[1], params, disabledLoading, callback)
      );

      return;
    }

    dispatch(getPrimaryListViewData(selectedVitalTypes[0], params, disabledLoading, callback));
  };

const getResourceList =
  (callback?: (data: Resource[]) => void): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());

    dispatch(
      httpAction<unknown, unknown, Resource[]>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.RESOURCE_URL}/${clientId}`
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            if (callback) {
              callback(res.data);
            }
          }
        }
      )
    );
  };

const sendDeleteRequest =
  (id: number, type: string[], params: ParamsFetchVitalRow): AppThunk =>
  async () => {
    const dataToSend = {
      recordId: id,
      type,
      params
    };

    window.parent.postMessage(
      {
        type: PostMessageType.SHOW_CONFIRMATION_DELETE_POPUP,
        data: {
          ...dataToSend
        }
      },
      '*'
    );
  };

const openEditRemarkPopup =
  (row: VitalRowItem, selectedVitals: string[]): AppThunk =>
  async () => {
    const dataToSend = {
      record: row,
      selectedVitals
    };

    window.parent.postMessage(
      {
        type: PostMessageType.OPEN_EDIT_REMARK_POPUP,
        data: {
          ...dataToSend
        }
      },
      '*'
    );
  };

const deleteRecord =
  (id: number, type: string[]): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());
    const listView = selectVitalListView(getState());

    const cloneListView = listView.filter((v) => v.id !== id);

    const params: { primaryType: string; primaryRecordId: string; secondaryType?: string; secondaryRecordId?: string } =
      {
        primaryType: type[0],
        primaryRecordId: `${id}`
      };

    if (type.length > 1) {
      params.secondaryType = type[1];
      params.secondaryRecordId = `${id}`;
    }

    dispatch(
      httpAction<unknown, unknown, VitalRowItem[]>(
        {
          method: 'DELETE',
          url: `${API_ENDPOINT.VITAL_URL}/${clientId}`,
          params
        },
        async (res) => {
          if (res.httpCode === 200) {
            dispatch(setVitalListView(cloneListView));
            dispatch(sendMessageToParent<{ status: string }>({ status: 'success' }, PostMessageType.SHOW_NOTIFICATION));
          }
        }
      )
    );
  };

const updateRemark =
  (
    id: number,
    type: string[],
    newRemark: string,
    secondaryRemark: string | undefined,
    callback: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    const clientId = selectClientId(getState());
    const listView = selectVitalListView(getState());
    const updatedDataForTable: { remark: string; secondaryRemark?: string } = { remark: newRemark };

    if (type.length > 1) {
      updatedDataForTable.secondaryRemark = secondaryRemark;
    }

    const cloneListView = listView.map((v) => {
      if (v.id === id) {
        return {
          ...v,
          ...updatedDataForTable
        };
      }

      return {
        ...v
      };
    });

    const params: { primaryType: string; secondaryType?: string } = {
      primaryType: type[0]
    };

    const dataObj: UpdateVitalObject = {
      primaryRemark: newRemark
    };

    if (type.length > 1) {
      params.secondaryType = type[1];
      dataObj.secondaryRemark = secondaryRemark;
    }

    dispatch(
      httpAction<{ primaryRemark: string }, unknown, VitalRowItem[]>(
        {
          method: 'PUT',
          url: `${API_ENDPOINT.VITAL_URL}/${clientId}/${id}`,
          data: dataObj,
          params
        },
        async (res) => {
          window.parent.postMessage(
            {
              type: PostMessageType.UPDATE_VITALS_STATUS,
              data: { success: res.httpCode === 200, msg: res }
            },
            '*'
          );

          if (res.httpCode === 200) {
            dispatch(setVitalListView(cloneListView));
            callback();
          }
        }
      )
    );
  };

const openAllVitalsOpenPopup =
  (
    clientId: string,
    orderBy: string = '',
    orderDirection: string = '',
    popupType: string = 'ALL_FIELDS',
    fieldsList: string[] = []
  ): AppThunk =>
  () => {
    window.parent.postMessage(
      {
        type: PostMessageType.SHOW_ALL_VITALS_INSERT_POPUP,
        data: {
          clientId: clientId,
          popupType: popupType,
          fieldsList: fieldsList,
          orderBy,
          orderDirection
        }
      },
      '*'
    );
  };

const openSelectSecondaryVitalPopup =
  (clientId: string, primaryVital: string, initiallySelectedVitalTypes: string[]): AppThunk =>
  () => {
    window.parent.postMessage(
      {
        type: PostMessageType.SHOW_SELECT_SECONDARY_VITAL_POPUP,
        data: { clientId, primaryVital, initiallySelectedVitalTypes }
      },
      '*'
    );
  };

const openDownloadVitalsPopUp =
  (clientId: string): AppThunk =>
  () => {
    window.parent.postMessage(
      {
        type: PostMessageType.SHOW_DOWNLOAD_REPORT_POPUP,
        data: { clientId: clientId }
      },
      '*'
    );
  };

const loadReferenceRange =
  (callback?: () => void): AppThunk =>
  (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, { key: string; value: string }[]>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.VITAL_URL}/config/ref-ranges`,
          params: { module: 'GENERAL', sort: 'key,asc', page: 0, size: 100 }
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const referenceRangesData: Record<string, any> = {};

            res.data?.forEach((v) => {
              Object.assign(referenceRangesData, { [`${v.key}`]: v.value });
            });

            dispatch(setReferenceRanges(referenceRangesData));

            if (callback) {
              setTimeout(() => {
                callback();
              }, 500);
            }
          }
        }
      )
    );
  };

const prepareStr = (str: string) => (str === '' ? null : Number(str));

const addVitalsRecord =
  (data: AllVitalsDataFromIframe): AppThunk =>
  (dispatch, getState) => {
    const vitalsData = data.vitalsData;
    let intervalId: NodeJS.Timer;
    let time = 0;

    dispatch(
      httpAction(
        {
          method: 'POST',
          url: `${API_ENDPOINT.VITAL_URL}`,
          data: {
            dateTime: new Date(vitalsData.dateTime).toISOString(),
            heartRate: prepareStr(vitalsData.heartRate),
            bloodGlucose: prepareStr(vitalsData.bloodGlucose),
            temperature: prepareStr(vitalsData.temp),
            bpDiastolic: prepareStr(vitalsData.diastolic),
            bpSystolic: prepareStr(vitalsData.systolic),
            spo2: prepareStr(vitalsData.spo2),
            weight: prepareStr(vitalsData.weight),
            pain: prepareStr(vitalsData.pain),
            respirationRate: prepareStr(vitalsData.respiration),
            heartRateRemark: vitalsData.heartRateRemark,
            bloodGlucoseRemark: vitalsData.bloodGlucoseRemark,
            temperatureRemark: vitalsData.temperatureRemark,
            bpRemark: vitalsData.bpRemark,
            spo2Remark: vitalsData.spo2Remark,
            weightRemark: vitalsData.weightRemark,
            painRemark: vitalsData.painRemark,
            respirationRateRemark: vitalsData.respirationRateRemark,
            clientId: data.clientId
          }
        },
        async (res) => {
          window.parent.postMessage(
            {
              type: PostMessageType.SAVE_ALL_VITALS_STATUS,
              data: { success: res.httpCode === 200, msg: res }
            },
            '*'
          );

          if (res.httpCode === 200) {
            if (intervalId) {
              clearInterval(intervalId);
            }

            intervalId = setInterval(() => {
              if (time === 5) {
                clearInterval(intervalId);
                time = 0;

                return;
              }

              time = time + 1;
              dispatch(getDashboardData());

              const isMultiVitalsView = getIsMultiVitals(getState());

              if (data.type === VitalsPopupTypeEnum.CUSTOM_FIELDS && data.fieldName) {
                dispatch(setCurrentPageSize(10));
                dispatch(setCurrentPage(0));
                dispatch(setVitalListViewStatus(VitalListViewStatusType.RECENTLY_UPDATED));
                const updatedFields = data.isMultiVitalDialog
                  ? [data.fieldName!, data.secondaryVitalName!]
                  : [data.fieldName!];

                const sorting = `${data.orderBy},${data.orderDirection}`;

                dispatch(
                  getListViewData(
                    updatedFields,
                    {
                      page: 0,
                      size: 10,
                      sort: sorting,
                      employeeCode: '',
                      fromDate: null,
                      toDate: null
                    },
                    isMultiVitalsView,
                    true
                  )
                );
                dispatch(getGraphData(true));
              }
            }, 2000);
          }
        }
      )
    );
  };

const iconData = {
  HEART_RATE: {
    png: HeartRatePng
  },
  BLOOD_GLUCOSE: {
    png: BloodGlucosePng
  },
  BP_SYSTOLIC: {
    png: BPIconPng
  },
  TEMPERATURE: {
    png: TempPng
  },
  SPO2: {
    png: SP02Png
  },
  WEIGHT: {
    png: WeightPng
  },
  PAIN: {
    png: PainPng
  },
  RESPIRATION_RATE: {
    png: RespirationRatePng
  }
};

const generatePDFForVitalChartForMultiple = (
  doc: jsPDF,
  type: VitalType,
  arrayData: any[],
  dateRange: string,
  clientInformation: any,
  vitalText: any,
  vitalText1: string,
  isBpPrimary: boolean,
  hasBP: boolean,
  noData?: boolean
) => {
  const iconForThisType = iconData[type].png;
  const pageWidth = doc.internal.pageSize.width; //Optional

  const UnitObj: any = {
    HEART_RATE: 'bpm',
    BLOOD_GLUCOSE: 'mmoL',
    BP_SYSTOLIC: 'mmHg',
    TEMPERATURE: '°C',
    SPO2: '%',
    WEIGHT: 'Kg',
    PAIN: 'Scale',
    RESPIRATION_RATE: 'bpm'
  };

  if (arrayData.length === 0) {
    doc.addImage(Logo, 'PNG', 10, 5, 70, 30, 'LOGO', 'FAST');

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'normal');
    doc.text('Name:', 15, 35);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(clientInformation.clientName, 45, 35);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'normal');
    doc.text('Date of Birth:', 15, 40);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(dayjs(new Date(clientInformation.clientDOB)).format('DD/MM/YYYY'), 45, 40);

    doc.setFontSize(10); //Optional
    doc.setFont('Helvetica', '', 'normal');
    doc.setTextColor('#4B4B4C');
    doc.text('Sex:', 15, 45);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(clientInformation.clientGender, 45, 45);

    let lineYCoordinate = 52;

    if (!noData) {
      doc.addImage(iconForThisType, 'PNG', 15, 52, 13, 13, `ICON_${type}`, 'FAST');

      doc.setFontSize(12); //Optional
      doc.setTextColor(37, 139, 150);
      doc.setFont('Helvetica', '', 'bold');
      doc.text(vitalText1 ? `${vitalText} - ${vitalText1}` : `${vitalText}`, 30, 57);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text(dateRange, 30, 62);

      lineYCoordinate = lineYCoordinate + 17;
    }

    doc.setLineWidth(0.5);
    doc.setDrawColor('#4B4B4C');
    doc.line(15, lineYCoordinate, pageWidth - 15, lineYCoordinate);
    doc.setFont('Helvetica', '', 'normal');

    return;
  }

  const sortedData = arrayData
    .map((v) => ({ ...v }))
    .sort((a, b) => b.rawData.recordedDateTime - a.rawData.recordedDateTime);

  let firstType = '';
  let secondType = '';
  let thirdType = '';

  if (isBpPrimary && hasBP) {
    thirdType = sortedData[0].thirdType;
  }

  if (!isBpPrimary && hasBP) {
    firstType = sortedData[0].firstType;
  }

  if (!isBpPrimary && !hasBP) {
    firstType = sortedData[0].firstType;
    secondType = sortedData[0].secondType;
  }

  const headers =
    arrayData.length !== 0
      ? [
          hasBP
            ? isBpPrimary
              ? [
                  'Resource',
                  'Date and Time',
                  'BP - Systolic (mmHg)',
                  'BP - Diastolic (mmHg)',
                  `${VitalsFieldNameEnum[thirdType as VitalType]} (${UnitObj[thirdType]})`,
                  'Remark'
                ]
              : [
                  'Resource',
                  'Date and Time',
                  `${VitalsFieldNameEnum[firstType as VitalType]} (${UnitObj[firstType]})`,
                  'BP - Systolic (mmHg)',
                  'BP - Diastolic (mmHg)',
                  'Remark'
                ]
            : [
                'Resource',
                'Date and Time',
                `${VitalsFieldNameEnum[firstType as VitalType]} (${UnitObj[firstType]})`,
                `${VitalsFieldNameEnum[secondType as VitalType]} (${UnitObj[secondType]})`,
                'Remark'
              ]
        ]
      : [[]];

  const data = sortedData.map((elt) => {
    let firstValue = '';
    let secondValue = '';
    let thirdValue = '';
    let remark = '';

    if (isBpPrimary && hasBP) {
      firstValue = elt.firstValue || '-';
      secondValue = elt.secondValue || '-';
      thirdValue = elt.thirdValue || '-';

      // let valueForBp = '';

      // if (elt.firstValue) {
      //   valueForBp += `${elt.firstValue}/${elt.secondValue}`;
      // } else {
      //   valueForBp = '-';
      // }

      // firstValue = elt.firstValue;
      // secondValue = elt.secondValue;
      // thirdValue = elt.thirdValue;

      // value += `${VitalsShortNameEnum.BP_SYSTOLIC} (mmHg): ${valueForBp}`;
      // value += `\n${VitalsShortNameEnum[elt.thirdType as VitalType]} (${UnitObj[elt.thirdType]}): ${
      //   elt.thirdValue || '-'
      // }`;

      remark += `${VitalsShortNameEnum.BP_SYSTOLIC}: ${
        elt.firstRemark ? elt.firstRemark : elt.secondRemark ? elt.secondRemark : '-'
      }`;
      remark += `\n${VitalsShortNameEnum[elt.thirdType as VitalType]}: ${elt.thirdRemark || '-'}`;
    }

    if (!isBpPrimary && hasBP) {
      firstValue = elt.firstValue || '-';
      secondValue = elt.secondValue || '-';
      thirdValue = elt.thirdValue || '-';

      // let valueForBp = '';

      // if (elt.secondValue) {
      //   valueForBp += `${elt.secondValue}/${elt.thirdValue}`;
      // } else {
      //   valueForBp = '-';
      // }

      // value += `${VitalsShortNameEnum[elt.firstType as VitalType]} (${UnitObj[elt.firstType]}): ${
      //   elt.firstValue || '-'
      // }`;
      // value += `\n${VitalsShortNameEnum.BP_SYSTOLIC} (mmHg): ${valueForBp}`;

      remark += `${VitalsShortNameEnum[elt.firstType as VitalType]}: ${elt.firstRemark || '-'}`;
      remark += `\n${VitalsShortNameEnum.BP_SYSTOLIC}: ${
        elt.secondRemark ? elt.secondRemark : elt.thirdRemark ? elt.thirdRemark : '-'
      }`;
    }

    if (!isBpPrimary && !hasBP) {
      firstValue = elt.firstValue || '-';
      secondValue = elt.secondValue || '-';

      // value += `${VitalsShortNameEnum[elt.firstType as VitalType]} (${UnitObj[elt.firstType]}): ${
      //   elt.firstValue || '-'
      // }`;
      // value += `\n${VitalsShortNameEnum[elt.secondType as VitalType]} (${UnitObj[elt.secondType]}): ${
      //   elt.secondValue || '-'
      // }`;

      remark += `${VitalsShortNameEnum[elt.firstType as VitalType]}: ${elt.firstRemark || '-'}`;
      remark += `\n${VitalsShortNameEnum[elt.secondType as VitalType]}: ${elt.secondRemark || '-'}`;
    }

    if (hasBP) {
      return [
        elt.rawData.resourceName,
        dayjs(elt.rawData.recordedDateTime).format('DD/MM/YYYY HH:mm'),
        firstValue,
        secondValue,
        thirdValue,
        remark
      ];
    }

    return [
      elt.rawData.resourceName,
      dayjs(elt.rawData.recordedDateTime).format('DD/MM/YYYY HH:mm'),
      firstValue,
      secondValue,
      remark
    ];
  });

  let content: UserOptions = {
    startY: 165,
    head: headers,
    body: data,
    margin: {
      top: 70,
      bottom: 30
    },
    theme: 'plain',
    columnStyles: hasBP
      ? {
          0: { cellWidth: 40 },
          1: { cellWidth: 30 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 50 }
        }
      : {
          0: { cellWidth: 50 },
          1: { cellWidth: 40 },
          2: { cellWidth: 30 },
          4: { cellWidth: 30 },
          5: { cellWidth: 60 }
        },
    headStyles: {
      fontSize: 8
    },
    bodyStyles: {
      fontSize: 8
    },
    // columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
    showHead: 'everyPage',
    willDrawCell: function (cellData) {
      // add borders around the head cells
      if (cellData.row.section === 'body') {
        doc.setDrawColor('#959595');
        doc.setLineWidth(0.1);

        // draw bottom border
        doc.line(
          cellData.cell.x,
          cellData.cell.y + cellData.cell.height,
          cellData.cell.x + cellData.cell.width,
          cellData.cell.y + cellData.cell.height
        );
        // draw top border
        doc.line(cellData.cell.x + cellData.cell.width, cellData.cell.y, cellData.cell.x, cellData.cell.y);
      }
    },
    didDrawPage: function () {
      doc.addImage(Logo, 'PNG', 10, 5, 70, 30, 'LOGO', 'FAST');

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text('Name:', 15, 35);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(clientInformation.clientName, 45, 35);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text('Date of Birth:', 15, 40);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(dayjs(new Date(clientInformation.clientDOB)).format('DD/MM/YYYY'), 45, 40);

      doc.setFontSize(10); //Optional
      doc.setFont('Helvetica', '', 'normal');
      doc.setTextColor('#4B4B4C');
      doc.text('Sex:', 15, 45);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(clientInformation.clientGender, 45, 45);

      let lineYCoordinate = 52;

      if (!noData) {
        doc.addImage(iconForThisType, 'PNG', 15, 52, 13, 13, `ICON_${type}`, 'FAST');

        doc.setFontSize(12); //Optional
        doc.setTextColor(37, 139, 150);
        doc.setFont('Helvetica', '', 'bold');
        doc.text(vitalText1 ? `${vitalText} - ${vitalText1}` : `${vitalText}`, 30, 57);

        doc.setFontSize(10); //Optional
        doc.setTextColor('#4B4B4C');
        doc.setFont('Helvetica', '', 'normal');
        doc.text(dateRange, 30, 62);

        lineYCoordinate = lineYCoordinate + 17;
      }

      doc.setLineWidth(0.5);
      doc.setDrawColor('#4B4B4C');
      doc.line(15, lineYCoordinate, pageWidth - 15, lineYCoordinate);
      doc.setFont('Helvetica', '', 'normal');
    }
  };

  autoTable(doc, content);
};

const generatePDFForVitalChart = (
  doc: jsPDF,
  type: VitalType,
  arrayData: any[],
  dateRange: string,
  clientInformation: any,
  vitalText: any,
  noData?: boolean,
  vitalText1?: string,
  isMultipleVital?: boolean,
  selectedVitals?: string[]
) => {
  const iconForThisType = iconData[type].png;
  const pageWidth = doc.internal.pageSize.width; //Optional
  const vitalNameText: Record<string, string> = {
    HEART_RATE: 'Heart Rate',
    BLOOD_GLUCOSE: 'Blood Glucose',
    BP_SYSTOLIC: 'BP - Systolic / Diastolic',
    TEMPERATURE: 'Temperature',
    SPO2: 'SpO2 Saturation',
    WEIGHT: 'Weight',
    PAIN: 'Pain',
    RESPIRATION_RATE: 'Respiration Rate'
  };

  if (arrayData.length === 0) {
    doc.addImage(Logo, 'PNG', 10, 5, 70, 30, 'LOGO', 'FAST');

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'normal');
    doc.text('Name:', 15, 35);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(clientInformation.clientName, 45, 35);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'normal');
    doc.text('Date of Birth:', 15, 40);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(dayjs(new Date(clientInformation.clientDOB)).format('DD/MM/YYYY'), 45, 40);

    doc.setFontSize(10); //Optional
    doc.setFont('Helvetica', '', 'normal');
    doc.setTextColor('#4B4B4C');
    doc.text('Sex:', 15, 45);

    doc.setFontSize(10); //Optional
    doc.setTextColor('#4B4B4C');
    doc.setFont('Helvetica', '', 'bold');
    doc.text(clientInformation.clientGender, 45, 45);

    let lineYCoordinate = 52;

    if (!noData) {
      doc.addImage(iconForThisType, 'PNG', 15, 52, 13, 13, `ICON_${type}`, 'FAST');

      doc.setFontSize(12); //Optional
      doc.setTextColor(37, 139, 150);
      doc.setFont('Helvetica', '', 'bold');

      if (isMultipleVital && selectedVitals && selectedVitals.length === 2) {
        doc.text(`${vitalNameText[selectedVitals[0]]} - ${vitalNameText[selectedVitals[1]]}`, 30, 57);
      }

      if (isMultipleVital && selectedVitals && selectedVitals.length === 1) {
        doc.text(`${vitalNameText[selectedVitals[0]]}`, 30, 57);
      }

      if (!isMultipleVital) {
        doc.text(vitalText, 30, 57);
      }

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text(dateRange, 30, 62);

      lineYCoordinate = lineYCoordinate + 17;
    }

    doc.setLineWidth(0.5);
    doc.setDrawColor('#4B4B4C');
    doc.line(15, lineYCoordinate, pageWidth - 15, lineYCoordinate);
    doc.setFont('Helvetica', '', 'normal');

    return;
  }

  const headers = arrayData.length !== 0 ? [['Resource', 'Date and Time', 'Record', 'Remark']] : [[]];

  const data = arrayData
    .map((v) => ({ ...v }))
    .sort((a, b) => b.recordedDateTime - a.recordedDateTime)
    .map((elt) => [elt.resourceName, dayjs(elt.recordedDateTime).format('DD/MM/YYYY HH:mm'), elt.value, elt.remark]);

  let content: UserOptions = {
    startY: 165,
    head: headers,
    body: data,
    margin: {
      top: 70,
      bottom: 30
    },
    theme: 'plain',
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 40 },
      2: { cellWidth: 30 },
      3: { cellWidth: 60 }
    },
    // columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
    showHead: 'everyPage',
    willDrawCell: function (cellData) {
      // add borders around the head cells
      if (cellData.row.section === 'body') {
        doc.setDrawColor('#959595');
        doc.setLineWidth(0.1);

        // draw bottom border
        doc.line(
          cellData.cell.x,
          cellData.cell.y + cellData.cell.height,
          cellData.cell.x + cellData.cell.width,
          cellData.cell.y + cellData.cell.height
        );
        // draw top border
        doc.line(cellData.cell.x + cellData.cell.width, cellData.cell.y, cellData.cell.x, cellData.cell.y);
      }
    },
    didDrawPage: function () {
      doc.addImage(Logo, 'PNG', 10, 5, 70, 30, 'LOGO', 'FAST');

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text('Name:', 15, 35);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(clientInformation.clientName, 45, 35);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'normal');
      doc.text('Date of Birth:', 15, 40);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(dayjs(new Date(clientInformation.clientDOB)).format('DD/MM/YYYY'), 45, 40);

      doc.setFontSize(10); //Optional
      doc.setFont('Helvetica', '', 'normal');
      doc.setTextColor('#4B4B4C');
      doc.text('Sex:', 15, 45);

      doc.setFontSize(10); //Optional
      doc.setTextColor('#4B4B4C');
      doc.setFont('Helvetica', '', 'bold');
      doc.text(clientInformation.clientGender, 45, 45);

      let lineYCoordinate = 52;

      if (!noData) {
        doc.addImage(iconForThisType, 'PNG', 15, 52, 13, 13, `ICON_${type}`, 'FAST');

        doc.setFontSize(12); //Optional
        doc.setTextColor(37, 139, 150);
        doc.setFont('Helvetica', '', 'bold');
        // doc.text(vitalText1 ? `${vitalText} - ${vitalText1}` : `${vitalText}`, 30, 57);

        if (isMultipleVital && selectedVitals && selectedVitals.length === 2) {
          doc.text(`${vitalNameText[selectedVitals[0]]} - ${vitalNameText[selectedVitals[1]]}`, 30, 57);
        }

        if (isMultipleVital && selectedVitals && selectedVitals.length === 1) {
          doc.text(`${vitalNameText[selectedVitals[0]]}`, 30, 57);
        }

        if (!isMultipleVital) {
          doc.text(vitalText, 30, 57);
        }

        doc.setFontSize(10); //Optional
        doc.setTextColor('#4B4B4C');
        doc.setFont('Helvetica', '', 'normal');
        doc.text(dateRange, 30, 62);

        lineYCoordinate = lineYCoordinate + 17;
      }

      doc.setLineWidth(0.5);
      doc.setDrawColor('#4B4B4C');
      doc.line(15, lineYCoordinate, pageWidth - 15, lineYCoordinate);
      doc.setFont('Helvetica', '', 'normal');
    }
  };

  autoTable(doc, content);
};

const handleReportDownloadingDone =
  (success: boolean): AppThunk =>
  () => {
    window.parent.postMessage(
      {
        type: PostMessageType.DOWNLOADING_COMPLETED,
        data: { success }
      },
      '*'
    );
  };

const fetchDataForReporting =
  (data: ReportingIframe, fromDetail?: boolean, secondAction?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsDownloadInprogress(true));

    const clientId = selectClientId(getState());
    const vitalText = selectVitalText(getState());
    const vitalUnit = selectVitalUnitWithType(getState());
    const clientInformation = selectClientInformation(getState());
    const initialVitalTypes = getInitialSelectedVitalType(getState());
    const selectedVitalType = getSelectedVitalType(getState());

    let typeParams: any[] = [];
    let viewType: ViewType;

    const period = data.period;
    let endDate = dayjs().toISOString();
    let startDate = dayjs().toISOString();

    const generateStartDateAndEndDate = generateStartDateAndEndDateByViewType(
      period as ViewTypeFromB3,
      dayjs(),
      dayjs()
    );

    startDate = generateStartDateAndEndDate.startDate.toISOString();
    endDate = generateStartDateAndEndDate.endDate.toISOString();

    viewType = generateStartDateAndEndDate.viewTye;

    if (period === ViewTypeFromB3.Day) {
      endDate = dayjs(endDate).add(1, 'day').startOf('date').toISOString();
    }

    if (period === ViewTypeFromB3.Week) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Month) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.ThreeMonth) {
      endDate = dayjs(endDate).add(1, 'day').startOf('date').toISOString();
    }

    if (period === ViewTypeFromB3.SixMonth) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Year) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Custom) {
      const isSameDay = dayjs(data.startDateTime).isSame(data.endDateTime, 'date');

      if (isSameDay) {
        startDate = dayjs(data.startDateTime).startOf('date').toISOString();
        endDate = dayjs(data.endDateTime).endOf('date').toISOString();
        viewType = ViewType.Day;
      } else {
        startDate = dayjs(data.startDateTime).startOf('date').toISOString();
        endDate = dayjs(data.endDateTime).endOf('date').add(1, 'minute').toISOString();

        viewType = generateViewTypeByDayDiffForPDF(dayjs(startDate), dayjs(endDate));
      }
    }

    const labels = generateLabelsByTime(viewType, dayjs(startDate), dayjs(endDate));

    if (period !== ViewTypeFromB3.Custom) {
      startDate = generateStartDateAndEndDate.startDate.toISOString();
      endDate = generateStartDateAndEndDate.endDate.toISOString();
    }

    const dataType: any = {
      bloodGlucose: 'BLOOD_GLUCOSE',
      bp: 'BP_SYSTOLIC',
      heartRate: 'HEART_RATE',
      pain: 'PAIN',
      respiration: 'RESPIRATION_RATE',
      spo2: 'SPO2',
      temp: 'TEMPERATURE',
      weight: 'WEIGHT'
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const iterator in data) {
      if (dataType[iterator] && dataType[iterator] !== 'BP_SYSTOLIC' && (data as any)[iterator]) {
        typeParams.push(dataType[iterator]);
      }

      if (dataType[iterator] && dataType[iterator] === 'BP_SYSTOLIC' && (data as any)[iterator]) {
        typeParams.push('BP_SYSTOLIC');
        typeParams.push('BP_DIASTOLIC');
      }
    }

    const baseLineUri = new URI(`${API_ENDPOINT.BASELINE_URL}/${clientId}`);

    baseLineUri.setSearch({ vitalType: typeParams });

    // const baseLineQuery = baseLineUri.query();

    // dispatch(
    //   httpAction<unknown, unknown, { id: string; value: number; type: VitalType }[]>(
    //     {
    //       method: 'GET',
    //       url: `${API_ENDPOINT.BASELINE_URL}/${clientId}?${baseLineQuery}`
    //     },
    //     async (baseLineRes) => {
    //       const baseLineValue: Record<string, { id: string; value: number }> = {};

    //       if (baseLineRes.data && baseLineRes.httpCode === 200) {
    //         baseLineRes.data.forEach((v) => {
    //           if (v.type) {
    //             baseLineValue[`${v.type}`] = {
    //               id: v.id,
    //               value: v.value
    //             };
    //           }
    //         });
    //       }

    //     }
    //   )
    // );

    typeParams = typeParams.filter((v) => v !== 'BP_DIASTOLIC');

    const uri = new URI(`${API_ENDPOINT.REPORT_URL}/${clientId}`);

    uri.setSearch({ type: typeParams, fromDate: startDate, toDate: endDate });

    const query = uri.query();

    dispatch(
      httpAction<unknown, unknown, Pick<VitalReportingItem, 'data' | 'vital'>[]>(
        {
          method: 'GET',
          url: `${API_ENDPOINT.REPORT_URL}/${clientId}?${query}`
        },
        async (res) => {
          if (res.data && res.httpCode === 200) {
            const result: VitalReportingItem[] = [];

            const isAllEmpty = res.data.filter((v) => v.data.length === 0);

            const vitalNameText: Record<string, string> = {
              HEART_RATE: 'Heart Rate',
              BLOOD_GLUCOSE: 'Blood Glucose',
              BP_SYSTOLIC: 'BP - Systolic / Diastolic',
              TEMPERATURE: 'Temperature',
              SPO2: 'SpO2 Saturation',
              WEIGHT: 'Weight',
              PAIN: 'Pain',
              RESPIRATION_RATE: 'Respiration Rate'
            };
            const vitalNameList = res.data.map((v) => vitalNameText[v.vital]);

            if (!fromDetail && isAllEmpty.length > 0) {
              const types = isAllEmpty.map((v) => vitalNameText[v.vital]);
              const typesTxt = types.join(', ');

              if (res.data.length === 1) {
                dispatch(
                  sendMessageToParent(
                    {
                      msg: `No data is found for ${typesTxt} for the selected time range. Please make a different selection.`
                    },
                    PostMessageType.SHOW_ERROR_MESSAGE_NO_DATA
                  )
                );
              } else {
                dispatch(
                  sendMessageToParent(
                    {
                      msg: `No data is found for ${typesTxt} for the selected time range.`
                    },
                    PostMessageType.SHOW_ERROR_MESSAGE_NO_DATA
                  )
                );
              }

              if (!secondAction) return;
            }

            res.data
              .filter((v) => v.data.length > 0)
              .forEach((v) => {
                let object: any = {};

                const isBP = v.vital === 'BP_SYSTOLIC';

                const cloneData = v.data.map((k) => ({ ...k }));

                object.cloneData = cloneData;
                object.data = v.data; // Data for list view
                object.vital = v.vital;
                object.showSecondGraphData = isBP;
                object.startDate = startDate;
                object.endDate = endDate;
                object.isCustomView = period === ViewTypeFromB3.Custom;

                const newArray: any[] = [];
                const newSecondArray: any[] = [];

                const formatedDataForGraph = v.data.sort((a, b) => a.recordedDateTime - b.recordedDateTime);
                const labelGraph = labels.concat(v.data.map((item) => item.recordedDateTime)).sort((a, b) => a - b);

                labelGraph.forEach((timeStamp) => {
                  let firstValue;
                  let secondValue;

                  const graphValue = formatedDataForGraph.find((item) => item.recordedDateTime === timeStamp);

                  if (graphValue) {
                    if (isBP) {
                      firstValue = (graphValue.value as string).split('/')[0];
                      secondValue = (graphValue.value as string).split('/')[1];
                    } else {
                      firstValue = graphValue.value;
                    }

                    if (firstValue) {
                      newArray.push({
                        x: graphValue.recordedDateTime,
                        y: Number(firstValue),
                        rawData: graphValue
                      });
                    }

                    if (secondValue) {
                      newSecondArray.push({
                        x: graphValue.recordedDateTime,
                        y: Number(secondValue),
                        rawData: graphValue
                      });
                    }
                  } else {
                    newArray.push({
                      x: timeStamp,
                      y: null,
                      rawData: null
                    });
                  }
                });

                object.graphData = newArray;
                object.secondGraphData = newSecondArray;

                if (
                  selectedVitalType &&
                  selectedVitalType.length === 1 &&
                  initialVitalTypes.length === 2 &&
                  initialVitalTypes[0] === selectedVitalType[0]
                ) {
                  object.unit = vitalUnit(initialVitalTypes[0] as VitalType);
                  object.vitalText = vitalText(initialVitalTypes[0] as VitalType);
                  object.vitalText1 = vitalText(initialVitalTypes[1] as VitalType);
                  object.vitalUnit1 = vitalUnit(initialVitalTypes[1] as VitalType);
                }

                if (
                  selectedVitalType &&
                  selectedVitalType.length === 1 &&
                  initialVitalTypes.length === 2 &&
                  initialVitalTypes[1] === selectedVitalType[0]
                ) {
                  object.unit = vitalUnit(initialVitalTypes[0] as VitalType);
                  object.vitalText = vitalText(initialVitalTypes[0] as VitalType);
                  object.vitalText1 = vitalText(initialVitalTypes[1] as VitalType);
                  object.vitalUnit1 = vitalUnit(initialVitalTypes[1] as VitalType);
                }

                if ((initialVitalTypes && initialVitalTypes.length === 0) || !initialVitalTypes || !selectedVitalType) {
                  object.unit = vitalUnit(v.vital as VitalType);
                  object.vitalText = vitalText(v.vital as VitalType);
                }

                object.viewType = viewType;
                // object.baseLineValue = baseLineValue;

                result.push(object);
              });

            dispatch(setVitalGraphReporting(result));

            const dateRange = `From ${dayjs(period === ViewTypeFromB3.Custom ? data.startDateTime : startDate).format(
              'MMMM DD, YYYY'
            )} To ${dayjs(period === ViewTypeFromB3.Custom ? data.endDateTime : endDate).format('MMMM DD, YYYY')}`;

            setTimeout(() => {
              const doc = new jsPDF('portrait');

              result.forEach((v, i) => {
                if (i > 0) {
                  doc.addPage();
                }

                const graphInstance =
                  (initialVitalTypes && initialVitalTypes.length === 0) || !initialVitalTypes
                    ? document.querySelector(`#${v.vital.toUpperCase()}`)
                    : document.querySelector(
                        `#graphItem_VITALS_${initialVitalTypes[0]}_SPACE_VITALS_${
                          initialVitalTypes[1]
                        }_SPACE_SELECTED_${selectedVitalType[0]}_SPACE_SELECTED_${selectedVitalType[1] || ''}`
                      );

                const canvasImg = (graphInstance as any).toDataURL();

                doc.addImage(canvasImg, 'JPEG', 10, 73, 186, 90, undefined, 'FAST');

                generatePDFForVitalChart(
                  doc,
                  v.vital as VitalType,
                  v.cloneData!,
                  dateRange,
                  clientInformation,
                  v.vitalText,
                  false,
                  undefined,
                  initialVitalTypes.length === 2,
                  selectedVitalType
                );
              });

              if (result.length === 0) {
                typeParams.forEach((v, i) => {
                  if (i > 0) {
                    doc.addPage();
                  }

                  generatePDFForVitalChart(
                    doc,
                    v,
                    [],
                    dateRange,
                    clientInformation,
                    vitalText(v as VitalType),
                    false,
                    undefined,
                    initialVitalTypes.length === 2,
                    selectedVitalType
                  );
                });
              }

              const pages = doc.internal.pages.filter((v) => v);

              const pageWidth = doc.internal.pageSize.width; //Optional
              const pageHeight = doc.internal.pageSize.height; //Optional

              doc.setFontSize(10); //Optional

              for (let j = 0; j < pages.length; j++) {
                let horizontalPos = pageWidth / 2; //Can be fixed number
                let verticalPos = pageHeight - 10; //Can be fixed number

                doc.setPage(j + 1);
                doc.text(`${j + 1} of ${pages.length}`, horizontalPos, verticalPos + 2, {
                  align: 'center'
                });
                doc.setFontSize(9);
                doc.setTextColor('#5CBA39');
                doc.text('Services:', horizontalPos - 90, verticalPos - 10);
                doc.setTextColor('#4B4B4C');
                doc.text(
                  'Sernior Day Care | Home Care | Nursing Home | Active Ageing | Rehabilitation and Wellness | Family Medicine | Dental',
                  horizontalPos - 76,
                  verticalPos - 10
                );
                doc.setTextColor('#5CBA39');
                doc.text('Community Support:', horizontalPos - 90, verticalPos - 5);
                doc.setTextColor('#4B4B4C');
                doc.text(
                  'Sernior Activity Centres | Community Care | Henderson Home',
                  horizontalPos - 60,
                  verticalPos - 5
                );
              }

              doc.save(
                `${clientInformation.clientName}_${vitalNameList.join('_')}_${dayjs(new Date()).format(
                  'DD.MM.YYYY'
                )}.pdf`
              );

              dispatch(handleReportDownloadingDone(true));
              dispatch(setIsDownloadInprogress(false));
            }, 2000);
          }
        }
      )
    );
  };

const fetchDataForReportingForMultipleVital =
  (data: ReportingIframe): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsDownloadInprogress(true));

    const clientId = selectClientId(getState());
    const vitalText = selectVitalText(getState());
    const vitalUnit = selectVitalUnitWithType(getState());
    const clientInformation = selectClientInformation(getState());
    const selectedVitalType = getSelectedVitalType(getState());

    const initialVitalTypes = getInitialSelectedVitalType(getState());
    const ishasBP = (initialVitalTypes || []).filter((v) => v === 'BP_SYSTOLIC').length > 0;

    const isBpPrimary = (initialVitalTypes || [])[0] === 'BP_SYSTOLIC';

    let viewType: ViewType;

    const period = data.period;
    let endDate = dayjs().toISOString();
    let startDate = dayjs().toISOString();

    const generateStartDateAndEndDate = generateStartDateAndEndDateByViewType(
      period as ViewTypeFromB3,
      dayjs(),
      dayjs()
    );

    startDate = generateStartDateAndEndDate.startDate.toISOString();
    endDate = generateStartDateAndEndDate.endDate.toISOString();

    viewType = generateStartDateAndEndDate.viewTye;

    if (period === ViewTypeFromB3.Day) {
      endDate = dayjs(endDate).add(1, 'day').startOf('date').toISOString();
    }

    if (period === ViewTypeFromB3.Week) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Month) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.ThreeMonth) {
      endDate = dayjs(endDate).add(1, 'day').startOf('date').toISOString();
    }

    if (period === ViewTypeFromB3.SixMonth) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Year) {
      endDate = dayjs(endDate).endOf('date').add(1, 'minute').toISOString();
    }

    if (period === ViewTypeFromB3.Custom) {
      const isSameDay = dayjs(data.startDateTime).isSame(data.endDateTime, 'date');

      if (isSameDay) {
        startDate = dayjs(data.startDateTime).startOf('date').toISOString();
        endDate = dayjs(data.endDateTime).endOf('date').toISOString();
        viewType = ViewType.Day;
      } else {
        startDate = dayjs(data.startDateTime).startOf('date').toISOString();
        endDate = dayjs(data.endDateTime).endOf('date').add(1, 'minute').toISOString();

        viewType = generateViewTypeByDayDiffForPDF(dayjs(startDate), dayjs(endDate));
      }
    }

    const labels = generateLabelsByTime(viewType, dayjs(startDate), dayjs(endDate));

    if (period !== ViewTypeFromB3.Custom) {
      startDate = generateStartDateAndEndDate.startDate.toISOString();
      endDate = generateStartDateAndEndDate.endDate.toISOString();
    }

    const vitalTypeURL = getGraphURLBasedOnSelectedVital(getState());

    dispatch(
      httpAction<
        unknown,
        unknown,
        {
          type: any;
          measurement1: VitalGraphItem[];
          measurement2?: VitalGraphItem[];
        }[]
      >(
        {
          method: 'GET',
          url: `${API_ENDPOINT.GRAPH_URL}/${clientId}?${vitalTypeURL}`,
          params: {
            fromDate: startDate,
            toDate: endDate
          }
        },
        async (res) => {
          if (!res.data || res.httpCode !== 200) return;

          let object: any = {};

          let graphData: any[] = [];
          let secondGraphData: any[] = [];
          let thridGraphData: any[] = [];

          if (isBpPrimary && ishasBP) {
            const bpData = res.data.find((v) => v.type === 'BP_SYSTOLIC');
            const nonBpData = res.data.find((v) => v.type !== 'BP_SYSTOLIC' && v.type !== 'BP_DIASTOLIC');
            const totalLabel = labels
              .concat(bpData?.measurement1.map((v) => v.recordedDateTime) || [])
              .concat(nonBpData?.measurement1.map((v) => v.recordedDateTime) || [])
              .sort((a, b) => a - b);

            if (bpData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = bpData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  graphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    firstValue: Number(foundLabel.value),
                    firstRemark: (foundLabel as any).remark || '',
                    thirdType: nonBpData?.type
                  });
                } else {
                  graphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });

              totalLabel.forEach((timeStamp) => {
                const foundLabel = (bpData.measurement2 || []).find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  secondGraphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    secondValue: Number(foundLabel.value),
                    secondRemark: (foundLabel as any).remark || '',
                    thirdType: nonBpData?.type
                  });
                } else {
                  secondGraphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            if (nonBpData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = nonBpData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  thridGraphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    thirdValue: Number(foundLabel.value),
                    thirdRemark: (foundLabel as any).remark || '',
                    thirdType: nonBpData.type
                  });
                } else {
                  thridGraphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            const sortingCloneData = graphData
              .concat(secondGraphData)
              .concat(thridGraphData)
              .filter((v) => v.y)
              .sort((a, b) => a.x - b.x);

            const groupByRecordedData = groupBy(sortingCloneData, 'x');
            const newCloneData: any[] = [];

            Object.keys(groupByRecordedData).forEach((v) => {
              const currentArrayForThisRecorded = groupByRecordedData[v];
              let newObj = {};

              currentArrayForThisRecorded.forEach((item) => {
                newObj = {
                  ...newObj,
                  ...item
                };
              });

              newCloneData.push(newObj);
            });

            object.cloneData = newCloneData;
            object.graphData = graphData;
            object.secondGraphData = secondGraphData;
            object.thirdData = thridGraphData;
            object.vitals = initialVitalTypes;
            object.viewType = viewType;
            object.vital = bpData?.type;
            object.vitalText = 'BP - Systolic / Diastolic';
            object.unit = 'mmHg';
            object.vitalText1 = vitalText(nonBpData?.type);
            object.vitalUnit1 = vitalUnit(nonBpData?.type);
            object.isBpPrimary = true;
            object.hasBP = true;
            object.startDate = startDate;
            object.endDate = endDate;
            object.isCustomView = period === ViewTypeFromB3.Custom;
          }

          if (!isBpPrimary && ishasBP) {
            const bpData = res.data.find((v) => v.type === 'BP_SYSTOLIC');
            const nonBpData = res.data.find((v) => v.type !== 'BP_SYSTOLIC' && v.type !== 'BP_DIASTOLIC');
            const totalLabel = labels
              .concat(bpData?.measurement1.map((v) => v.recordedDateTime) || [])
              .concat(nonBpData?.measurement1.map((v) => v.recordedDateTime) || [])
              .sort((a, b) => a - b);

            if (nonBpData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = nonBpData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  graphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    firstValue: Number(foundLabel.value),
                    firstRemark: (foundLabel as any).remark || '',
                    firstType: nonBpData?.type
                  });
                } else {
                  graphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            if (bpData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = bpData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  secondGraphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    secondValue: Number(foundLabel.value),
                    secondRemark: (foundLabel as any).remark || '',
                    firstType: nonBpData?.type
                  });
                } else {
                  secondGraphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });

              totalLabel.forEach((timeStamp) => {
                const foundLabel = (bpData.measurement2 || []).find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  thridGraphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    thirdValue: Number(foundLabel.value),
                    thirdRemark: (foundLabel as any).remark || '',
                    firstType: nonBpData?.type
                  });
                } else {
                  thridGraphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            const sortingCloneData = graphData
              .concat(secondGraphData)
              .concat(thridGraphData)
              .filter((v) => v.y)
              .sort((a, b) => a.x - b.x);

            const groupByRecordedData = groupBy(sortingCloneData, 'x');
            const newCloneData: any[] = [];

            Object.keys(groupByRecordedData).forEach((v) => {
              const currentArrayForThisRecorded = groupByRecordedData[v];
              let newObj = {};

              currentArrayForThisRecorded.forEach((item) => {
                newObj = {
                  ...newObj,
                  ...item
                };
              });

              newCloneData.push(newObj);
            });

            object.cloneData = newCloneData;
            object.graphData = graphData;
            object.secondGraphData = secondGraphData;
            object.thirdData = thridGraphData;
            object.vitals = initialVitalTypes;
            object.viewType = viewType;
            object.vital = bpData?.type;
            object.vitalText = vitalText(nonBpData?.type);
            object.unit = vitalUnit(nonBpData?.type);
            object.vitalText1 = 'BP - Systolic / Diastolic';
            object.vitalUnit1 = 'mmHg';
            object.isBpPrimary = false;
            object.hasBP = true;
            object.startDate = startDate;
            object.endDate = endDate;
            object.isCustomView = period === ViewTypeFromB3.Custom;
          }

          if (!ishasBP) {
            const firstData = res.data[0];
            const secondData = res.data[1];
            const totalLabel = labels
              .concat(firstData?.measurement1.map((v) => v.recordedDateTime) || [])
              .concat(secondData?.measurement1.map((v) => v.recordedDateTime) || [])
              .sort((a, b) => a - b);

            if (firstData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = firstData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  graphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    firstType: firstData.type,
                    firstValue: Number(foundLabel.value),
                    firstRemark: (foundLabel as any).remark || '',
                    secondType: secondData?.type
                  });
                } else {
                  graphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            if (secondData) {
              totalLabel.forEach((timeStamp) => {
                const foundLabel = secondData.measurement1.find((v) => v.recordedDateTime === timeStamp);

                if (foundLabel) {
                  secondGraphData.push({
                    x: foundLabel.recordedDateTime,
                    y: Number(foundLabel.value),
                    rawData: foundLabel,
                    firstType: firstData.type,
                    secondType: secondData?.type,
                    secondValue: Number(foundLabel.value),
                    secondRemark: (foundLabel as any).remark || ''
                  });
                } else {
                  secondGraphData.push({
                    x: timeStamp,
                    y: null,
                    rawData: null
                  });
                }
              });
            }

            const sortingCloneData = graphData
              .concat(secondGraphData)
              .filter((v) => v.y)
              .sort((a, b) => a.x - b.x);

            const groupByRecordedData = groupBy(sortingCloneData, 'x');
            const newCloneData: any[] = [];

            Object.keys(groupByRecordedData).forEach((v) => {
              const currentArrayForThisRecorded = groupByRecordedData[v];
              let newObj = {};

              currentArrayForThisRecorded.forEach((item) => {
                newObj = {
                  ...newObj,
                  ...item
                };
              });

              newCloneData.push(newObj);
            });

            object.cloneData = newCloneData;
            object.graphData = graphData;
            object.secondGraphData = secondGraphData;
            object.thirdData = [];
            object.vitals = initialVitalTypes;
            object.viewType = viewType;
            object.vital = firstData?.type;
            object.vitalText = vitalText(firstData?.type);
            object.unit = vitalUnit(firstData?.type);
            object.vitalText1 = vitalText(secondData?.type);
            object.vitalUnit1 = vitalUnit(secondData?.type);
            object.isBpPrimary = false;
            object.hasBP = false;
            object.startDate = startDate;
            object.endDate = endDate;
            object.isCustomView = period === ViewTypeFromB3.Custom;
          }

          const result: VitalReportingItem[] = [];

          result.push(object);

          dispatch(setVitalGraphReporting(result));

          const dateRange = `From ${dayjs(period === ViewTypeFromB3.Custom ? data.startDateTime : startDate).format(
            'MMMM DD, YYYY'
          )} To ${dayjs(period === ViewTypeFromB3.Custom ? data.endDateTime : endDate).format('MMMM DD, YYYY')}`;

          setTimeout(() => {
            const doc = new jsPDF('portrait');

            result.forEach((v, i) => {
              if (i > 0) {
                doc.addPage();
              }

              if (Array.isArray(v.cloneData) && v.cloneData.length > 0) {
                const graphInstance = document.querySelector(
                  `#graphItem_VITALS_${initialVitalTypes[0]}_SPACE_VITALS_${initialVitalTypes[1]}_SPACE_SELECTED_${
                    selectedVitalType[0]
                  }_SPACE_SELECTED_${selectedVitalType[1] || ''}`
                );

                console.log('graphInstance', graphInstance);

                const canvasImg = (graphInstance as any).toDataURL();

                doc.addImage(canvasImg, 'JPEG', 10, 73, 186, 90, undefined, 'FAST');
              }

              generatePDFForVitalChartForMultiple(
                doc,
                v.vital as VitalType,
                v.cloneData!,
                dateRange,
                clientInformation,
                v.vitalText,
                v.vitalText1 || '',
                v.isBpPrimary || false,
                v.hasBP || false
              );
            });

            const pages = doc.internal.pages.filter((v) => v);

            const pageWidth = doc.internal.pageSize.width; //Optional
            const pageHeight = doc.internal.pageSize.height; //Optional

            doc.setFontSize(10); //Optional

            for (let j = 0; j < pages.length; j++) {
              let horizontalPos = pageWidth / 2; //Can be fixed number
              let verticalPos = pageHeight - 10; //Can be fixed number

              doc.setPage(j + 1);
              doc.text(`${j + 1} of ${pages.length}`, horizontalPos, verticalPos + 2, {
                align: 'center'
              });
              doc.setFontSize(9);
              doc.setTextColor('#5CBA39');
              doc.text('Services:', horizontalPos - 90, verticalPos - 10);
              doc.setTextColor('#4B4B4C');
              doc.text(
                'Sernior Day Care | Home Care | Nursing Home | Active Ageing | Rehabilitation and Wellness | Family Medicine | Dental',
                horizontalPos - 76,
                verticalPos - 10
              );
              doc.setTextColor('#5CBA39');
              doc.text('Community Support:', horizontalPos - 90, verticalPos - 5);
              doc.setTextColor('#4B4B4C');
              doc.text(
                'Sernior Activity Centres | Community Care | Henderson Home',
                horizontalPos - 60,
                verticalPos - 5
              );
            }

            doc.save(
              `${clientInformation.clientName}_${vitalText(initialVitalTypes[0] as any)}_${vitalText(
                initialVitalTypes[1] as any
              )}_${dayjs(new Date()).format('DD.MM.YYYY')}.pdf`
            );

            dispatch(handleReportDownloadingDone(true));
            dispatch(setIsDownloadInprogress(false));
          }, 2000);
        }
      )
    );
  };

export {
  getDashboardData,
  getListViewData,
  setVitalListView,
  setVitalListViewStatus,
  addVitalsRecord,
  getResourceList,
  openAllVitalsOpenPopup,
  openDownloadVitalsPopUp,
  sendDeleteRequest,
  deleteRecord,
  updateRemark,
  setCurrentPage,
  setCurrentPageSize,
  setTotalElements,
  generatePDFForVitalChart,
  getGraphData,
  setVitalGraphView,
  setVitalGraphData,
  setVitalGraphLabel,
  fetchDataForReporting,
  setSelectedVitalType,
  setInitialSelectedVitalType,
  openSelectSecondaryVitalPopup,
  getMultiVitalListViewData,
  setForceRendeChart,
  handleReportDownloadingDone,
  openEditRemarkPopup,
  setViewType,
  setCustomViewType,
  setGraphStartDate,
  setGraphEndDate,
  setIsMultiVitals,
  setIsShowBorderline,
  loadReferenceRange,
  fetchDataForReportingForMultipleVital
};
