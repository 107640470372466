import styled from '@emotion/styled';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';

export const EmptyStateWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WarningIconIns = styled(WarningIcon)`
  font-size: 80px;
  color: #959595;
`;

export const BigTypographyIns = styled(Typography)`
  font-size: 40px;
  color: #959595;
`;

export const SmallTypographyIns = styled(Typography)`
  font-size: 12px;
  color: #000000;
`;
