import { ThemeOptions } from '@mui/material';
import * as colors from './color';

const spacing = (...factors: number[]) => {
  const spaceBasis = 8;
  const spaces = factors.slice(0, 4).map((f) => {
    const m = Math.abs(f % 1);
    let fx = f;

    // check if decimal number is not exactly 0.5, do ceil or floor;
    if (m !== 0.5) {
      fx = m < 0.5 ? Math.floor(f) : Math.ceil(f);
    }

    return `${fx * spaceBasis}px`;
  });

  return spaces.join(' ');
};

export default spacing;

const muiTheme: ThemeOptions = {
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: spacing(0, 0.5)
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,
          color: colors.white
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& tr': {
            backgroundColor: colors.white,
            boxShadow: '0 2px 6px 0 rgba(0,0,0,0.3)'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: colors.white,
          fontSize: 13,
          lineHeight: '1.125rem',
          '& span': {
            display: 'flex',
            alignItems: 'center',
            '& > svg': {
              marginRight: spacing(0.5)
            }
          }
        },
        body: {
          color: colors.text,
          fontSize: 13,
          lineHeight: 'normal',
          '&.actions': {
            textAlign: 'right',
            whiteSpace: 'nowrap',
            width: '1px'
          },
          '& a, & svg': {
            color: colors.primary
          },
          '& button': {
            background: 'none',
            border: 'none',
            color: colors.primary,
            cursor: 'pointer',
            textAlign: 'left'
          }
        },
        footer: {
          '& span': {
            fontSize: 12
          },
          '& div': {
            fontSize: 12
          }
        },
        sizeSmall: {
          padding: spacing(1),
          '&:first-child': {
            paddingLeft: spacing(2)
          },
          '& button': {
            padding: spacing(0.5)
          }
        }
      }
    }
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white
    },
    primary: {
      main: colors.primary,
      contrastText: colors.white
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white
    },
    text: {
      primary: colors.black,
      secondary: colors.black
    }
  },
  spacing: 8,
  typography: {
    fontFamily: ['Open Sans', '-apple-system', '"Segoe UI"', 'Arial', 'sans-serif'].join(',')
  }
};

export { muiTheme };
