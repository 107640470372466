/* eslint-disable @typescript-eslint/no-empty-interface */
// BASE
import { BaseState } from './base';
import { Chart } from 'chart.js';

declare global {
  interface Window {
    chart: Chart;
  }
}

export type VitalType =
  | 'TEMPERATURE'
  | 'HEART_RATE'
  | 'BLOOD_GLUCOSE'
  | 'BP_SYSTOLIC'
  | 'SPO2'
  | 'WEIGHT'
  | 'PAIN'
  | 'RESPIRATION_RATE';

export interface MeasurementItem {
  type: VitalType;
  value: number | string;
  unit: string;
  lastAgo: string;
}

export interface VitalRowItem {
  value: number | string;
  secondaryValue: number | string;
  unit: string;
  recordedDateTime: number;
  resourceName: string;
  remark: string;
  secondaryRemark: string;
  isLock: boolean;
  id: number;
  resourceId?: string;
}

export interface Resource {
  id: string;
  username: string;
  employeeCode: string;
}

export interface MultiVitalsRowItem {
  primaryValue: number | string;
  secondaryValue: number | string;
  recordedDateTime: number;
  primaryUnit: string;
  secondaryUnit: string;
  resourceName: string;
  clinicianId: string;
  primaryRemark: string;
  secondaryRemark: string;
  isLock: boolean;
  resourceId: string;
  recordId: number;
  fileName: string;
}

export interface VitalReportingItem {
  vital: string;
  vitalText: string;
  viewType: ViewType;
  cloneData?: Pick<VitalRowItem, 'recordedDateTime' | 'resourceName' | 'value' | 'remark' | 'unit'>[];
  data: Pick<VitalRowItem, 'recordedDateTime' | 'resourceName' | 'value' | 'remark' | 'unit'>[];
  graphLabels: number[];
  graphViewData: VitalGraphItem[]; // Acutal Data from API
  graphData: any[]; // Whole Data
  secondGraphData: any[]; // Whole Data
  showSecondGraphData: boolean;
  unit: string;
  baseLineValue: BaseLine;
  vitalText1?: string;
  vitalUnit1?: string;
  thirdData?: any[];
  vitals?: any[];
  isBpPrimary?: boolean;
  hasBP?: boolean;
  startDate?: string;
  endDate?: string;
  isCustomView?: boolean;
}

export interface VitalGraphItem {
  value: number;
  unit: string;
  recordedDateTime: number;
  resourceName: string;
  clinicianId?: string;
}

export interface ReportingIframe {
  bloodGlucose: boolean;
  bp: boolean;
  heartRate: boolean;
  pain: boolean;
  respiration: boolean;
  spo2: boolean;
  temp: boolean;
  weight: boolean;
  period: string;
  startDateTime: string;
  endDateTime: string;
}

export enum ViewType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  ThreeMonth = 'ThreeMonth',
  SixMonth = 'SixMonth',
  Year = 'Year',
  Custom = 'Custom'
}

// TODO: Refactor later
export enum ViewTypeFromB3 {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  ThreeMonth = '3months',
  SixMonth = '6months',
  Year = 'year',
  Custom = 'custom'
}

export interface ParamsFetchVitalRow {
  page: number;
  size: number;
  fromDate?: string | null;
  toDate?: string | null;
  employeeCode: string | null;
  sort?: string | null;
}

export enum VitalListViewStatusType {
  STABLE = 'STABLE',
  RECENTLY_UPDATED = 'RECENTLY_UPDATED'
}

export interface DataWaitingForDownload {
  data: Pick<VitalReportingItem, 'data' | 'vital'>[];
  viewType: ViewType;
  startDate: string;
  endDate: string;
}

export interface BaseLine {
  [key: string]: {
    id: string;
    value: number;
    recordedDateTime: number;
    resourceName: string;
    unit: string;
  };
}

export interface VitalState extends BaseState {
  dashboard: MeasurementItem[];
  loadingDashboard: boolean;
  vitalListView: VitalRowItem[];
  vitalGraphView: VitalGraphItem[];
  currentPage: number;
  currentPageSize: number;
  totalElements: number;
  vitalIcons: Record<string, any>;
  vitalText: Record<string, string>;
  vitalUnit: Record<string, string>;
  vitalGraphLabel: number[];
  vitalGraphData: any[];
  secondVitalGraphData: any[];
  thirdVitalGraphData: any[];
  vitalGraphReportData: VitalReportingItem[];
  vitalListViewStatus: VitalListViewStatusType;
  loadingGraph: boolean;
  loadingListView: boolean;
  dataWaitingForDownload?: DataWaitingForDownload;
  baseLine?: BaseLine;
  initialVitalTypes: string[];
  selectedVitalTypes: string[];
  enabledZoomPan: boolean;
  forceRenderChart: boolean;
  isDownloadingInProgress: boolean;
  isShowBorderline: boolean;
  isMultiVitals: boolean;
  viewType: ViewType | null;
  customViewType: ViewType | null;
  graphStartDate: Date | null;
  graphEndDate: Date | null;
  referenceRanges: Record<string, string>;
}

export interface VitalsRecords {
  heartRate: string;
  bloodGlucose: string;
  dateTime: string;
  temp: string;
  diastolic: string;
  systolic: string;
  spo2: string;
  weight: string;
  respiration: string;
  pain: string;
  heartRateRemark?: string;
  bloodGlucoseRemark?: string;
  temperatureRemark?: string;
  bpRemark?: string;
  spo2Remark?: string;
  weightRemark?: string;
  painRemark?: string;
  respirationRateRemark?: string;
}

export enum VitalsPopupTypeEnum {
  ALL_FIELDS = 'ALL_FIELDS',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS'
}

export interface AllVitalsDataFromIframe {
  clientId: string;
  vitalsData: VitalsRecords;
  type: VitalsPopupTypeEnum;
  fieldName?: VitalType;
  secondaryVitalName?: string;
  isMultiVitalDialog?: boolean;
  orderBy?: string;
  orderDirection?: string;
}

export interface UpdateVitalObject {
  rowId?: number;
  primaryRemark: string;
  secondaryRemark?: string;
}

export interface UpdateVitalDataFromIframe {
  clientId: string;
  vitalsData: UpdateVitalObject;
  type: VitalsPopupTypeEnum;
  selectedVitals: string[];
  isMultiVitalDialog?: boolean;
}

export interface VitalConfigItem {
  createdBy: string;
  createdById: string;
  createdDate: string;
  key: string;
  lastModifiedBy: string;
  lastModifiedById: string;
  lastModifiedDate: string;
  module: string;
  stringValue: string;
  type: string;
}
