import { createSlice } from '@reduxjs/toolkit';

// MODEL
import { AppState } from 'model';

// REDUCER ACTION
import {
  changeAppLoading,
  setAppMessage,
  setAppToken,
  setClientId,
  setClientInformation,
  setUserRoles
} from './reducers';

export const initialState: AppState = {
  loading: false,
  message: undefined,
  clientID: '',
  token: '',
  clientDOB: '',
  clientGender: '',
  clientName: '',
  usersRoles: []
};

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    changeAppLoading,
    setAppMessage,
    setAppToken,
    setClientId,
    setClientInformation,
    setUserRoles
  }
});

export default appSlice.reducer;
