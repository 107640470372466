import React, { FC, memo } from 'react';

// REDUX
import { useAppSelector } from 'hooks';

// SELECTOR
import {
  selectVitalGraphReport,
  selectIsDownloadingInprogress,
  getSelectedVitalType,
  getInitialSelectedVitalType
} from 'redux/vital/selectors';

// COMPONENTS
import DownloadVitalGraph from './download.new.graphView';

const GraphViewWrapper: FC = () => {
  const graphReportingData = useAppSelector(selectVitalGraphReport);
  const isDownloadingInprogress = useAppSelector(selectIsDownloadingInprogress);
  const selectedVitalType = useAppSelector(getSelectedVitalType);
  const initialSelectedVital = useAppSelector(getInitialSelectedVitalType);

  if (!isDownloadingInprogress) return null;

  return (
    <div style={{ position: 'absolute', top: -9999, left: -9999, width: 1000, height: 500 }}>
      {graphReportingData.map((graphItem) => {
        return (
          <DownloadVitalGraph
            key={graphItem.vitals ? 'multiple_graphs' : `line_${graphItem.vital}`}
            customViewType={graphItem.viewType}
            data={graphItem.graphData}
            endDate={new Date(graphItem.endDate!)}
            id={
              initialSelectedVital && initialSelectedVital.length === 2
                ? `graphItem_VITALS_${initialSelectedVital[0]}_SPACE_VITALS_${initialSelectedVital[1]}_SPACE_SELECTED_${
                    selectedVitalType[0]
                  }_SPACE_SELECTED_${selectedVitalType[1] || ''}`
                : `${graphItem.vital}`
            }
            secondData={graphItem.secondGraphData}
            startDate={new Date(graphItem.startDate!)}
            thirdData={graphItem.thirdData || []}
            viewType={graphItem.viewType}
            vital={graphItem.vital}
            vitalText={graphItem.vitalText}
            vitalText1={graphItem.vitalText1}
            vitalUnit={graphItem.unit}
            isCustomView={!!graphItem.isCustomView}
            vitalUnit1={graphItem.vitalUnit1}
          />
        );
      })}
    </div>
  );
};

export default memo(GraphViewWrapper);
