import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { AppState, AppMessage } from 'model';

// ACTION TO CHANGE STATE
export const changeAppLoading: CaseReducer<AppState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setAppMessage: CaseReducer<AppState, PayloadAction<AppMessage | undefined>> = (state, action) => {
  state.message = action.payload;
};

export const setAppToken: CaseReducer<AppState, PayloadAction<string>> = (state, action) => {
  state.token = action.payload;
};

export const setClientId: CaseReducer<AppState, PayloadAction<string>> = (state, action) => {
  state.clientID = action.payload;
};

export const setUserRoles: CaseReducer<AppState, PayloadAction<string[]>> = (state, action) => {
  state.usersRoles = action.payload;
};

export const setClientInformation: CaseReducer<
  AppState,
  PayloadAction<{ clientName: string; clientDOB: string; clientGender: string }>
> = (state, action) => {
  state.clientDOB = action.payload.clientDOB;
  state.clientGender = action.payload.clientGender;
  state.clientName = action.payload.clientName;
};
