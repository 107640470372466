/* eslint-disable max-len */
import React, { FC, ReactNode, useCallback, useMemo, useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';

// MODEL
import { ViewType } from 'model';

// REDUX
import { useAppDispatch, useAppSelector } from 'hooks';
import { sendMessageToParent } from 'redux/app/actions';
import { getDashboardData } from 'redux/vital/action';

// MESSAGE TYPE
import { PostMessageType } from 'model';

// SELECTOR
import {
  selectAvailableVitalTypes,
  selectVitalIcons,
  getSelectedVitalType,
  selectViewType,
  selectCustomViewType,
  selectGraphStartDate,
  selectGraphEndDate
} from 'redux/vital/selectors';
import { selectClientId } from 'redux/app/selectors';

// ACTIONS
import { setCurrentPage, setCurrentPageSize, setTotalElements } from 'redux/vital/action';

//STYLES
import { VitalIconWrapperItem, HR } from './styles';
import { VitalType } from 'model';

import VitalGraph from './index.graphview';
import VitalListView from './index.listview';
import { Grid } from '@mui/material';

const IconWrapper: FC<{ icon: ReactNode; text: string; active: boolean; type: string; callBack: () => void }> = ({
  icon,
  text,
  active,
  callBack
}) => {
  return (
    <div
      style={{
        padding: 10,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      onClick={() => {
        callBack();
      }}
    >
      {icon}
      <Typography
        sx={{
          fontSize: 12,
          color: active ? '#258B96' : '#959595',
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 1
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

const VitalDetail: FC = () => {
  const enabledGraph = process.env.REACT_APP_ENABLED_GRAPH === 'true';
  const currentTabFromLocalstorage = window.localStorage.getItem('currentTab');
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [startGraphDate, setStartGraphDate] = useState<Date>(dayjs().startOf('date').toDate());
  const [endGraphDate, setEndGraphDate] = useState<Date>(dayjs().endOf('date').toDate());
  const [employeeCode, setEmployeeCode] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [selectRemark, setSelectRemark] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(Number(currentTabFromLocalstorage));
  const [viewType, setViewType] = useState<ViewType>(ViewType.Day);
  const [orderBy, setOrderBy] = useState('date');
  const [orderDirection, setOrderDirection] = useState<'desc' | 'asc'>('desc');
  const [customViewType, setCustomViewType] = useState<ViewType | null>(ViewType.Day);
  const clientId = useAppSelector(selectClientId);
  const selectedType = useAppSelector(getSelectedVitalType);
  const selectedViewType = useAppSelector(selectViewType);
  const selectedCustomViewType = useAppSelector(selectCustomViewType);
  const selectedGraphStartDate = useAppSelector(selectGraphStartDate);
  const selectedGraphEndDate = useAppSelector(selectGraphEndDate);

  const availableVitalTypes = useAppSelector(selectAvailableVitalTypes);

  useEffect(() => {
    if (selectedViewType) {
      setViewType(selectedViewType);
    }
  }, [selectedViewType]);

  useEffect(() => {
    if (selectedCustomViewType) {
      setCustomViewType(selectedCustomViewType);
    }
  }, [selectedCustomViewType]);

  useEffect(() => {
    if (selectedGraphStartDate) {
      setStartGraphDate(selectedGraphStartDate);
    }
  }, [selectedGraphStartDate]);

  useEffect(() => {
    if (selectedGraphEndDate) {
      setEndGraphDate(selectedGraphEndDate);
    }
  }, [selectedGraphEndDate]);

  useEffect(() => {
    if (currentTabFromLocalstorage) {
      setSelectedTab(Number(currentTabFromLocalstorage));
    }
  }, []);

  useEffect(() => {
    if (clientId.length > 0 && clientId.length > 0) {
      dispatch(getDashboardData());
    }
  }, [clientId, dispatch]);

  const resetData = useCallback(
    (isBack?: boolean) => {
      dispatch(setCurrentPage(0));
      dispatch(setCurrentPageSize(10));
      dispatch(setTotalElements(0));
      // RESET LIST VIEW
      setStartDate(null);
      setEndDate(null);
      setEmployeeCode([]);
      setSelectedId(undefined);
      setSelectRemark('');
      setOrderBy('date');
      setOrderDirection('desc');

      // RESET GRAPH VIEW
      setViewType(ViewType.Day);
      setStartGraphDate(dayjs().startOf('date').toDate());
      setEndGraphDate(dayjs().endOf('date').toDate());
      setCustomViewType(ViewType.Day);

      if (isBack) {
        window.localStorage.setItem('currentTab', `0`);
      } else {
        window.localStorage.setItem('currentTab', `${selectedTab}`);
      }
    },
    [selectedTab]
  );

  const iconStatus = useAppSelector(selectVitalIcons);

  const iconText = useMemo<Record<string, any>>(() => {
    return {
      HEART_RATE: 'Heart Rate',
      BLOOD_GLUCOSE: 'Blood Glucose',
      BP_SYSTOLIC: 'BP - Systolic / Diastolic',
      TEMPERATURE: 'Temperature',
      SPO2: 'SpO2 Saturation',
      WEIGHT: 'Weight',
      PAIN: 'Pain',
      RESPIRATION_RATE: 'Respiration Rate'
    };
  }, []);

  const handleNavigateToOverview = useCallback(() => {
    resetData(true);

    dispatch(sendMessageToParent({}, PostMessageType.NAVIGATE_PARENT));
  }, [dispatch, resetData]);

  const handleClickVitalIcon = useCallback((vital: string) => {
    // resetData();
    // dispatch(setSelectedVitalType([`${vital.toUpperCase()}`]));
    dispatch(sendMessageToParent({ type: vital }, PostMessageType.NAVIGATE_TO_OTHER_VITAL));
  }, []);

  const handleSetViewType = useCallback((localViewType: ViewType) => {
    setViewType(localViewType);
    dispatch(
      sendMessageToParent(
        {
          viewType: localViewType
        },
        PostMessageType.SET_PARENT_VIEW_TYPE
      )
    );
  }, []);

  const handleSetCustomViewType = useCallback((localViewType: ViewType | null) => {
    setCustomViewType(localViewType);
    dispatch(
      sendMessageToParent(
        {
          viewType: localViewType
        },
        PostMessageType.SET_PARENT_CUSTOM_VIEW_TYPE
      )
    );
  }, []);

  const handleSetGraphStartDate = useCallback((localStartDate: Date) => {
    setStartGraphDate(localStartDate);
    dispatch(
      sendMessageToParent(
        {
          startDate: localStartDate
        },
        PostMessageType.SET_PARENT_GRAPH_START_DATE
      )
    );
  }, []);

  const handleSetGraphEndDate = useCallback((localEndDate: Date) => {
    setEndGraphDate(localEndDate);
    dispatch(
      sendMessageToParent(
        {
          endDate: localEndDate
        },
        PostMessageType.SET_PARENT_GRAPH_END_DATE
      )
    );
  }, []);

  const renderIcon = useMemo(() => {
    return ['HEART_RATE', 'BLOOD_GLUCOSE', 'BP_SYSTOLIC', 'TEMPERATURE', 'SPO2', 'WEIGHT', 'PAIN', 'RESPIRATION_RATE']
      .filter((v) => availableVitalTypes.includes(v as VitalType))
      .map((v) => {
        const ActiveComponent = iconStatus(v as VitalType).active;
        const NonActiveComponent = iconStatus(v as VitalType).nonActive;

        return (
          <Grid item md="auto" sm={3} xs={3}>
            <VitalIconWrapperItem key={v}>
              <IconWrapper
                active={selectedType.includes(v)}
                callBack={() => handleClickVitalIcon(v)}
                icon={selectedType.includes(v) ? <ActiveComponent height={50} width={50} /> : <NonActiveComponent />}
                text={iconText[v]}
                type={v}
              />
            </VitalIconWrapperItem>
          </Grid>
        );
      });
  }, [availableVitalTypes, iconStatus, selectedType, handleClickVitalIcon, iconText]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
        <Grid
          item
          style={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'pointer' }}
          xs={1}
          onClick={handleNavigateToOverview}
        >
          <ArrowBackIosIcon />
          <Typography>Back</Typography>
        </Grid>
        <Grid container item xs justifyContent="flex-end" spacing={1} style={{ marginLeft: '20px' }}>
          {renderIcon}
        </Grid>
      </Grid>
      <HR />
      {enabledGraph ? (
        selectedTab === 0 ? (
          <VitalGraph
            customViewType={customViewType}
            endDate={endGraphDate}
            selectedTab={selectedTab}
            setCustomViewType={handleSetCustomViewType}
            setEndDate={handleSetGraphEndDate}
            setStartDate={handleSetGraphStartDate}
            setViewType={handleSetViewType}
            startDate={startGraphDate}
            viewType={viewType}
            onSelect={(v) => {
              window.localStorage.setItem('currentTab', `${v}`);
              setSelectedTab(v);
            }}
          />
        ) : (
          <VitalListView
            employeeCode={employeeCode}
            endDate={endDate}
            orderBy={orderBy}
            orderDirection={orderDirection}
            selectedId={selectedId}
            selectedTab={selectedTab}
            selectRemark={selectRemark}
            setEmployeeCode={setEmployeeCode}
            setEndDate={setEndDate}
            setOrderBy={setOrderBy}
            setOrderDirection={setOrderDirection}
            setSelectedId={setSelectedId}
            setSelectRemark={setSelectRemark}
            setStartDate={setStartDate}
            startDate={startDate}
            onSelect={(v) => {
              window.localStorage.setItem('currentTab', `${v}`);
              setSelectedTab(v);
            }}
          />
        )
      ) : (
        <VitalListView
          employeeCode={employeeCode}
          endDate={endDate}
          orderBy={orderBy}
          orderDirection={orderDirection}
          selectedId={selectedId}
          selectedTab={selectedTab}
          selectRemark={selectRemark}
          setEmployeeCode={setEmployeeCode}
          setEndDate={setEndDate}
          setOrderBy={setOrderBy}
          setOrderDirection={setOrderDirection}
          setSelectedId={setSelectedId}
          setSelectRemark={setSelectRemark}
          setStartDate={setStartDate}
          startDate={startDate}
          onSelect={(v) => {
            window.localStorage.setItem('currentTab', `${v}`);
            setSelectedTab(v);
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default VitalDetail;
