/* eslint-disable max-len */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'chartjs-adapter-date-fns';
import dayjs from 'dayjs';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  Plugin
} from 'chart.js';
import Point from 'assets/icon/point.png';
import RedPoint from 'assets/icon/red-dot.png';
import PurplePoint from 'assets/icon/purple-dot.png';
import { Line } from 'react-chartjs-2';
import {
  selectForceRenderChart,
  selectLoadingGraph,
  getSelectedVitalType,
  getInitialSelectedVitalType,
  selectReferenceRanges,
  selectIsShowBorderline
} from 'redux/vital/selectors';
import { generateViewTypeByDayDiff } from 'helper/index';
import { enUS } from 'date-fns/locale';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { setForceRendeChart } from 'redux/vital/action';

// MODEL
import { ViewType } from 'model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { VitalsReferenceRangeKeyEnum, VitalsShortNameForRefRangeEnum } from 'constant/vitals';

let timeOutId: NodeJS.Timer;

const chartPoint = new Image();
const redChartPoint = new Image();
const purplePoint = new Image();

chartPoint.src = Point;
chartPoint.width = 13;
chartPoint.height = 13;
redChartPoint.src = RedPoint;
redChartPoint.width = 13;
redChartPoint.height = 13;
purplePoint.src = PurplePoint;
purplePoint.width = 13;
purplePoint.height = 13;

const VitalGraph: FC<{
  viewType: ViewType;
  customViewType: ViewType | null | undefined;
  vitalText?: string;
  vitalUnit?: string;
  vitalText1?: string;
  vitalUnit1?: string;
  id?: string;
  setStartDate?: (date: Date) => void;
  startDate?: Date;
  setEndDate?: (date: Date) => void;
  endDate?: Date;
  setViewType?: (viewType: ViewType) => void;
  data: any[];
  secondData: any[];
  thirdData: any[];
  vital: string;
  isCustomView: boolean;
}> = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  viewType,
  vitalText,
  vitalUnit,
  vitalText1 = '',
  vitalUnit1 = '',
  customViewType,
  id,
  setViewType,
  data,
  secondData,
  thirdData,
  vital,
  isCustomView
}) => {
  const dispatch = useAppDispatch();
  const enabledLoadingGraph = useAppSelector(selectLoadingGraph);
  const forceRenderChart = useAppSelector(selectForceRenderChart);
  const selectedVitalType = useAppSelector(getSelectedVitalType);
  const isShowBorderline = useAppSelector(selectIsShowBorderline);
  const referenceRanges = useAppSelector(selectReferenceRanges);
  const chartRef = useRef<any>();
  const [startZoom, setStartZoom] = useState(false);
  const initialVitalTypes = useAppSelector(getInitialSelectedVitalType);

  const legendPluggin: Plugin = {
    id: 'horizontalLine',
    afterDraw: function (chartInstance: any) {
      const ctx = chartInstance.ctx;

      if (chartInstance.canvas.id === 'main_chart_js') return;

      const isBP = chartInstance.canvas.id.includes('BP_SYSTOLIC');

      const unitVital: any = {
        HEART_RATE: 'bpm',
        BLOOD_GLUCOSE: 'mmoL',
        BP_SYSTOLIC: 'mmHg',
        TEMPERATURE: '°C',
        SPO2: '%',
        WEIGHT: 'Kg',
        PAIN: 'Scale',
        RESPIRATION_RATE: 'bpm'
      };

      if (chartInstance.canvas.id.includes('graphItem_')) {
        const localVitals = chartInstance.canvas.id.replace('graphItem_', '').split('_SPACE_');
        const initiallySelectedVitalTypes: any[] = [];
        const selectedTypes: any[] = [];

        localVitals.forEach((e: string) => {
          if (e.includes('VITALS_')) {
            initiallySelectedVitalTypes.push(e.replace('VITALS_', ''));
          }

          if (e.includes('SELECTED_')) {
            selectedTypes.push(e.replace('SELECTED_', ''));
          }
        });

        const isBPInner = selectedTypes.find((v) => v === 'BP_SYSTOLIC');
        const nonBP = selectedTypes.find((v) => v !== 'BP_SYSTOLIC');
        const isMultipleVital = initiallySelectedVitalTypes.length === 2;
        const isBpPrimary = initiallySelectedVitalTypes[0] === 'BP_SYSTOLIC';
        const ishasBP = initiallySelectedVitalTypes.filter((v) => v === 'BP_SYSTOLIC').length > 0;
        const firstVital = initiallySelectedVitalTypes[0];
        const firstVitalFromSelectedType = selectedTypes.includes(firstVital);
        const secondVital = initiallySelectedVitalTypes[1];
        const secondVitalFromSelectedType = selectedTypes.includes(secondVital);

        if (isMultipleVital && isBpPrimary && ishasBP) {
          if (isBPInner) {
            ctx.beginPath();
            ctx.moveTo(360, 10);
            ctx.lineTo(410, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#009aa6';
            ctx.fillText('SBP (mmHg)', 420, 13);
            ctx.stroke();
            ctx.drawImage(chartPoint, 377, 2);
            ctx.restore();

            ctx.beginPath();
            ctx.moveTo(510, 10);
            ctx.lineTo(560, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#b74253';
            ctx.fillText('DBP (mmHg)', 570, 13);
            ctx.stroke();
            ctx.drawImage(redChartPoint, 527, 2);
            ctx.restore();
          }

          if (nonBP) {
            ctx.beginPath();
            ctx.moveTo(660, 10);
            ctx.lineTo(710, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#9747FF';
            ctx.fillText(unitVital[nonBP], 720, 13);
            ctx.stroke();
            ctx.drawImage(purplePoint, 677, 2);
            ctx.restore();
          }
        }

        if (isMultipleVital && !isBpPrimary && ishasBP) {
          if (nonBP) {
            ctx.beginPath();
            ctx.moveTo(360, 10);
            ctx.lineTo(410, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#009aa6';
            ctx.fillText(unitVital[nonBP], 420, 13);
            ctx.stroke();
            ctx.drawImage(chartPoint, 377, 2);
            ctx.restore();
          }

          if (isBPInner) {
            ctx.beginPath();
            ctx.moveTo(510, 10);
            ctx.lineTo(560, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#b74253';
            ctx.fillText('SBP (mmHg)', 570, 13);
            ctx.stroke();
            ctx.drawImage(redChartPoint, 527, 2);
            ctx.restore();

            ctx.beginPath();
            ctx.moveTo(660, 10);
            ctx.lineTo(710, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#9747FF';
            ctx.fillText('DBP (mmHg)', 720, 13);
            ctx.stroke();
            ctx.drawImage(purplePoint, 677, 2);
            ctx.restore();
          }
        }

        if (isMultipleVital && !ishasBP) {
          if (firstVitalFromSelectedType) {
            ctx.beginPath();
            ctx.moveTo(360, 10);
            ctx.lineTo(410, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#009aa6';
            ctx.fillText(unitVital[firstVital], 420, 13);
            ctx.stroke();
            ctx.drawImage(chartPoint, 377, 2);
            ctx.restore();
          }

          if (secondVitalFromSelectedType) {
            ctx.beginPath();
            ctx.moveTo(510, 10);
            ctx.lineTo(560, 10);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#b74253';
            ctx.fillText(unitVital[secondVital], 570, 13);
            ctx.stroke();
            ctx.drawImage(redChartPoint, 527, 2);
            ctx.restore();
          }
        }

        return;
      }

      if (isBP) {
        ctx.beginPath();
        ctx.moveTo(410, 10);
        ctx.lineTo(460, 10);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#009aa6';
        ctx.fillText('SBP (mmHg)', 470, 13);
        ctx.stroke();
        ctx.drawImage(chartPoint, 427, 2);
        ctx.restore();

        ctx.beginPath();
        ctx.moveTo(560, 10);
        ctx.lineTo(610, 10);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#b74253';
        ctx.fillText('DBP (mmHg)', 620, 13);
        ctx.stroke();
        ctx.drawImage(redChartPoint, 577, 2);
        ctx.restore();
      } else {
        ctx.beginPath();
        ctx.moveTo(480, 10);
        ctx.lineTo(530, 10);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#009aa6';
        ctx.fillText(unitVital[chartInstance.canvas.id], 540, 13);
        ctx.stroke();
        ctx.drawImage(chartPoint, 497, 2);
        ctx.restore();
      }
    }
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    TimeScale,
    zoomPlugin,
    annotationPlugin,
    legendPluggin
  );

  useEffect(() => {
    if (chartRef.current) {
      window.chart = chartRef.current;
    }
  }, [chartRef]);

  const updateDataSets = useCallback(() => {
    const isBPInner = (selectedVitalType || []).find((v) => v === 'BP_SYSTOLIC');
    const nonBP = (selectedVitalType || []).find((v) => v !== 'BP_SYSTOLIC');
    const isMultipleVital = (initialVitalTypes || []).length === 2;
    const isBpPrimary = (initialVitalTypes || [])[0] === 'BP_SYSTOLIC';
    const ishasBP = (initialVitalTypes || []).filter((v) => v === 'BP_SYSTOLIC').length > 0;
    const firstVital = (initialVitalTypes || [])[0];
    const firstVitalFromSelectedType = (selectedVitalType || []).includes(firstVital);
    const secondVital = (initialVitalTypes || [])[1];
    const secondVitalFromSelectedType = (selectedVitalType || []).includes(secondVital);

    let resultMultiple: any[] = [];

    if (isMultipleVital && isBpPrimary && ishasBP) {
      if (isBPInner) {
        resultMultiple = [
          {
            data: data,
            borderColor: '#258B96',
            backgroundColor: ['#258B96'],
            pointStyle: chartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y'
          }
        ];

        resultMultiple = resultMultiple.concat([
          {
            data: secondData,
            borderColor: '#b74253',
            backgroundColor: ['#b74253'],
            pointStyle: redChartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y'
          }
        ]);

        if (nonBP) {
          resultMultiple = resultMultiple.concat([
            {
              data: thirdData,
              borderColor: '#9747FF',
              backgroundColor: ['#9747FF'],
              pointStyle: purplePoint,
              tension: 0.0,
              spanGaps: true,
              yAxisID: 'y2'
            }
          ]);
        }
      }

      if (!isBPInner && nonBP) {
        resultMultiple = resultMultiple.concat([
          {
            data: data,
            borderColor: '#9747FF',
            backgroundColor: ['#9747FF'],
            pointStyle: purplePoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y2'
          }
        ]);
      }

      setTimeout(() => {
        chartRef.current.data.datasets = resultMultiple;
        chartRef.current.update();
      }, 0);

      return;
    }

    if (isMultipleVital && !isBpPrimary && ishasBP) {
      if (nonBP) {
        resultMultiple = [
          {
            data: data,
            borderColor: '#258B96',
            backgroundColor: ['#258B96'],
            pointStyle: chartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y'
          }
        ];

        if (isBPInner) {
          resultMultiple = resultMultiple.concat([
            {
              data: secondData,
              borderColor: '#b74253',
              backgroundColor: ['#b74253'],
              pointStyle: redChartPoint,
              tension: 0.0,
              spanGaps: true,
              yAxisID: 'y2'
            }
          ]);

          resultMultiple = resultMultiple.concat([
            {
              data: thirdData,
              borderColor: '#9747FF',
              backgroundColor: ['#9747FF'],
              pointStyle: purplePoint,
              tension: 0.0,
              spanGaps: true,
              yAxisID: 'y2'
            }
          ]);
        }
      }

      if (!nonBP && isBPInner) {
        resultMultiple = resultMultiple.concat([
          {
            data: data,
            borderColor: '#b74253',
            backgroundColor: ['#b74253'],
            pointStyle: redChartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y2'
          }
        ]);

        resultMultiple = resultMultiple.concat([
          {
            data: secondData,
            borderColor: '#9747FF',
            backgroundColor: ['#9747FF'],
            pointStyle: purplePoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y2'
          }
        ]);
      }

      setTimeout(() => {
        chartRef.current.data.datasets = resultMultiple;
        chartRef.current.update();
      }, 0);

      return;
    }

    if (isMultipleVital && !ishasBP) {
      if (firstVitalFromSelectedType && secondVitalFromSelectedType) {
        resultMultiple = [
          {
            data: data,
            borderColor: '#258B96',
            backgroundColor: ['#258B96'],
            pointStyle: chartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y'
          }
        ];

        resultMultiple = resultMultiple.concat([
          {
            data: secondData,
            borderColor: '#b74253',
            backgroundColor: ['#b74253'],
            pointStyle: redChartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y2'
          }
        ]);
      }

      if (firstVitalFromSelectedType && !secondVitalFromSelectedType) {
        resultMultiple = [
          {
            data: data,
            borderColor: '#258B96',
            backgroundColor: ['#258B96'],
            pointStyle: chartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y'
          }
        ];
      }

      if (!firstVitalFromSelectedType && secondVitalFromSelectedType) {
        resultMultiple = [
          {
            data: data,
            borderColor: '#b74253',
            backgroundColor: ['#b74253'],
            pointStyle: redChartPoint,
            tension: 0.0,
            spanGaps: true,
            yAxisID: 'y2'
          }
        ];
      }

      setTimeout(() => {
        chartRef.current.data.datasets = resultMultiple;
        chartRef.current.update();
      }, 0);

      return;
    }

    let result: any[] = [
      {
        data: data,
        borderColor: '#258B96',
        backgroundColor: ['#258B96'],
        pointStyle: chartPoint,
        tension: 0.0,
        spanGaps: true,
        yAxisID: 'y'
      }
    ];

    if (secondData.length > 0) {
      result = result.concat([
        {
          data: secondData,
          borderColor: '#b74253',
          backgroundColor: ['#b74253'],
          pointStyle: redChartPoint,
          tension: 0.0,
          spanGaps: true,
          yAxisID: 'y'
        }
      ]);
    }

    setTimeout(() => {
      chartRef.current.data.datasets = result;
      chartRef.current.update();
    }, 0);
  }, [data, secondData, thirdData, initialVitalTypes]);

  useEffect(() => {
    if (chartRef.current && !enabledLoadingGraph) {
      updateDataSets();
    }
  }, [data, secondData, thirdData, chartRef.current, enabledLoadingGraph, initialVitalTypes]);

  const updateChartMinMax = useCallback(() => {
    if (viewType === ViewType.Custom || isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs(startDate).startOf('date').valueOf(),
        max: dayjs(endDate).valueOf()
      });
    }

    if (viewType === ViewType.Day && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().startOf('date').valueOf(),
        max: dayjs().add(1, 'day').startOf('date').valueOf()
      });
    }

    if (viewType === ViewType.Week && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().startOf('week').valueOf(),
        max: dayjs().endOf('week').add(1, 'minute').valueOf()
      });
    }

    if (viewType === ViewType.Month && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().startOf('month').valueOf(),
        max: dayjs().endOf('month').add(1, 'minute').valueOf()
      });
    }

    if (viewType === ViewType.ThreeMonth && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().subtract(3, 'months').startOf('month').valueOf(),
        max: dayjs().endOf('month').valueOf()
      });
    }

    if (viewType === ViewType.SixMonth && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().subtract(6, 'months').startOf('month').valueOf(),
        max: dayjs().endOf('month').add(1, 'minute').valueOf()
      });
    }

    if (viewType === ViewType.Year && !isCustomView) {
      chartRef.current?.zoomScale('x', {
        min: dayjs().startOf('year').valueOf(),
        max: dayjs().endOf('year').add(1, 'minute').valueOf()
      });
    }

    chartRef.current?.update();
  }, [viewType, customViewType, endDate, startDate, isCustomView]);

  const xLabel = useMemo(() => {
    if (viewType === ViewType.Day) {
      return 'Time (Hour)';
    }

    if (viewType === ViewType.Week || viewType === ViewType.Month) {
      return 'Date';
    }

    if (viewType === ViewType.ThreeMonth || viewType === ViewType.SixMonth) {
      return 'Week';
    }

    if (viewType === ViewType.Year) {
      return 'Month';
    }

    if (viewType === ViewType.Custom && customViewType === ViewType.Day) {
      return 'Custom (Hour)';
    }

    if (viewType === ViewType.Custom && (customViewType === ViewType.Week || customViewType === ViewType.Month)) {
      return 'Custom (Date)';
    }

    if (
      viewType === ViewType.Custom &&
      (customViewType === ViewType.ThreeMonth || customViewType === ViewType.SixMonth)
    ) {
      return 'Custom (Week)';
    }

    if (viewType === ViewType.Custom && customViewType === ViewType.Year) {
      return 'Custom (Month)';
    }
  }, [viewType, customViewType]);

  const updateTimeUnit = useCallback(() => {
    const view = viewType === ViewType.Custom ? customViewType : viewType;

    let result =
      view === ViewType.Day
        ? 'hour'
        : view === ViewType.Week || view === ViewType.Month
        ? 'day'
        : view === ViewType.ThreeMonth || view === ViewType.SixMonth
        ? 'week'
        : 'month';

    if (chartRef.current && chartRef.current.options.scales && chartRef.current.options.scales.x) {
      chartRef.current.options.scales.x.time.unit = result;
    }

    if (viewType === ViewType.Custom) {
      chartRef.current.options.scales.x.title.text = xLabel;
    }

    chartRef.current?.update();
  }, [viewType, customViewType, startZoom, chartRef, startDate, endDate]);

  const updateYLabel = useCallback(() => {
    if (chartRef.current && vitalText && vitalUnit && vitalText.length > 0 && vitalUnit.length > 0) {
      chartRef.current.options.scales.y.title.text = `${vitalText} (${vitalUnit})`;
    }

    if (chartRef.current && vitalText1 && vitalUnit1 && vitalText1.length > 0 && vitalUnit1.length > 0) {
      chartRef.current.options.scales.y2.display = true;
      chartRef.current.options.scales.y2.title.text = `${vitalText1} (${vitalUnit1})`;
    }

    setTimeout(() => {
      chartRef.current?.update();
    }, 100);
  }, [chartRef, vitalText, vitalUnit, vitalText1, vitalUnit1]);

  const updateMaxMinForY = useCallback(() => {
    const isMultiple = initialVitalTypes.length === 2;

    Object.keys(referenceRanges).forEach((key) => {
      const rangeItem = JSON.parse(referenceRanges[key]);
      const vitalType = VitalsReferenceRangeKeyEnum[key as keyof typeof VitalsReferenceRangeKeyEnum];

      if (selectedVitalType[0] === vitalType || vital === vitalType) {
        if (key === 'vital_signs_blood_pressure_systolic') {
          chartRef.current.options.scales.y.suggestedMax = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y.max = rangeItem.unrealisticHigh;
        } else if (key === 'vital_signs_resting_blood_pressure_diastollic') {
          chartRef.current.options.scales.y.suggestedMin = rangeItem.unrealisticLow;
          chartRef.current.options.scales.y2.min = rangeItem.unrealisticLow;
        } else {
          chartRef.current.options.scales.y.suggestedMax = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y.suggestedMin = rangeItem.unrealisticLow;
          chartRef.current.options.scales.y.max = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y.min = rangeItem.unrealisticLow;
        }
      }

      if (
        (selectedVitalType.length > 1 && selectedVitalType[1] === vitalType) ||
        (initialVitalTypes.length === 2 && selectedVitalType.length === 1 && selectedVitalType[0] === vitalType)
      ) {
        if (key === 'vital_signs_blood_pressure_systolic') {
          chartRef.current.options.scales.y2.suggestedMax = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y.max = rangeItem.unrealisticHigh;
        } else if (key === 'vital_signs_resting_blood_pressure_diastollic') {
          chartRef.current.options.scales.y2.suggestedMin = rangeItem.unrealisticLow;
          chartRef.current.options.scales.y2.min = rangeItem.unrealisticLow;
        } else {
          chartRef.current.options.scales.y2.suggestedMax = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y2.suggestedMin = rangeItem.unrealisticLow;
          chartRef.current.options.scales.y2.max = rangeItem.unrealisticHigh;
          chartRef.current.options.scales.y2.min = rangeItem.unrealisticLow;
        }
      }
    });

    if (isMultiple) {
      initialVitalTypes.forEach((vitalSign, idx) => {
        const isPain = vitalSign === 'PAIN';
        const isBP = vitalSign === 'BP_SYSTOLIC' || vitalSign === 'BP_DIASTOLIC';

        if (isPain && idx === 0) {
          chartRef.current.options.scales.y.suggestedMax = 10;
          chartRef.current.options.scales.y.suggestedMin = 0;
          chartRef.current.options.scales.y.min = 0;
          chartRef.current.options.scales.y.max = 10;
          chartRef.current.options.scales.y.ticks.stepSize = 1;
        }

        if (isPain && idx === 1) {
          chartRef.current.options.scales.y2.suggestedMax = 10;
          chartRef.current.options.scales.y2.suggestedMin = 0;
          chartRef.current.options.scales.y2.min = 0;
          chartRef.current.options.scales.y2.max = 10;
          chartRef.current.options.scales.y2.ticks.stepSize = 1;
        }

        if (!isBP && !isPain && idx === 0) {
          chartRef.current.options.scales.y.ticks.stepSize = 9;
        }

        if (!isBP && !isPain && idx === 1) {
          chartRef.current.options.scales.y2.ticks.stepSize = 6;
        }
      });

      setTimeout(() => {
        chartRef.current?.update();
      }, 100);

      return;
    }

    if (vital === 'PAIN') {
      chartRef.current.options.scales.y.max = 10;
      chartRef.current.options.scales.y.ticks.stepSize = 1;
    } else {
      chartRef.current.options.scales.y.ticks.stepSize = 10;
    }

    setTimeout(() => {
      chartRef.current?.update();
    }, 100);
  }, [selectedVitalType, initialVitalTypes, referenceRanges, vital]);

  const updateScaleBasedOnCheckBox = useCallback(() => {
    const isMultipleVItal = initialVitalTypes.length === 2;
    const isPrimary = initialVitalTypes[0];
    const isSecondary = initialVitalTypes[1];

    if (isMultipleVItal && selectedVitalType.length === 1) {
      if (selectedVitalType[0] === isPrimary) {
        chartRef.current.options.scales.y.display = true;
        chartRef.current.options.scales.y2.display = false;
      }

      if (selectedVitalType[0] === isSecondary) {
        chartRef.current.options.scales.y.display = false;
        chartRef.current.options.scales.y2.display = true;
      }
    }

    setTimeout(() => {
      chartRef.current?.update();
    }, 0);
  }, [selectedVitalType, initialVitalTypes]);

  useEffect(() => {
    if (chartRef.current) {
      updateYLabel();
    }
  }, [vitalText, vitalUnit, chartRef, vitalText1, vitalText1, initialVitalTypes]);

  useEffect(() => {
    if (chartRef.current) {
      updateScaleBasedOnCheckBox();
    }
  }, [selectedVitalType, chartRef]);

  useEffect(() => {
    if (chartRef.current) {
      updateMaxMinForY();
    }
  }, [selectedVitalType, chartRef]);

  useEffect(() => {
    if (startZoom && chartRef.current && chartRef.current.options.scales && chartRef.current.options.scales.x) {
      chartRef.current.options.scales.x.time.unit = undefined;
      chartRef.current?.update();
    }
  }, [startZoom]);

  useEffect(() => {
    if (!enabledLoadingGraph && chartRef.current && !startZoom) {
      updateChartMinMax();
      updateTimeUnit();
      updateYLabel();
      updateMaxMinForY();
      updateScaleBasedOnCheckBox();
    }
  }, [enabledLoadingGraph, chartRef, updateChartMinMax, startZoom]);

  const createCanvasElement = (arrowType: string, text: string, color: string): HTMLCanvasElement => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');

    canvas.width = 200;
    canvas.height = 20;
    const context = canvas.getContext('2d');

    // Draw Material Icon
    const iconX = 0;
    const iconY = 0;
    const image = new Image();

    const downArrow = `<path d="M8.74228e-07 5L0.881251 4.11875L4.375 7.60625L4.375 7.64949e-07L5.625 9.83506e-07L5.625 7.60625L9.1125 4.1125L10 5L5 10L8.74228e-07 5Z" fill="${color}"/>`;
    const upArrow = `<path d="M10 8L9.11875 8.88125L5.625 5.39375V13H4.375V5.39375L0.8875 8.8875L0 8L5 3L10 8Z" fill="${color}"/>`;

    const svg = `<svg width="115" height="20" viewBox="0 0 160 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${arrowType === 'UP' ? upArrow : downArrow}
      <text x="12" y="14" fill="${color}">${'  '} ${text}</text>
      </svg>`;

    image.src = `data:image/svg+xml;base64,${window.btoa(svg)}`;
    context!.drawImage(image, iconX, iconY, 110, 16);

    return canvas;
  };

  const prepareAnnotationObj = (
    val: string,
    color: string,
    text: string,
    arrowType: string,
    lblPosition: string,
    scaleID: string
  ) => {
    if (!val) return;

    return {
      type: 'line',
      yMin: val,
      yMax: val,
      borderColor: color,
      borderWidth: 2,
      yScaleID: scaleID,
      borderDash: [10],
      label: {
        backgroundColor: 'rgba(0,0,0,0)',
        content: () => createCanvasElement(arrowType, text, color),
        enabled: true,
        display: true,
        position: lblPosition,
        yAdjust: -8
      }
    };
  };

  const updateRefRanges = useCallback(() => {
    if (!chartRef.current) return;

    const annotations: any = {};

    if (!isShowBorderline) {
      setTimeout(() => {
        chartRef.current.options.plugins.annotation.annotations = annotations;
        chartRef.current.update();
      }, 0);

      return;
    }

    Object.keys(referenceRanges)
      .filter((key) => {
        const vitalType = VitalsReferenceRangeKeyEnum[key as keyof typeof VitalsReferenceRangeKeyEnum];

        return selectedVitalType.includes(vitalType);
      })
      .forEach((key) => {
        const rangeItem = JSON.parse(referenceRanges[key]);
        const abnormalLowRange = rangeItem.abnormalLow.length > 1 ? rangeItem.abnormalLow[1] : rangeItem.abnormalLow[0];
        const abnormalHightRange = rangeItem.abnormalHigh[0];

        const skipRefRangesList = [VitalsReferenceRangeKeyEnum.vital_signs_weight];
        const vitalType = VitalsReferenceRangeKeyEnum[key as keyof typeof VitalsReferenceRangeKeyEnum];
        const primaryVital = initialVitalTypes[0];
        const secondaryVital = initialVitalTypes[1];

        if (skipRefRangesList.includes(vitalType)) return;

        // Single Vital
        if (vital === vitalType && initialVitalTypes.length === 0) {
          const isBP = vitalType === 'BP_SYSTOLIC';

          if (isBP) {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = name === 'DBP' ? '#B74253' : '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }

          if (!isBP) {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }
        }

        // Multiple Vital when turn off primay or secondary
        if (selectedVitalType.length === 1 && selectedVitalType[0] === vitalType && initialVitalTypes.length === 2) {
          if (selectedVitalType[0] === primaryVital && primaryVital === 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = name === 'DBP' ? '#B74253' : '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }

          if (selectedVitalType[0] === primaryVital && primaryVital !== 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }

          if (selectedVitalType[0] === secondaryVital && secondaryVital === 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = name === 'DBP' ? '#9747FF' : '#B74253';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }

          if (
            selectedVitalType[0] === secondaryVital &&
            secondaryVital !== 'BP_SYSTOLIC' &&
            primaryVital === 'BP_SYSTOLIC'
          ) {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#9747FF';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }

          if (
            selectedVitalType[0] === secondaryVital &&
            secondaryVital !== 'BP_SYSTOLIC' &&
            primaryVital !== 'BP_SYSTOLIC'
          ) {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#B74253';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }
        }

        // Multiple Vital
        if (selectedVitalType.length === 2 && initialVitalTypes.length === 2) {
          if (primaryVital === vitalType && vitalType === 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = name === 'DBP' ? '#B74253' : '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }

          if (primaryVital === vitalType && vitalType !== 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#258B96';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y'
            );
          }

          if (secondaryVital === vitalType && vitalType === 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = name === 'DBP' ? '#9747FF' : '#B74253';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }

          if (secondaryVital === vitalType && vitalType !== 'BP_SYSTOLIC' && primaryVital === 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#9747FF';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }

          if (secondaryVital === vitalType && vitalType !== 'BP_SYSTOLIC' && primaryVital !== 'BP_SYSTOLIC') {
            const name = VitalsShortNameForRefRangeEnum[key as keyof typeof VitalsShortNameForRefRangeEnum];
            const color = '#B74253';

            annotations[`${key}-below`] = prepareAnnotationObj(
              abnormalLowRange,
              color,
              `Low Border of ${name}`,
              'DOWN',
              'start',
              'y2'
            );

            annotations[`${key}-up`] = prepareAnnotationObj(
              abnormalHightRange,
              color,
              `High Border of ${name}`,
              'UP',
              'start',
              'y2'
            );
          }
        }
      });

    setTimeout(() => {
      chartRef.current.options.plugins.annotation.annotations = annotations;
      chartRef.current.update();
    }, 0);
  }, [referenceRanges, isShowBorderline, vital]);

  useEffect(() => {
    if (forceRenderChart && chartRef.current) {
      updateChartMinMax();
      updateTimeUnit();
      updateDataSets();
      updateYLabel();
      updateMaxMinForY();
      updateRefRanges();

      dispatch(setForceRendeChart(false));
    }
  }, [forceRenderChart, viewType, chartRef, customViewType, startDate, endDate, initialVitalTypes]);

  useEffect(() => {
    if (chartRef.current && !enabledLoadingGraph) {
      updateRefRanges();
    }
  }, [chartRef.current, enabledLoadingGraph, isShowBorderline]);

  const options: ChartOptions<'line'> = useMemo<ChartOptions<'line'>>(() => {
    return {
      maintainAspectRatio: false,
      animation: false,
      layout: {
        padding: {
          top: 30,
          left: 30,
          right: 30
        }
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            title(tooltipItem) {
              const record = (tooltipItem[0]?.raw as any).rawData;

              return record?.resourceName || '';
            },
            label(tooltipItem) {
              const record = (tooltipItem.raw as any).rawData;
              const type = record.type;

              if (type === 'BP SYSTOLIC' || type === 'BP DIASTOLIC') {
                return `${type} - ${dayjs(Number(record.recordedDateTime)).format('DD MMM, YYYY hh:mma')}`;
              }

              return dayjs(Number(record.recordedDateTime)).format('DD MMM, YYYY hh:mma');
            },
            footer(tooltipItem) {
              if (tooltipItem[0]) {
                return `${tooltipItem[0]?.formattedValue} ${(tooltipItem[0].raw as any).rawData.unit}`;
              }

              return;
            },
            afterBody(tooltipItem) {
              if (tooltipItem[0] && (tooltipItem[0].raw as any).rawData.remark) {
                const remarkArr = (tooltipItem[0].raw as any).rawData.remark.match(/.{1,50}/g);

                for (let x = 1; x < remarkArr.length; x++) {
                  if (remarkArr[x] && remarkArr[x][0] === ' ') {
                    remarkArr[x] = remarkArr[x].replace(/^\s+/g, '');
                  } else {
                    remarkArr[x] = `-${remarkArr[x]}`;
                  }
                }

                return remarkArr;
              }

              return;
            }
          },
          borderWidth: 1,
          borderColor: '#ccc',
          backgroundColor: '#FFF',
          titleAlign: 'center',
          titleFont: {
            size: 15,
            weight: 'bold'
          },
          titleColor: '#515151',
          bodyColor: '#000',
          bodyAlign: 'center',
          bodyFont: {
            size: 13
          },
          footerAlign: 'center',
          footerMarginTop: 10,
          footerFont: {
            size: 15
          },
          padding: 12,
          footerColor: '#515151',
          displayColors: false
        },
        legend: {
          display: true,
          position: 'top'
        },
        annotation: {
          annotations: {}
        },
        zoom: {
          limits: {
            x: {
              min: dayjs().subtract(2, 'years').startOf('year').valueOf(),
              max: dayjs().endOf('year').add(1, 'minute').valueOf(),
              minRange: 7 * 24 * 60 * 60
            }
          },
          pan: {
            enabled: true,
            mode: 'x',
            onPanComplete: (chart) => {
              const firstValue = chart.chart.scales.x.ticks[0].value;
              const lastValue = chart.chart.scales.x.ticks[chart.chart.scales.x.ticks.length - 1].value;

              if (timeOutId) {
                clearTimeout(timeOutId);
              }

              timeOutId = setTimeout(() => {
                if (setViewType && setStartDate && setEndDate) {
                  setStartDate(new Date(firstValue));
                  setEndDate(new Date(lastValue));
                }
              }, 200);
            }
          },
          zoom: {
            drag: {
              enabled: true,
              modifierKey: 'meta'
            },
            mode: 'x',
            wheel: {
              enabled: true,
              modifierKey: 'ctrl'
            },
            onZoomStart: (chart) => {
              (chart.chart.options.scales?.x as any).time.unit = undefined;
              chart.chart.update();
              setStartZoom(true);
            },
            onZoom: (chart) => {
              const firstValue = chart.chart.scales.x.ticks[0].value;
              const lastValue = chart.chart.scales.x.ticks[chart.chart.scales.x.ticks.length - 1].value;
              const localViewType = generateViewTypeByDayDiff(dayjs(firstValue), dayjs(lastValue));

              if (localViewType === ViewType.Month || localViewType === ViewType.Week) {
                (chart.chart.options.scales?.x as any).time.unit = 'day';
              } else {
                (chart.chart.options.scales?.x as any).time.unit = undefined;
              }

              chart.chart.update();
            },
            onZoomComplete: (chart) => {
              const firstValue = chart.chart.scales.x.ticks[0].value;
              const lastValue = chart.chart.scales.x.ticks[chart.chart.scales.x.ticks.length - 1].value;

              const localViewType = generateViewTypeByDayDiff(dayjs(firstValue), dayjs(lastValue));

              if (timeOutId) {
                clearTimeout(timeOutId);
              }

              timeOutId = setTimeout(() => {
                if (setViewType && setStartDate && setEndDate) {
                  setStartDate(new Date(firstValue));
                  setEndDate(new Date(lastValue));
                  setViewType(localViewType);
                }
              }, 200);
            }
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#000',
            font: {
              weight: '500'
            }
          },
          type: 'time',
          time: {
            unit: undefined,
            minUnit: 'minute'
          },
          adapters: {
            date: {
              locale: enUS
            }
          },
          border: {
            dash: [20, 4]
          },
          title: {
            display: true,
            text: 'Time (Hour)',
            font: { weight: 'bold', size: 16 },
            color: '#959595',
            padding: {
              top: 30
            }
          }
        },

        y: {
          min: 0,
          suggestedMin: 0,
          border: {
            dash: [20, 4]
          },
          title: {
            display: true,
            text: '',
            font: { weight: 'bold', size: 16 },
            color: '#959595',
            padding: {
              bottom: 30
            }
          },
          ticks: {
            color(ctx) {
              const { index } = ctx;

              if (index % 2 === 0) {
                return '#000';
              }

              return '#ccc';
            },
            stepSize: 10,
            font: {
              weight: '500'
            }
          }
        },

        y2: {
          min: 0,
          display: !!vitalText1,
          suggestedMin: 0,
          border: {
            dash: [20, 4]
          },
          title: {
            display: true,
            text: `${vitalText1} (${vitalUnit1})`,
            font: { weight: 'bold', size: 16 },
            color: '#959595',
            padding: {
              bottom: 30
            }
          },
          position: 'right',
          ticks: {
            color(ctx) {
              const { index } = ctx;

              if (index % 2 === 0) {
                return '#000';
              }

              return '#ccc';
            },
            stepSize: 15,
            font: {
              weight: '500'
            }
          }
        }
      }
    };
  }, [vitalText, vitalText1, vitalUnit, vitalUnit1]);

  useEffect(() => {
    if (chartRef.current && chartRef.current.options.scales && chartRef.current.options.scales.x) {
      chartRef.current.options.scales.x.title.text = xLabel;
      chartRef.current?.update();
    }
  }, [xLabel]);

  const mainComponent = useMemo(() => {
    return (
      <Line
        ref={chartRef}
        data={{
          datasets: []
        }}
        id={id}
        options={options}
      />
    );
  }, [chartRef, id, forceRenderChart]);

  return <div style={{ position: 'relative', width: '100%', height: 500 }}>{mainComponent}</div>;
};

export default VitalGraph;
