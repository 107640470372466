import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Dashboard from 'pages/Dashboard';
import VitalDetail from 'pages/VitalDetail';

const router = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<Dashboard />} path="/" />
      <Route element={<VitalDetail />} path="/detail" />
    </Routes>
  </BrowserRouter>
);

export default router;
