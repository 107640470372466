// TYPE
import { RootState } from 'redux/type';
import {
  MeasurementItem,
  VitalRowItem,
  VitalType,
  VitalGraphItem,
  VitalReportingItem,
  VitalListViewStatusType,
  DataWaitingForDownload,
  BaseLine,
  ViewType
} from 'model';

export const selectVitalLoadingStatus = (state: RootState): boolean => state.appReducer.loading;
export const selectDashboardData = (state: RootState): MeasurementItem[] => state.vitalReducer.dashboard;
export const selectVitalListView = (state: RootState): VitalRowItem[] => state.vitalReducer.vitalListView;
export const selectVitalListViewStatus = (state: RootState): VitalListViewStatusType =>
  state.vitalReducer.vitalListViewStatus;
export const selectAvailableVitalTypes = (state: RootState): VitalType[] =>
  state.vitalReducer.dashboard.map((v) => v.type);
export const selectCurrentPage = (state: RootState): number => state.vitalReducer.currentPage;
export const selectCurrentPageSize = (state: RootState): number => state.vitalReducer.currentPageSize;
export const selectTotalElements = (state: RootState): number => state.vitalReducer.totalElements;
export const selectVitalIcons =
  (state: RootState) =>
  (type: VitalType): { active: any; nonActive: any; png: any } =>
    state.vitalReducer.vitalIcons[type];
export const selectVitalText =
  (state: RootState) =>
  (type: VitalType): string =>
    state.vitalReducer.vitalText[type];

export const selectVitalUnit =
  (type: VitalType) =>
  (state: RootState): string =>
    state.vitalReducer.vitalUnit[type];
export const selectVitalUnitWithType =
  (state: RootState) =>
  (type: VitalType): string =>
    state.vitalReducer.vitalUnit[type];
export const selectVitalGraphView = (state: RootState): VitalGraphItem[] => state.vitalReducer.vitalGraphView;
export const selectVitalGraphLabel = (state: RootState): number[] => state.vitalReducer.vitalGraphLabel;
export const selectIsDownloadingInprogress = (state: RootState): boolean => state.vitalReducer.isDownloadingInProgress;
export const selectVitalGraphData = (state: RootState): any[] => state.vitalReducer.vitalGraphData;
export const selectSecondVitalGraphData = (state: RootState): VitalGraphItem[] =>
  state.vitalReducer.secondVitalGraphData;
export const selectThirdVitalGraphData = (state: RootState): VitalGraphItem[] => state.vitalReducer.thirdVitalGraphData;
export const selectVitalData = (state: RootState): any[] => state.vitalReducer.vitalGraphData;
export const selectVitalGraphReport = (state: RootState): VitalReportingItem[] =>
  state.vitalReducer.vitalGraphReportData;
export const selectLoadingGraph = (state: RootState): boolean => state.vitalReducer.loadingGraph;
export const selectEnabledZoomPan = (state: RootState): boolean => state.vitalReducer.enabledZoomPan;
export const selectLoadingDashboard = (state: RootState): boolean => state.vitalReducer.loadingDashboard;
export const selectForceRenderChart = (state: RootState): boolean => state.vitalReducer.forceRenderChart;

export const selectViewType = (state: RootState): ViewType | null => state.vitalReducer.viewType;
export const selectCustomViewType = (state: RootState): ViewType | null => state.vitalReducer.customViewType;
export const selectGraphStartDate = (state: RootState): Date | null => state.vitalReducer.graphStartDate;
export const selectGraphEndDate = (state: RootState): Date | null => state.vitalReducer.graphEndDate;

export const selectLoadingListView = (state: RootState): boolean => state.vitalReducer.loadingListView;
export const selectDataForDownload = (state: RootState): DataWaitingForDownload | undefined =>
  state.vitalReducer.dataWaitingForDownload;
export const isExistingOneValueAtLeast = (state: RootState): boolean => {
  if (!state.vitalReducer.baseLine) return false;

  const keys = Object.keys(state.vitalReducer.baseLine);

  return keys.some((v) => {
    if (state.vitalReducer.baseLine && state.vitalReducer.baseLine[v].id.length > 0) {
      return true;
    }

    return false;
  });
};
export const selectBaseLine = (state: RootState): BaseLine | undefined => {
  return state.vitalReducer.baseLine;
};
// TODO - rename this to getSelectedVitalTypes
export const getSelectedVitalType = (state: RootState): string[] => {
  return state.vitalReducer.selectedVitalTypes;
};
export const getInitialSelectedVitalType = (state: RootState): string[] => {
  return state.vitalReducer.initialVitalTypes;
};

export const getIsMultiVitals = (state: RootState): boolean => {
  return state.vitalReducer.isMultiVitals;
};

export const selectIsShowBorderline = (state: RootState): boolean => {
  return state.vitalReducer.isShowBorderline;
};

export const getURLBasedOnSelectedVital = (state: RootState): string => {
  const types = getSelectedVitalType(state);

  let vitalType = '';

  types.forEach((v) => {
    if (v === 'BP_SYSTOLIC' && vitalType.length === 0) {
      vitalType = `vitalType=BP_SYSTOLIC&&vitalType=BP_DIASTOLIC`;

      return;
    }

    if (v !== 'BP_SYSTOLIC' && vitalType.length === 0) {
      vitalType = `vitalType=${v}`;

      return;
    }

    if (v === 'BP_SYSTOLIC' && vitalType.length > 0) {
      vitalType += `&&vitalType=BP_SYSTOLIC&&vitalType=BP_DIASTOLIC`;

      return;
    }

    if (v !== 'BP_SYSTOLIC' && vitalType.length > 0) {
      vitalType += `&&vitalType=${v}`;

      return;
    }
  });

  return vitalType;
};

export const getGraphURLBasedOnSelectedVital = (state: RootState): string => {
  const types = getSelectedVitalType(state);

  let vitalType = '';

  types.forEach((v) => {
    if (v === 'BP_SYSTOLIC' && vitalType.length === 0) {
      vitalType = `vitalType=BP_SYSTOLIC`;

      return;
    }

    if (v !== 'BP_SYSTOLIC' && vitalType.length === 0) {
      vitalType = `vitalType=${v}`;

      return;
    }

    if (v === 'BP_SYSTOLIC' && vitalType.length > 0) {
      vitalType += `&&vitalType=BP_SYSTOLIC&&vitalType=BP_DIASTOLIC`;

      return;
    }

    if (v !== 'BP_SYSTOLIC' && vitalType.length > 0) {
      vitalType += `&&vitalType=${v}`;

      return;
    }
  });

  return vitalType;
};
export const selectBaseLineById =
  (id?: string): any =>
  (state: RootState) => {
    if (!state.vitalReducer.baseLine || !id) return null;

    return state.vitalReducer.baseLine[id] as any;
  };

export const selectReferenceRanges = (state: RootState): Record<string, string> => {
  return state.vitalReducer.referenceRanges;
};
