// TYPES
import { AppThunk } from 'redux/type';

// SLICE
import { appSlice } from './slice';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// CONSTANT
import { API_ENDPOINT } from 'constant';

// MODEL
import { AllVitalsDataFromIframe as AllVitalsDataFromParent, PostMessageType, UpdateVitalDataFromIframe } from 'model';

// SELECTOR
import { selectClientId } from './selectors';

// REDUX ACTIONS
import {
  addVitalsRecord,
  deleteRecord,
  fetchDataForReporting,
  setSelectedVitalType,
  setInitialSelectedVitalType,
  updateRemark,
  setViewType,
  setCustomViewType,
  setGraphStartDate,
  setGraphEndDate,
  loadReferenceRange
} from 'redux/vital/action';

// ACTIONS
const { setAppToken, setClientId, setClientInformation, setUserRoles } = appSlice.actions;

function sendMessageToParent<T>(data: T, event: PostMessageType): AppThunk {
  return () => {
    window.parent.postMessage(
      {
        type: event,
        data: {
          ...data
        }
      },
      '*'
    );
  };
}

const handleMessageFromParentIframe =
  (event: MessageEvent): AppThunk =>
  async (dispatch) => {
    try {
      const { type, data } = event.data;

      if (type === PostMessageType.FETCH_MASTER_DATA && data) {
        if (data.viewType) {
          dispatch(setViewType(data.viewType));
        }

        if (data.customViewType) {
          dispatch(setCustomViewType(data.customViewType));
        }

        if (data.graphStartDate) {
          dispatch(setGraphStartDate(data.graphStartDate));
        }

        if (data.graphEndDate) {
          dispatch(setGraphEndDate(data.graphEndDate));
        }

        dispatch(setInitialSelectedVitalType(data.initialVitalType));
        dispatch(setSelectedVitalType(data.types));
        dispatch(setAppToken(data.token));
        dispatch(setClientId(data.clientId));
      }

      if (type === PostMessageType.CONFIRMED_TO_DELETE) {
        const payload = {
          id: data.id,
          type: data.type,
          params: data.params
        };

        dispatch(deleteRecord(payload.id, payload.type));
      }

      if (type == PostMessageType.SAVE_ALL_VITALS_DATA && data) {
        const payload: AllVitalsDataFromParent = {
          clientId: data.clientId,
          vitalsData: data.vitalsData,
          type: data.type,
          fieldName: data.fieldName,
          secondaryVitalName: data.secondaryVitalName,
          isMultiVitalDialog: data.isMultiVitalDialog,
          orderBy: data.orderBy,
          orderDirection: data.orderDirection
        };

        dispatch(addVitalsRecord(payload));
      }

      if (type == PostMessageType.UPDATE_REMARK && data) {
        const payload: UpdateVitalDataFromIframe = {
          clientId: data.clientId,
          vitalsData: data.vitalsData,
          type: data.type,
          isMultiVitalDialog: data.isMultiVitalDialog,
          selectedVitals: data.selectedVitals
        };

        dispatch(
          updateRemark(
            payload.vitalsData.rowId!,
            payload.selectedVitals,
            payload.vitalsData.primaryRemark,
            payload.vitalsData.secondaryRemark,
            () => {}
          )
        );
      }

      const dataType: any = {
        bloodGlucose: 'BLOOD_GLUCOSE',
        bp: 'BP_SYSTOLIC',
        heartRate: 'HEART_RATE',
        pain: 'PAIN',
        respiration: 'RESPIRATION_RATE',
        spo2: 'SPO2',
        temp: 'TEMPERATURE',
        weight: 'WEIGHT'
      };

      if (type == PostMessageType.PROCEED_DOWNLOADING && data) {
        dispatch(
          loadReferenceRange(() => {
            const selectedVital: any[] = [];

            Object.keys(data).forEach((v) => {
              if (typeof data[v] === 'boolean' && data[v]) {
                selectedVital.push(dataType[v]);
              }
            });

            dispatch(setSelectedVitalType(selectedVital));
            dispatch(setInitialSelectedVitalType([]));

            setTimeout(() => {
              dispatch(fetchDataForReporting(data));
            }, 200);
          })
        );
      }

      if (type == PostMessageType.PROCEED_DOWNLOADING_SECOND_ACTION) {
        dispatch(
          loadReferenceRange(() => {
            const selectedVital: any[] = [];

            Object.keys(data).forEach((v) => {
              if (typeof data[v] === 'boolean' && data[v]) {
                selectedVital.push(dataType[v]);
              }
            });

            dispatch(setSelectedVitalType(selectedVital));
            dispatch(setInitialSelectedVitalType([]));

            setTimeout(() => {
              dispatch(fetchDataForReporting(data, false, true));
            });
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

const handleFetchClientInformation = (): AppThunk => async (dispatch, getState) => {
  const clientId = selectClientId(getState());

  dispatch(
    httpAction<unknown, unknown, { name: string; dateOfBirth: number; gender: string }>(
      {
        method: 'GET',
        url: `${API_ENDPOINT.CLIENT_URL}/${clientId}`
      },
      async (res) => {
        if (res.httpCode === 200 && res.data) {
          dispatch(
            setClientInformation({
              clientDOB: new Date(res.data.dateOfBirth).toString(),
              clientName: res.data.name,
              clientGender: res.data.gender
            })
          );
        }
      }
    )
  );
};

const handleFetchUserRole = (): AppThunk => async (dispatch) => {
  dispatch(
    httpAction<unknown, unknown, { privileges: string[] }>(
      {
        method: 'GET',
        url: `${API_ENDPOINT.SECURITY_URL}`
      },
      async (res) => {
        if (res.httpCode === 200 && res.data) {
          dispatch(setUserRoles(res.data.privileges));
        }
      }
    )
  );
};

export { handleMessageFromParentIframe, handleFetchClientInformation, sendMessageToParent, handleFetchUserRole };
