import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';

// REDUCER
import appReducer, { initialState as appInitialState } from './app/slice';
import vitalReducer, { initialState as vitalInitialState } from './vital/slice';

// TYPE
import { RootState } from './type';

export const reducer = combineReducers({
  appReducer,
  vitalReducer
});

export const initialAppState: Omit<RootState, 'router'> = {
  appReducer: appInitialState,
  vitalReducer: vitalInitialState
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV === 'prod' ? getDefaultMiddleware().concat() : getDefaultMiddleware().concat(logger),
  devTools: process.env.REACT_APP_ENV !== 'prod'
});
