import { ViewType, ViewTypeFromB3 } from 'model';
import dayjs, { Dayjs } from 'dayjs';

export const trimString = function (string: string, length: number) {
  return string.length > length ? string.substring(0, length) + '...' : string;
};

export const generateLabelsByTime = (viewType: ViewType, startDate: Dayjs, endDate: Dayjs): number[] => {
  let resultLabels: number[] = [];
  const ranges: number[] = [];

  if (viewType === ViewType.Day) {
    let currentDate = dayjs(startDate).subtract(1, 'hour');

    while (currentDate.isBefore(endDate)) {
      currentDate = currentDate.add(1, 'hour');

      ranges.push(currentDate.valueOf());
    }

    resultLabels = ranges.sort((a, b) => a - b);
  }

  if (viewType === ViewType.Week) {
    let currentDate = dayjs(startDate).subtract(1, 'day');

    while (currentDate.isBefore(endDate.subtract(1, 'day'), 'date')) {
      currentDate = currentDate.add(1, 'day');

      ranges.push(currentDate.valueOf());
    }

    resultLabels = ranges.sort((a, b) => a - b);
  }

  if (viewType === ViewType.Month) {
    let currentDate = dayjs(startDate).subtract(1, 'day');

    while (currentDate.isBefore(endDate, 'date')) {
      currentDate = currentDate.add(1, 'day');

      ranges.push(currentDate.valueOf());
    }

    resultLabels = ranges.sort((a, b) => a - b);
  }

  if (viewType === ViewType.ThreeMonth || viewType === ViewType.SixMonth) {
    let currentDate = dayjs(startDate).endOf('week');
    let newEndDate = dayjs(endDate).startOf('week');

    ranges.push(dayjs(startDate).valueOf());

    while (currentDate.isBefore(newEndDate, 'date')) {
      ranges.push(currentDate.valueOf());

      currentDate = currentDate.add(1, 'week');
    }

    ranges.push(dayjs(endDate).valueOf());

    resultLabels = ranges.sort((a, b) => a - b);
  }

  if (viewType === ViewType.Year) {
    let currentDate = dayjs().startOf('year');
    let newEndDate = dayjs().endOf('year');

    while (currentDate.isBefore(newEndDate)) {
      ranges.push(currentDate.valueOf());

      currentDate = currentDate.add(1, 'month');
    }

    resultLabels = ranges.sort((a, b) => a - b);
  }

  return resultLabels;
};

export const generateViewTypeByDayDiff = (startDate: Dayjs, endDate: Dayjs): ViewType => {
  let viewType = ViewType.Day;

  const dayDiff = dayjs(endDate).diff(dayjs(startDate), 'days');

  if (dayDiff >= 1 && dayDiff <= 7) {
    viewType = ViewType.Week;
  }

  if (dayDiff > 7 && dayDiff <= 30) {
    viewType = ViewType.Month;
  }

  if (dayDiff > 30 && dayDiff <= 180) {
    viewType = ViewType.ThreeMonth;
  }

  if (dayDiff > 180) {
    viewType = ViewType.Year;
  }

  return viewType;
};

export const generateViewTypeByDayDiffForPDF = (startDate: Dayjs, endDate: Dayjs): ViewType => {
  let viewType = ViewType.Day;

  const dayDiff = dayjs(endDate).diff(dayjs(startDate), 'days');

  if (dayDiff >= 1 && dayDiff <= 7) {
    viewType = ViewType.Week;
  }

  if (dayDiff > 7 && dayDiff <= 30) {
    viewType = ViewType.Month;
  }

  if (dayDiff > 30 && dayDiff <= 180) {
    viewType = ViewType.ThreeMonth;
  }

  if (dayDiff > 180 && dayDiff <= 217) {
    viewType = ViewType.SixMonth;
  }

  if (dayDiff > 217) {
    viewType = ViewType.Year;
  }

  return viewType;
};

export const generateStartDateAndEndDateByViewType = (
  viewType: ViewType | ViewTypeFromB3,
  startDate: Dayjs,
  endDate: Dayjs
): {
  startDate: Dayjs;
  endDate: Dayjs;
  viewTye: ViewType;
} => {
  let result: {
    startDate: Dayjs;
    endDate: Dayjs;
    viewTye: ViewType;
  } = {
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    viewTye: ViewType.Day
  };

  if (viewType === ViewType.Day || viewType === ViewTypeFromB3.Day) {
    result.startDate = dayjs().startOf('date');
    result.endDate = dayjs().endOf('date');
    result.viewTye = ViewType.Day;
  }

  if (viewType === ViewType.Week || viewType === ViewTypeFromB3.Week) {
    result.startDate = dayjs().startOf('week');
    result.endDate = dayjs().endOf('week').add(12, 'hours');
    result.viewTye = ViewType.Week;
  }

  if (viewType === ViewType.Month || viewType === ViewTypeFromB3.Month) {
    result.startDate = dayjs().startOf('month');
    result.endDate = dayjs().endOf('month');
    result.viewTye = ViewType.Month;
  }

  if (viewType === ViewType.ThreeMonth || viewType === ViewTypeFromB3.ThreeMonth) {
    result.startDate = dayjs().subtract(3, 'months').startOf('month');
    result.endDate = dayjs().endOf('month');
    result.viewTye = ViewType.ThreeMonth;
  }

  if (viewType === ViewType.SixMonth || viewType === ViewTypeFromB3.SixMonth) {
    result.startDate = dayjs().subtract(6, 'months').startOf('month');
    result.endDate = dayjs().endOf('month');
    result.viewTye = ViewType.SixMonth;
  }

  if (viewType === ViewType.Year || viewType === ViewTypeFromB3.Year) {
    result.startDate = dayjs().startOf('year');
    result.endDate = dayjs().endOf('year');
    result.viewTye = ViewType.Year;
  }

  return result;
};
