const API_ENDPOINT = {
  VITAL_URL: `${process.env.REACT_APP_API_URL}/vitals`,
  DASHBOARD_URL: `${process.env.REACT_APP_API_URL}/vitals/latest`,
  CLIENT_URL: `${process.env.REACT_APP_API_URL}/clients`,
  GRAPH_URL: `${process.env.REACT_APP_API_URL}/vitals/graph`,
  REPORT_URL: `${process.env.REACT_APP_API_URL}/vitals/report`,
  BASELINE_URL: `${process.env.REACT_APP_API_URL}/vitals/baseline`,
  RESOURCE_URL: `${process.env.REACT_APP_API_URL}/vitals/resources`,
  SECURITY_URL: `${process.env.REACT_APP_API_URL}/security/verify`,
  CONFIG_URL: `${process.env.REACT_APP_API_URL}/config`
};

export { API_ENDPOINT };
